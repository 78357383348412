import {TestContext} from 'yup'
import {TFunction} from 'i18next'

import {
  getIsFacilitySpecificBenefitType,
  BenefitType,
  getIsInitialDateOfServiceRequiredWhenCreatingBenefitType,
} from 'utils/benefits'
import {
  ValidatorTest,
  makeTextField,
  makeBenefitField,
  makeFacilityField,
  makeMcdTypeField,
  makeCharityCareTypeField,
  makeDateField,
  makeSelectField,
} from 'utils/form/fieldTypes'
import {FacilityFields} from 'graphql/deserializedTypes/FacilityFields'
import {
  Config_config_applicationBenefits,
  Config_config_charityCareTypes,
} from 'graphql/deserializedTypes/Config'
import {makeFormSchema} from 'utils/form/schema'

const facilityValidatorTest: ValidatorTest = [
  'form.required',
  function (this: TestContext, value: string) {
    const {benefit} = this.parent
    if (!getIsFacilitySpecificBenefitType(benefit)) return true
    if (value) return true
    return false
  },
]

const ccTypeValidatorTest: ValidatorTest = [
  'form.required',
  function (this: TestContext, value: string) {
    const {benefit} = this.parent
    if (benefit !== 'charityCare') return true
    if (value) return true
    return false
  },
]

const mcdTypeValidatorTest: ValidatorTest = [
  'form.required',
  function (this: TestContext, value: string) {
    const {benefit} = this.parent
    if (benefit !== 'medicaid') return true
    if (value) return true
    return false
  },
]

const initialDateOfServiceValidatorTest: ValidatorTest = [
  'form.required',
  function (this: TestContext, value: string) {
    const {benefit} = this.parent
    if (
      !getIsInitialDateOfServiceRequiredWhenCreatingBenefitType(
        benefit as BenefitType
      )
    ) {
      return true
    }
    if (value) return true
    return false
  },
]

export const getCreateApplicationFormSchema = ({
  facilities,
  benefits,
  charityCareTypes,
  t,
}: {
  facilities: FacilityFields[]
  benefits: Config_config_applicationBenefits[]
  charityCareTypes: Config_config_charityCareTypes[]
  t: TFunction
}) =>
  makeFormSchema({
    fields: {
      application: {
        personId: makeTextField({isRequired: true}),
        status: makeTextField({isRequired: true}),
        benefit: makeBenefitField({
          benefits,
          t,
          isRequired: true,
          defaultsToSingularOption: true,
        }),
        facility: makeFacilityField({
          facilities,
          defaultsToSingularOption: true,
          validatorTest: facilityValidatorTest,
        }),
        mcdType: makeMcdTypeField({
          validatorTest: mcdTypeValidatorTest,
        }),
        charityCareType: makeCharityCareTypeField({
          charityCareTypes,
          defaultsToSingularOption: true,
          validatorTest: ccTypeValidatorTest,
        }),
        assignedToId: makeSelectField({isRequired: true}),
        initialDateOfService: makeDateField({
          validatorTest: initialDateOfServiceValidatorTest,
        }),
      },
    },
  })

export const createApplicationFormSchemaStatic = getCreateApplicationFormSchema(
  {
    facilities: [],
    benefits: [],
    charityCareTypes: [],
    t: () => '',
  }
)
