import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addStateHandlers,
  addProps,
  addWrapper,
  addHandlers,
} from 'ad-hok'
import {groupBy} from 'lodash/fp'
import {PureQueryOptions} from 'apollo-boost'

import {addTranslationHelpers} from 'utils/i18n'
import {addClasses, makeClasses} from 'theme'
import FormSection from 'components/FormSection'
import Body1 from 'components/Body1'
import {getExtendedName} from 'utils/name'
import Grid from 'components/Grid'
import {outcomesSectionForLink} from './ApplicationFormLeftColumn'
import AddButton from 'components/AddButton'
import BenefitOutcomeFormDialog, {
  OutcomePerson,
  OutcomeApplication,
  BenefitOutcome,
} from 'components/BenefitOutcomeFormDialog'
import NestedFormListItem from 'components/NestedFormListItem'
import {addApplicationFormContext} from './applicationFormContext'
import {APPLICATION_QUERY} from 'graphql/queries'
import DeleteBenefitOutcomeButton from 'components/DeleteBenefitOutcomeButton'
import {addApplicationBenefitOutcomesContext} from './applicationBenefitOutcomesContext'
import {
  getFormattedBenefitOutcomeTitle,
  getFormattedBenefitOutcomeSubtitle,
  isBenefitOutcomeEditable,
} from 'utils/benefitOutcome'
import typedAs from 'utils/typedAs'
import addUserRoles from 'utils/addUserRoles'

const classes = makeClasses((theme) => ({
  outcomesForPersonContainer: {
    marginBottom: theme.spacing(2),
  },
  outcomePersonName: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
}))

interface BenefitOutcomeItemProps {
  benefitOutcome: BenefitOutcome
  person: OutcomePerson
  onEdit: (benefitOutcome: BenefitOutcome) => void
  refetchQueriesOnDelete: PureQueryOptions[]
  userIsApprover: boolean
}

const BenefitOutcomeItem: FC<BenefitOutcomeItemProps> = flowMax(
  addDisplayName('BenefitOutcomeItem'),
  addTranslationHelpers,
  addHandlers({
    onEdit: ({benefitOutcome, onEdit}) => () => {
      onEdit(benefitOutcome)
    },
  }),
  addProps(({benefitOutcome, userIsApprover}) => ({
    showDelete: isBenefitOutcomeEditable({benefitOutcome, userIsApprover}),
  })),
  ({benefitOutcome, person, onEdit, refetchQueriesOnDelete, showDelete, t}) => (
    <NestedFormListItem
      title={getFormattedBenefitOutcomeTitle({...benefitOutcome, t})}
      subtitle={getFormattedBenefitOutcomeSubtitle(benefitOutcome)}
      onEditClick={onEdit}
      renderDelete={() =>
        showDelete ? (
          <DeleteBenefitOutcomeButton
            benefitOutcome={benefitOutcome}
            person={person}
            refetchQueriesOnDelete={refetchQueriesOnDelete}
          />
        ) : null
      }
    />
  )
)

interface OutcomesForPersonProps {
  person: OutcomePerson
  application: OutcomeApplication
  benefitOutcomes: BenefitOutcome[]
  userIsApprover: boolean
}

const OutcomesForPerson: FC<OutcomesForPersonProps> = flowMax(
  addDisplayName('OutcomesForPerson'),
  addStateHandlers(
    {
      isShowingDialog: false,
      benefitOutcome: typedAs<BenefitOutcome | undefined>(undefined),
    },
    {
      showNewDialog: () => () => ({
        isShowingDialog: true,
        benefitOutcome: undefined,
      }),
      showEditDialog: () => (benefitOutcome: BenefitOutcome) => ({
        isShowingDialog: true,
        benefitOutcome,
      }),
      hideDialog: () => () => ({
        isShowingDialog: false,
        benefitOutcome: undefined,
      }),
    }
  ),
  addApplicationBenefitOutcomesContext,
  addTranslationHelpers,
  addClasses(classes),
  ({
    person,
    application,
    showNewDialog,
    showEditDialog,
    isShowingDialog,
    hideDialog,
    benefitOutcome,
    benefitOutcomes,
    submitDate,
    initialDateOfService,
    userIsApprover,
    t,
    classes,
  }) => (
    <Grid
      container
      direction="column"
      alignItems="flex-start"
      className={classes.outcomesForPersonContainer}
    >
      <Body1 className={classes.outcomePersonName}>
        {getExtendedName({...person, t})}
      </Body1>
      <Grid container direction="column">
        {benefitOutcomes.map((benefitOutcome) => (
          <BenefitOutcomeItem
            benefitOutcome={benefitOutcome}
            onEdit={showEditDialog}
            refetchQueriesOnDelete={[
              {
                query: APPLICATION_QUERY,
                variables: {id: application.id},
              },
            ]}
            person={person}
            userIsApprover={userIsApprover}
            key={benefitOutcome.id}
          />
        ))}
      </Grid>
      <AddButton onClick={showNewDialog}>{t('benefitOutcomes.add')}</AddButton>
      <BenefitOutcomeFormDialog
        open={isShowingDialog}
        onSaveSuccess={hideDialog}
        onCancel={hideDialog}
        benefitOutcome={benefitOutcome}
        person={person}
        application={application}
        submitDate={submitDate}
        initialDateOfService={initialDateOfService}
        refetchQueriesOnCreateOrUpdate={[
          {
            query: APPLICATION_QUERY,
            variables: {id: application.id},
          },
        ]}
        userIsApprover={userIsApprover}
        editable={isBenefitOutcomeEditable({benefitOutcome, userIsApprover})}
      />
    </Grid>
  )
)

const OutcomesSection: FC = flowMax(
  addDisplayName('OutcomesSection'),
  addUserRoles,
  addWrapper((render) => (
    <FormSection labelTranslationKey={outcomesSectionForLink.name}>
      {render()}
    </FormSection>
  )),
  addApplicationFormContext,
  addApplicationBenefitOutcomesContext,
  addProps(({application: {benefitOutcomes}}) => ({
    benefitOutcomesForPerson: groupBy('person.id', benefitOutcomes),
  })),
  ({outcomesPeople, application, benefitOutcomesForPerson, userIsApprover}) => (
    <>
      {outcomesPeople.map((person) => (
        <OutcomesForPerson
          person={person}
          benefitOutcomes={benefitOutcomesForPerson[person.id] || []}
          application={application}
          userIsApprover={userIsApprover}
          key={person.id}
        />
      ))}
    </>
  )
)

export default OutcomesSection
