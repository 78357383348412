/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {EditableFileStatus} from './globalTypes' // ====================================================
// GraphQL query operation: PeopleByMrn
// ====================================================

import {PeopleByMrnVariables as PeopleByMrnVariablesOrig} from '../types/PeopleByMrn'
import {PeopleByMrn as PeopleByMrnOrig} from '../types/PeopleByMrn'
import {formatISODate} from 'utils/date'
import {PeopleByMrn_peopleByMrn as PeopleByMrn_peopleByMrnOrig} from '../types/PeopleByMrn'
import {PeopleByMrn_peopleByMrn_openApplications as PeopleByMrn_peopleByMrn_openApplicationsOrig} from '../types/PeopleByMrn'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {PeopleByMrn_peopleByMrn_editableFiles as PeopleByMrn_peopleByMrn_editableFilesOrig} from '../types/PeopleByMrn'
export interface PeopleByMrn_peopleByMrn_editableFiles {
  __typename: 'EditableFile'
  id: string
  fileKey: string
  filename: string
  updatedAt: Date
  status: EditableFileStatus
  allowSigning: boolean | null
  documentSendType: string | null
  hasIncompleteRemoteDocumentRequest: boolean
}
export const deserializePeopleByMrn_peopleByMrn_editableFiles = (
  serialized: PeopleByMrn_peopleByMrn_editableFilesOrig
): PeopleByMrn_peopleByMrn_editableFiles => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
})
export const serializePeopleByMrn_peopleByMrn_editableFiles = (
  deserialized: PeopleByMrn_peopleByMrn_editableFiles
): PeopleByMrn_peopleByMrn_editableFilesOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
})
export interface PeopleByMrn_peopleByMrn_openApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
}
export const deserializePeopleByMrn_peopleByMrn_openApplications = (
  serialized: PeopleByMrn_peopleByMrn_openApplicationsOrig
): PeopleByMrn_peopleByMrn_openApplications => ({...serialized})
export const serializePeopleByMrn_peopleByMrn_openApplications = (
  deserialized: PeopleByMrn_peopleByMrn_openApplications
): PeopleByMrn_peopleByMrn_openApplicationsOrig => ({...deserialized})
export interface PeopleByMrn_peopleByMrn {
  __typename: 'Person'
  id: string
  firstName: string | null
  middleName: string | null
  lastName: string | null
  suffix: string | null
  preferredName: string | null
  dob: Date | null
  editableFiles: PeopleByMrn_peopleByMrn_editableFiles[]
  openApplications: PeopleByMrn_peopleByMrn_openApplications[]
}
export const deserializePeopleByMrn_peopleByMrn = (
  serialized: PeopleByMrn_peopleByMrnOrig
): PeopleByMrn_peopleByMrn => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  editableFiles: serialized.editableFiles.map(
    deserializePeopleByMrn_peopleByMrn_editableFiles
  ),
  openApplications: serialized.openApplications.map(
    deserializePeopleByMrn_peopleByMrn_openApplications
  ),
})
export const serializePeopleByMrn_peopleByMrn = (
  deserialized: PeopleByMrn_peopleByMrn
): PeopleByMrn_peopleByMrnOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  editableFiles: deserialized.editableFiles.map(
    serializePeopleByMrn_peopleByMrn_editableFiles
  ),
  openApplications: deserialized.openApplications.map(
    serializePeopleByMrn_peopleByMrn_openApplications
  ),
})
export interface PeopleByMrn {
  /**
   * List of people with matching MRN
   */
  peopleByMrn: PeopleByMrn_peopleByMrn[]
}
export const deserializePeopleByMrn = (
  serialized: PeopleByMrnOrig
): PeopleByMrn => ({
  ...serialized,
  peopleByMrn: serialized.peopleByMrn.map(deserializePeopleByMrn_peopleByMrn),
})
export const serializePeopleByMrn = (
  deserialized: PeopleByMrn
): PeopleByMrnOrig => ({
  ...deserialized,
  peopleByMrn: deserialized.peopleByMrn.map(serializePeopleByMrn_peopleByMrn),
})
export interface PeopleByMrnVariables {
  hospitalPatientId: string
}
export const deserializePeopleByMrnVariables = (
  serialized: PeopleByMrnVariablesOrig
): PeopleByMrnVariables => ({...serialized})
export const serializePeopleByMrnVariables = (
  deserialized: PeopleByMrnVariables
): PeopleByMrnVariablesOrig => ({...deserialized})
