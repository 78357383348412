import {TFunction} from 'i18next'

import {BenefitType, getIsFacilitySpecificBenefitType} from 'utils/benefits'

export const getBenefitName = ({benefit, t}: {benefit: string; t: TFunction}) =>
  t(`benefitTypeLabels.${benefit}`)

export const getApplicationName = ({
  id,
  benefit,
  facility,
  mcdType,
  t,
}: {
  id: string
  benefit: BenefitType
  facility?: string
  mcdType?: string
  t: TFunction
}) => {
  const benefitName = getBenefitName({benefit, t})
  if (getIsFacilitySpecificBenefitType(benefit))
    return t('applications.nameWithSubtype', {
      benefit: benefitName,
      subtype: facility,
      id,
    })
  if (benefit === 'medicaid')
    return t('applications.nameWithSubtype', {
      benefit: benefitName,
      subtype: mcdType,
      id,
    })
  return t('applications.name', {
    benefit: benefitName,
    id,
  })
}

const applicationNames: Record<BenefitType, string> = {
  medicaid: 'NJ Medicaid',
  monitor: 'Monitor',
  charityCare: 'CC',
  slide: 'Slide',
}

export const getApplicationShortName = ({
  id,
  benefit,
  facility,
}: {
  id: string
  benefit: BenefitType
  facility?: string | null
}) =>
  `${applicationNames[benefit]}${
    getIsFacilitySpecificBenefitType(benefit) && facility
      ? ` - ${facility}`
      : ''
  } (${id})`
