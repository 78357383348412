import {isObject} from 'lodash/fp'

import {
  NjmmisCheckResult,
  McdType,
  Benefit,
} from 'graphql/deserializedTypes/globalTypes'
import {snakeCaseToSentenceCase, sentenceCaseToSnakeCase} from 'utils/string'
import {BenefitType} from 'utils/benefits'

export const deserializeNjmmisCheckResult = (
  serialized: NjmmisCheckResult
): string => snakeCaseToSentenceCase(serialized)

export const serializeNjmmisCheckResult = (
  deserialized: string
): NjmmisCheckResult =>
  sentenceCaseToSnakeCase(deserialized) as NjmmisCheckResult

export const deserializeMcdType = (serialized: McdType): string => serialized

export const serializeMcdType = (deserialized: string): McdType =>
  deserialized as McdType

export const deserializeBenefit = (serialized: Benefit): BenefitType =>
  serialized

export const serializeBenefit = (deserialized: BenefitType): Benefit =>
  deserialized

export interface Signature {
  type: 'signature'
  fileKey: string
}

type FileTemplateMappingValue = string | number | Signature | null

export const isSignature = (
  value: FileTemplateMappingValue
): value is Signature => (isObject(value) ? value.type === 'signature' : false)

export type DeserializedFileTemplateMappingScalar = Record<
  string,
  FileTemplateMappingValue
>

export const deserializeFileTemplateMappingScalar = (
  value: string
): DeserializedFileTemplateMappingScalar => JSON.parse(value)

export const serializeFileTemplateMappingScalar = (
  mapping: DeserializedFileTemplateMappingScalar
) => JSON.stringify(mapping)
