/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {EditableFileStatus} from './globalTypes' // ====================================================
// GraphQL query operation: EditableFiles
// ====================================================

import {EditableFilesVariables as EditableFilesVariablesOrig} from '../types/EditableFiles'
import {EditableFiles as EditableFilesOrig} from '../types/EditableFiles'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {EditableFiles_editableFiles as EditableFiles_editableFilesOrig} from '../types/EditableFiles'
export interface EditableFiles_editableFiles {
  __typename: 'EditableFile'
  id: string
  fileKey: string
  filename: string
  updatedAt: Date
  status: EditableFileStatus
  allowSigning: boolean | null
  documentSendType: string | null
  isUnreviewed: boolean
}
export const deserializeEditableFiles_editableFiles = (
  serialized: EditableFiles_editableFilesOrig
): EditableFiles_editableFiles => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
})
export const serializeEditableFiles_editableFiles = (
  deserialized: EditableFiles_editableFiles
): EditableFiles_editableFilesOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
})
export interface EditableFiles {
  /**
   * List of editable files for an application
   */
  editableFiles: EditableFiles_editableFiles[]
}
export const deserializeEditableFiles = (
  serialized: EditableFilesOrig
): EditableFiles => ({
  ...serialized,
  editableFiles: serialized.editableFiles.map(
    deserializeEditableFiles_editableFiles
  ),
})
export const serializeEditableFiles = (
  deserialized: EditableFiles
): EditableFilesOrig => ({
  ...deserialized,
  editableFiles: deserialized.editableFiles.map(
    serializeEditableFiles_editableFiles
  ),
})
export interface EditableFilesVariables {
  applicationId: string
}
export const deserializeEditableFilesVariables = (
  serialized: EditableFilesVariablesOrig
): EditableFilesVariables => ({...serialized})
export const serializeEditableFilesVariables = (
  deserialized: EditableFilesVariables
): EditableFilesVariablesOrig => ({...deserialized})
