/* eslint-disable */
import {SendDocumentRequestsVariables as SendDocumentRequestsVariablesOrig} from '../types/SendDocumentRequests'
import {SendDocumentRequests as SendDocumentRequestsOrig} from '../types/SendDocumentRequests'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL mutation operation: SendDocumentRequests
// ====================================================
export interface SendDocumentRequests {
  sendDocumentRequests: boolean
}
export const deserializeSendDocumentRequests = (
  serialized: SendDocumentRequestsOrig
): SendDocumentRequests => ({...serialized})
export const serializeSendDocumentRequests = (
  deserialized: SendDocumentRequests
): SendDocumentRequestsOrig => ({...deserialized})
export interface SendDocumentRequestsVariables {
  esignSessionId: string
  phoneNumber?: string | null
  language?: string | null
  applicationId: string
  personId: string
  requestorIds: string[]
  documentIds: string[]
  smsMessage?: string | null
}
export const deserializeSendDocumentRequestsVariables = (
  serialized: SendDocumentRequestsVariablesOrig
): SendDocumentRequestsVariables => ({...serialized})
export const serializeSendDocumentRequestsVariables = (
  deserialized: SendDocumentRequestsVariables
): SendDocumentRequestsVariablesOrig => ({...deserialized})
