import {BenefitType} from 'utils/benefits'

interface ApplicationStatus {
  status: string
  benefits: BenefitType[]
  closedOrVoid?: boolean
}

export const APPLICATION_STATUS_CLOSED = 'Closed'
export const APPLICATION_STATUS_PRESUBMISSION_STARTED = 'Presubmission, started'
const APPLICATION_STATUS_READY_FOR_SUBMIT_MISSING_DOC =
  'Ready for submit (missing doc)'
const APPLICATION_STATUS_READY_FOR_SUBMIT_COMPLETE_DOC =
  'Ready for submit (complete doc)'

export const APPLICATION_STATUSES: ApplicationStatus[] = [
  {
    status: APPLICATION_STATUS_PRESUBMISSION_STARTED,
    benefits: [
      BenefitType.medicaid,
      BenefitType.monitor,
      BenefitType.charityCare,
      BenefitType.slide,
    ],
  },
  {
    status: APPLICATION_STATUS_READY_FOR_SUBMIT_MISSING_DOC,
    benefits: [BenefitType.medicaid],
  },
  {
    status: APPLICATION_STATUS_READY_FOR_SUBMIT_COMPLETE_DOC,
    benefits: [BenefitType.medicaid],
  },
  {
    status: 'Ready for app review',
    benefits: [BenefitType.monitor, BenefitType.charityCare, BenefitType.slide],
  },
  {
    status: 'Locating app',
    benefits: [BenefitType.monitor],
  },
  {
    status: 'Submitted, pending (missing doc)',
    benefits: [BenefitType.medicaid],
  },
  {
    status: 'Submitted, pending (complete doc)',
    benefits: [BenefitType.medicaid, BenefitType.monitor],
  },
  {
    status: 'In review, missing docs requested',
    benefits: [
      BenefitType.medicaid,
      BenefitType.monitor,
      BenefitType.charityCare,
      BenefitType.slide,
    ],
  },
  {
    status: 'In review, missing docs fulfilled',
    benefits: [
      BenefitType.medicaid,
      BenefitType.monitor,
      BenefitType.charityCare,
      BenefitType.slide,
    ],
  },
  {
    status: 'Awaiting retro',
    benefits: [BenefitType.medicaid, BenefitType.monitor],
  },
  {
    status: 'Appealing',
    benefits: [BenefitType.medicaid, BenefitType.monitor],
  },
  {
    status: 'Contingent approval',
    benefits: [BenefitType.charityCare],
  },
  {
    status: APPLICATION_STATUS_CLOSED,
    benefits: [
      BenefitType.medicaid,
      BenefitType.monitor,
      BenefitType.charityCare,
      BenefitType.slide,
    ],
    closedOrVoid: true,
  },
  {
    status: 'Void',
    benefits: [
      BenefitType.medicaid,
      BenefitType.monitor,
      BenefitType.charityCare,
      BenefitType.slide,
    ],
    closedOrVoid: true,
  },
  {
    status: 'Other',
    benefits: [
      BenefitType.medicaid,
      BenefitType.monitor,
      BenefitType.charityCare,
      BenefitType.slide,
    ],
  },
]

export const getStatusesForBenefit = (benefit: BenefitType) =>
  APPLICATION_STATUSES.filter(({benefits}) => benefits.includes(benefit))

export const isClosedOrVoid = ({
  benefit,
  status,
}: {
  benefit: BenefitType
  status: string
}): boolean =>
  getStatusesForBenefit(benefit).find(
    ({status: otherStatus}) => status === otherStatus
  )?.closedOrVoid ?? false

const APPLICATION_STATUSES_MEDICAID_HOUSEHOLD_NONFROZEN = [
  APPLICATION_STATUS_PRESUBMISSION_STARTED,
  APPLICATION_STATUS_READY_FOR_SUBMIT_MISSING_DOC,
  APPLICATION_STATUS_READY_FOR_SUBMIT_COMPLETE_DOC,
]

export const isApplicationHouseholdFrozen = ({
  benefit,
  status,
}: {
  benefit: BenefitType
  status: string
}): boolean => {
  if (benefit === BenefitType.medicaid)
    return !APPLICATION_STATUSES_MEDICAID_HOUSEHOLD_NONFROZEN.includes(status)
  if ([BenefitType.charityCare, BenefitType.slide].includes(benefit))
    return status === APPLICATION_STATUS_CLOSED
  return false
}
