import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps, addWrapper} from 'ad-hok'
import {sortBy} from 'lodash/fp'
import cx from 'classnames'
import {branchIfFalsy} from 'ad-hok-utils'

import FormSection from 'components/FormSection'
import {addTranslationHelpers} from 'utils/i18n'
import {addDocumentSubsectionContextProvider} from 'components/EditApplicationForm/DataAndDocumentsSection/context'
import DocumentsSubsection, {
  addIsDocumentEditingFrozenContextProvider,
} from 'components/EditApplicationForm/DataAndDocumentsSection/DocumentsSubsection'
import {getExtendedName} from 'utils/name'
import {addApplicationFormContext} from 'components/EditApplicationForm/applicationFormContext'
import {addClasses, makeClasses} from 'theme'
import Heading from 'components/Heading'
import {APPLICATION_STATUS_CLOSED} from 'utils/applicationStatuses'
import {
  Application_application,
  Application_application_MedicaidApplication_householdMembers,
  Application_application_MedicaidApplication_person,
} from 'graphql/deserializedTypes/Application'
import {addShowDocumentFiles} from 'utils/configContext'

const classes = makeClasses((theme) => ({
  header: {
    fontSize: 18,
  },
  headerApplication: {
    marginTop: theme.spacing(7),
  },
}))

interface SectionHeadingProps {
  className?: string
}

const SectionHeading: FC<SectionHeadingProps> = flowMax(
  addDisplayName('SectionHeading'),
  addClasses(classes),
  ({children, classes, className}) => (
    <Heading variant="h6" className={cx(classes.header, className)}>
      {children}
    </Heading>
  )
)

interface HouseholdMemberDocumentsProps {
  householdMember: {
    person: {
      id: string
      firstName: string | null
      lastName: string | null
      preferredName: string | null
      middleName: string | null
      suffix: string | null
    }
  }
}

const HouseholdMemberDocuments: FC<HouseholdMemberDocumentsProps> = flowMax(
  addDisplayName('HouseholdMemberDocuments'),
  addTranslationHelpers,
  addProps(({householdMember: {person: {id}, person}, t}) => ({
    personId: id,
    createDialogTitle: t('applicationDocumentDialog.titlePersonDocument', {
      name: getExtendedName({...person, t}),
    }),
  })),
  addDocumentSubsectionContextProvider,
  () => <DocumentsSubsection />
)

const ApplicationDocuments: FC = flowMax(
  addDisplayName('ApplicationDocuments'),
  addTranslationHelpers,
  addClasses(classes),
  addWrapper((render, {classes, t}) => (
    <div>
      <SectionHeading className={classes.headerApplication}>
        {t('applicationForm.applicationDocuments.header')}
      </SectionHeading>
      {render()}
    </div>
  )),
  addProps(({t}) => ({
    personId: null,
    createDialogTitle: t('applicationDocumentDialog.title'),
  })),
  addDocumentSubsectionContextProvider,
  () => <DocumentsSubsection />
)

export const dataAndDocumentsSectionName = 'dataAndDocuments'

const getHouseholdMembers = ({
  person,
  householdMembers,
}: Application_application): (
  | Application_application_MedicaidApplication_householdMembers
  | {
      person: Application_application_MedicaidApplication_person
    }
)[] =>
  householdMembers.length
    ? sortBy(
        ({person: {firstName, id: personId}}) => [
          personId !== person.id,
          firstName,
        ],
        householdMembers
      )
    : [{person}]

const DataAndDocumentsSection: FC = flowMax(
  addDisplayName('DataAndDocumentsSection'),
  addShowDocumentFiles,
  branchIfFalsy('showDocumentFiles'),
  addApplicationFormContext,
  addProps(
    ({application}) => ({
      householdMembers: getHouseholdMembers(application),
      isDocumentEditingFrozen: application.status === APPLICATION_STATUS_CLOSED,
    }),
    ['application']
  ),
  addIsDocumentEditingFrozenContextProvider,
  addTranslationHelpers,
  ({householdMembers, t}) => (
    <FormSection labelTranslationKey={dataAndDocumentsSectionName}>
      <SectionHeading>
        {t('applicationForm.applicationDocuments.householdMembersHeader')}
      </SectionHeading>
      {householdMembers.map((householdMember) => (
        <HouseholdMemberDocuments
          householdMember={householdMember}
          key={householdMember.person.id}
        />
      ))}
      <ApplicationDocuments />
    </FormSection>
  )
)

export default DataAndDocumentsSection
