import React, {FC} from 'react'
import {flowMax, addDisplayName, addWrapper, addProps} from 'ad-hok'
import parseISO from 'date-fns/parseISO'

import {addClasses, makeClasses, sharedStyles} from 'theme'
import Form from 'components/Form'
import FormSection from 'components/FormSection'
import ReadOnlyTextField from 'components/ReadOnlyTextField'
import {editMedicaidApplicationFormSchema} from 'components/EditMedicaidApplicationForm/schema'
import Grid from 'components/Grid'
import MultilineTextField from 'components/MultilineTextField'
import ApplicationStatusUpdateSelect from 'components/ApplicationStatusUpdateSelect'
import {addFormikTyped} from 'utils/form/formik'
import {
  PrimaryPointOfContactDetails,
  addPrimaryPointOfContactProviderAndOptions,
} from 'components/EditApplicationForm/primaryPointOfContact'
import SelectField from 'components/SelectField'
import DateField from 'components/DateField'
import TextField from 'components/TextField'
import ApplicationFormLeftColumn from 'components/EditApplicationForm/ApplicationFormLeftColumn'
import DocumentRequestSection from 'components/EditApplicationForm/DocumentRequestSection'
import CommunicationHistorySection from 'components/EditApplicationForm/CommunicationHistorySection'
import DataAndDocumentsSection from 'components/EditApplicationForm/DataAndDocumentsSection'
import {addApplicationFormContext} from 'components/EditApplicationForm/applicationFormContext'
import typeProp from 'utils/typeProp'
import OutcomesSection from 'components/EditApplicationForm/OutcomesSection'
import {OutcomePerson} from 'components/BenefitOutcomeFormDialog'
import HouseholdSection from 'components/EditApplicationForm/HouseholdSection'
import {addApplicationBenefitOutcomesContextProvider} from 'components/EditApplicationForm/applicationBenefitOutcomesContext'
import {Application_application_MedicaidApplication} from 'graphql/deserializedTypes/Application'
import {Assert, SchemaDoesntHaveExtraFields} from 'utils/form/typeHelpers'
import {addUpdateApplicationMutation} from 'graphql/generated'
import AccountOnDateOfServiceDetails from 'components/AccountOnDateOfServiceDetails'
import AssignedToField from 'components/AssignedToField'
import FileTemplateSection from 'components/FileTemplateSection'
import EditableFilesSection from 'components/EditableFilesSection'
import EventLogSection from 'components/EditApplicationForm/EventLogSection'

export const applicationFormClasses = makeClasses((theme) => ({
  container: sharedStyles.formColumnsContainer,
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  statusSelect: {
    width: 324,
    marginBottom: theme.spacing(4),
  },
  formColumnContainer: {
    ...sharedStyles.formColumnContainer,
    width: 460,
  },
  rightColumnContainer: {
    ...sharedStyles.formRightColumnContainer,
    width: 400,
    paddingTop: 38,
  },
}))

interface HouseholdMember {
  wantsCoverage: boolean | null
  person: OutcomePerson
}

const getOutcomePeople = ({
  householdMembers,
}: {
  householdMembers: HouseholdMember[]
}) =>
  householdMembers
    .filter((member, index) => index === 0 || member.wantsCoverage)
    .map(({person}) => person)

type Check = Assert<
  SchemaDoesntHaveExtraFields<
    typeof editMedicaidApplicationFormSchema,
    typeof addUpdateApplicationMutation
  >
>

const EditMedicaidApplicationForm: FC = flowMax(
  addDisplayName('EditMedicaidApplicationForm'),
  addApplicationFormContext,
  typeProp('application', {} as Application_application_MedicaidApplication),
  addProps(({application: {householdMembers}}) => ({
    outcomesPeople: getOutcomePeople({householdMembers}),
  })),
  addProps(
    ({application}) => ({
      initialValues: {
        application: {
          ...application,
          assignedToId: application.assignedTo?.id ?? null,
          primaryPointOfContactId: application.primaryPointOfContact?.id ?? '',
        },
      },
    }),
    ['application']
  ),
  addClasses(applicationFormClasses),
  addWrapper(
    (
      render,
      {classes, onUpdateSuccess, mutateUpdateApplication, initialValues}
    ) => (
      <Form
        name="applicationForm"
        className={classes.form}
        schema={editMedicaidApplicationFormSchema}
        initialValues={initialValues}
        mutate={mutateUpdateApplication}
        onSubmitSuccess={onUpdateSuccess}
        shouldResetFormOnInitialValuesChange
        resetOnSaveSuccess
      >
        {render()}
      </Form>
    )
  ),
  addPrimaryPointOfContactProviderAndOptions(editMedicaidApplicationFormSchema),
  addFormikTyped(editMedicaidApplicationFormSchema),
  addProps(
    ({
      formik: {
        values: {
          application: {submitDate, initialDateOfService},
        },
      },
    }) => ({
      submitDate: submitDate ? parseISO(submitDate) : null,
      initialDateOfService: initialDateOfService
        ? parseISO(initialDateOfService)
        : null,
    })
  ),
  addApplicationBenefitOutcomesContextProvider,
  ({
    application,
    classes,
    primaryPointOfContactOptions,
    refetchQueriesOnStatusChange,
    formik: {
      values: {
        application: {policyId},
      },
    },
  }) => (
    <Grid container className={classes.container} direction="row">
      <ApplicationFormLeftColumn
        application={application}
        policyId={policyId}
      />
      <Grid item className={classes.formColumnContainer}>
        <FormSection
          labelTranslationKey="applicationProcessing"
          shouldMemoize={false}
        >
          <ReadOnlyTextField name="application.id" />
          <SelectField name="application.mcdType" />
          <SelectField
            name="application.primaryPointOfContactId"
            options={primaryPointOfContactOptions}
          />
          <PrimaryPointOfContactDetails />
          <DateField name="application.initialDateOfService" />
          {application.accountOnDateOfService ? (
            <ReadOnlyTextField name="application.accountNumber" />
          ) : (
            <TextField name="application.accountNumber" />
          )}
          <AccountOnDateOfServiceDetails application={application} />
          <DateField name="application.submitDate" />
          <SelectField name="application.submitMethod" />
          <SelectField name="application.countyName" />
          <TextField name="application.confirmationNumber" />
          <TextField name="application.policyId" />
        </FormSection>
        <HouseholdSection />
        <FileTemplateSection />
        <EditableFilesSection />
        <OutcomesSection />
        <DataAndDocumentsSection />
      </Grid>
      <Grid item className={classes.rightColumnContainer}>
        <ApplicationStatusUpdateSelect
          className={classes.statusSelect}
          application={application}
          refetchQueries={refetchQueriesOnStatusChange}
        />
        <AssignedToField
          name="application.assignedToId"
          assignable={application}
        />
        <MultilineTextField rows={2} rowsMax={20} name="application.notes" />
        <EventLogSection />
        <DocumentRequestSection />
        <CommunicationHistorySection />
      </Grid>
    </Grid>
  )
)

export default EditMedicaidApplicationForm
