/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {EditableFileStatus} from './globalTypes' // ====================================================
// GraphQL mutation operation: SendEditableFileToEmr
// ====================================================

import {SendEditableFileToEmrVariables as SendEditableFileToEmrVariablesOrig} from '../types/SendEditableFileToEmr'
import {SendEditableFileToEmr as SendEditableFileToEmrOrig} from '../types/SendEditableFileToEmr'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {SendEditableFileToEmr_sendEditableFileToEmr as SendEditableFileToEmr_sendEditableFileToEmrOrig} from '../types/SendEditableFileToEmr'
export interface SendEditableFileToEmr_sendEditableFileToEmr {
  __typename: 'EditableFile'
  id: string
  fileKey: string
  filename: string
  updatedAt: Date
  status: EditableFileStatus
  allowSigning: boolean | null
  documentSendType: string | null
}
export const deserializeSendEditableFileToEmr_sendEditableFileToEmr = (
  serialized: SendEditableFileToEmr_sendEditableFileToEmrOrig
): SendEditableFileToEmr_sendEditableFileToEmr => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
})
export const serializeSendEditableFileToEmr_sendEditableFileToEmr = (
  deserialized: SendEditableFileToEmr_sendEditableFileToEmr
): SendEditableFileToEmr_sendEditableFileToEmrOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
})
export interface SendEditableFileToEmr {
  sendEditableFileToEmr: SendEditableFileToEmr_sendEditableFileToEmr
}
export const deserializeSendEditableFileToEmr = (
  serialized: SendEditableFileToEmrOrig
): SendEditableFileToEmr => ({
  ...serialized,
  sendEditableFileToEmr: deserializeSendEditableFileToEmr_sendEditableFileToEmr(
    serialized.sendEditableFileToEmr
  ),
})
export const serializeSendEditableFileToEmr = (
  deserialized: SendEditableFileToEmr
): SendEditableFileToEmrOrig => ({
  ...deserialized,
  sendEditableFileToEmr: serializeSendEditableFileToEmr_sendEditableFileToEmr(
    deserialized.sendEditableFileToEmr
  ),
})
export interface SendEditableFileToEmrVariables {
  id: string
}
export const deserializeSendEditableFileToEmrVariables = (
  serialized: SendEditableFileToEmrVariablesOrig
): SendEditableFileToEmrVariables => ({...serialized})
export const serializeSendEditableFileToEmrVariables = (
  deserialized: SendEditableFileToEmrVariables
): SendEditableFileToEmrVariablesOrig => ({...deserialized})
