import {TFunction} from 'i18next'
import {sum} from 'lodash/fp'
import {range} from 'lodash'

import {
  CanonicalValues,
  ClientType,
  EditPersonFormPersonType,
  MARITAL_STATUS_MARRIED,
  MARITAL_STATUS_SEPARATED,
  PersonWithRelationshipType,
  RelationshipType,
  RELATIONSHIP_TYPE_CHILD,
  RELATIONSHIP_TYPE_PARENT,
  RELATIONSHIP_TYPE_SIBLING_STEPSIBLING,
  RELATIONSHIP_TYPE_SPOUSE,
  RELATIONSHIP_TYPE_STEPCHILD,
  RELATIONSHIP_TYPE_STEPPARENT,
  TAX_FILING_STATUS_DEPENDENT,
  TAX_FILING_STATUS_FILER,
  TAX_FILING_STATUS_FILING_JOINTLY,
  TAX_FILING_STATUS_NOT_FILING,
} from 'components/EditPersonForm/schema'
import {
  isPersonWithoutIncome,
  PersonWithoutIncome,
  PERSON_WITHOUT_INCOME,
} from 'utils/income'
import {isMinorCharityCare} from 'utils/charityCare'
import {isMinorMedicaid} from 'utils/medicaid'
import {isUnder18} from 'utils/age'
import {BenefitType} from 'utils/benefits'

export type HouseholdSizeFactor =
  | 'client'
  | 'dependents'
  | 'spouseLivingWith'
  | 'spouseLivingWithNotIncludedInDependents'
  | 'expectedChildren'
  | 'expectedChildrenOfSpouse'
  | 'childrenLivingWith'
  | 'siblingsLivingWith'
  | 'parentsLivingWith'
  | 'filers'
  | 'jointFiler'
  | 'dependentsOfAnyJointFiler'

export const HOUSEHOLD_BOX_MEDICAID_FILER: HouseholdSizeFactor[] = [
  'client',
  'dependents',
  'spouseLivingWithNotIncludedInDependents',
  'expectedChildren',
  'expectedChildrenOfSpouse',
]
export const HOUSEHOLD_BOX_MEDICAID_JOINT_FILER: HouseholdSizeFactor[] = [
  'client',
  'dependents',
  'jointFiler',
  'expectedChildren',
  'expectedChildrenOfSpouse',
]
export const HOUSEHOLD_BOX_MEDICAID_ADULT_NOT_FILING: HouseholdSizeFactor[] = [
  'client',
  'spouseLivingWith',
  'childrenLivingWith',
  'expectedChildren',
  'expectedChildrenOfSpouse',
]
export const HOUSEHOLD_BOX_MEDICAID_MINOR_NOT_FILING: HouseholdSizeFactor[] = [
  'client',
  'siblingsLivingWith',
  'parentsLivingWith',
  'childrenLivingWith',
  'spouseLivingWith',
  'expectedChildren',
  'expectedChildrenOfSpouse',
]
export const HOUSEHOLD_BOX_MEDICAID_DEPENDENT: HouseholdSizeFactor[] = [
  'client',
  'filers',
  'dependentsOfAnyJointFiler',
  'expectedChildren',
  'expectedChildrenOfSpouse',
  'spouseLivingWithNotIncludedInDependents',
]

const relationshipToPersonWithRelationshipType = ({
  otherPerson,
  relationshipType,
}: RelationshipType): PersonWithRelationshipType => ({
  ...otherPerson,
  relationshipType,
  personValues: otherPerson,
})

const relationshipToPersonWithRelationshipTypePossiblyUndefined = (
  relationship: RelationshipType | undefined
): PersonWithRelationshipType | undefined =>
  relationship
    ? relationshipToPersonWithRelationshipType(relationship)
    : undefined

export const determineHouseholdSizeFactorsMedicaid = ({
  person: {
    taxFilingStatus,
    claimedBySomeoneOtherThanParents,
    livingWithParentsWhoDoNotFileJointly,
  },
  person,
}: any): HouseholdSizeFactor[] => {
  if (taxFilingStatus === TAX_FILING_STATUS_FILER)
    return HOUSEHOLD_BOX_MEDICAID_FILER

  if (taxFilingStatus === TAX_FILING_STATUS_FILING_JOINTLY)
    return HOUSEHOLD_BOX_MEDICAID_JOINT_FILER

  if (
    !isMinorMedicaid(person) &&
    (taxFilingStatus === TAX_FILING_STATUS_NOT_FILING ||
      (taxFilingStatus === TAX_FILING_STATUS_DEPENDENT &&
        claimedBySomeoneOtherThanParents))
  )
    return HOUSEHOLD_BOX_MEDICAID_ADULT_NOT_FILING

  if (
    isMinorMedicaid(person) &&
    (taxFilingStatus === TAX_FILING_STATUS_NOT_FILING ||
      (taxFilingStatus === TAX_FILING_STATUS_DEPENDENT &&
        claimedBySomeoneOtherThanParents) ||
      (taxFilingStatus === TAX_FILING_STATUS_DEPENDENT &&
        claimedBySomeoneOtherThanParents === false &&
        livingWithParentsWhoDoNotFileJointly))
  )
    return HOUSEHOLD_BOX_MEDICAID_MINOR_NOT_FILING

  if (
    taxFilingStatus === TAX_FILING_STATUS_DEPENDENT &&
    claimedBySomeoneOtherThanParents === false &&
    (!isMinorMedicaid(person) || livingWithParentsWhoDoNotFileJointly === false)
  )
    return HOUSEHOLD_BOX_MEDICAID_DEPENDENT

  return ['client']
}

const charityCareHouseholdLeftBox: HouseholdSizeFactor[] = [
  'client',
  'spouseLivingWith',
  'expectedChildren',
  'expectedChildrenOfSpouse',
  'childrenLivingWith',
]

const charityCareHouseholdRightBox: HouseholdSizeFactor[] = [
  'client',
  'parentsLivingWith',
  'siblingsLivingWith',
  'childrenLivingWith',
  'expectedChildren',
]

const determineHouseholdSizeFactorsCharityCare = ({
  person: {
    dob,
    maritalStatus,
    spouseLiveWithMe,
    isStudent,
    estParentLiveWithClient,
    relationships,
  },
  full,
}: any): HouseholdSizeFactor[] => {
  const doesSpouseLiveWith = full
    ? !!relationships.find(
        ({relationshipType, liveTogether}: any) =>
          relationshipType === RELATIONSHIP_TYPE_SPOUSE && liveTogether
      )
    : spouseLiveWithMe
  if (maritalStatus === MARITAL_STATUS_MARRIED && doesSpouseLiveWith)
    return charityCareHouseholdLeftBox

  if (!dob) return ['client']

  if (isUnder18(dob)) return charityCareHouseholdRightBox

  const numParentsLiveWith = full
    ? relationships.filter(
        ({relationshipType, liveTogether}: any) =>
          relationshipType &&
          [RELATIONSHIP_TYPE_PARENT, RELATIONSHIP_TYPE_STEPPARENT].includes(
            relationshipType
          ) &&
          liveTogether
      ).length
    : estParentLiveWithClient
  if (isStudent && numParentsLiveWith > 0) return charityCareHouseholdRightBox

  return charityCareHouseholdLeftBox
}

const determineDependentsOfExcludingClient = (
  filers: PersonWithRelationshipType[]
) => (person: ClientType) => {
  const filerIds = filers.map(({id}) => id)
  return person.relationships
    .filter(
      ({otherPerson: {taxFilingStatus, dependentOfTaxFilerId}}) =>
        taxFilingStatus === TAX_FILING_STATUS_DEPENDENT &&
        dependentOfTaxFilerId &&
        filerIds.includes(dependentOfTaxFilerId)
    )
    .map(relationshipToPersonWithRelationshipType)
}

const isDependentOf = (filer: PersonWithRelationshipType) => ({
  dependentOfTaxFilerId,
}: PersonWithRelationshipType) =>
  dependentOfTaxFilerId && dependentOfTaxFilerId === filer.id

export const determineHouseholdSizePartsPreliminaryMedicaid = ({
  person: {
    estTaxDependentCount,
    spouseLiveWithMe,
    taxDependentInclSpouseLiveWithClient,
    pregnant,
    expectedChild,
    maritalStatus,
    estChildren19LiveWithClient,
    estMinor19SiblingLiveWithClient,
    estParentLiveWithClient,
    spousePregnant,
  },
  person,
}: any): [HouseholdSizeFactor, number][] => {
  const isSpouseLivingWith =
    [MARITAL_STATUS_MARRIED, MARITAL_STATUS_SEPARATED].includes(
      maritalStatus
    ) && spouseLiveWithMe
  const numExpectedChildren = pregnant ? expectedChild ?? 0 : 0
  const numExpectedChildrenOfSpouse =
    isSpouseLivingWith && spousePregnant ? 1 : 0
  const numTaxDependents = estTaxDependentCount ?? 0
  const numSpouseLivingWith = isSpouseLivingWith ? 1 : 0
  const numSpouseLivingWithNotIncludedInTaxDependents =
    isSpouseLivingWith && !taxDependentInclSpouseLiveWithClient ? 1 : 0
  const numChildrenLivingWith = estChildren19LiveWithClient ?? 0
  const numSiblingsLivingWith = estMinor19SiblingLiveWithClient ?? 0
  const numParentsLivingWith = estParentLiveWithClient ?? 0

  const counts: Record<HouseholdSizeFactor, number> = {
    client: 1,
    dependents: numTaxDependents,
    dependentsOfAnyJointFiler: numTaxDependents,
    spouseLivingWithNotIncludedInDependents: numSpouseLivingWithNotIncludedInTaxDependents,
    expectedChildren: numExpectedChildren,
    expectedChildrenOfSpouse: numExpectedChildrenOfSpouse,
    spouseLivingWith: numSpouseLivingWith,
    childrenLivingWith: numChildrenLivingWith,
    siblingsLivingWith: numSiblingsLivingWith,
    parentsLivingWith: numParentsLivingWith,
    filers: 2,
    jointFiler: 1,
  }

  return determineHouseholdSizeFactorsMedicaid({person}).map((factor) => [
    factor,
    counts[factor],
  ])
}

export const determineHouseholdSizePartsPreliminaryCharityCare = ({
  person: {
    estTaxDependentCount,
    spouseLiveWithMe,
    taxDependentInclSpouseLiveWithClient,
    pregnant,
    expectedChild,
    maritalStatus,
    children18LiveWithClient,
    minor18SiblingLiveWithClient,
    estParentLiveWithClient,
    spousePregnant,
  },
  person,
}: any): [HouseholdSizeFactor, number][] => {
  const isSpouseLivingWith =
    maritalStatus === MARITAL_STATUS_MARRIED && spouseLiveWithMe
  const numExpectedChildren = pregnant ? expectedChild ?? 0 : 0
  const numExpectedChildrenOfSpouse =
    isSpouseLivingWith && spousePregnant ? 1 : 0
  const numTaxDependents = estTaxDependentCount ?? 0
  const numSpouseLivingWith = isSpouseLivingWith ? 1 : 0
  const numSpouseLivingWithNotIncludedInTaxDependents =
    isSpouseLivingWith && !taxDependentInclSpouseLiveWithClient ? 1 : 0
  const numChildrenLivingWith = children18LiveWithClient ?? 0
  const numSiblingsLivingWith = minor18SiblingLiveWithClient ?? 0
  const numParentsLivingWith = estParentLiveWithClient ?? 0

  const counts: Record<HouseholdSizeFactor, number> = {
    client: 1,
    dependents: numTaxDependents,
    dependentsOfAnyJointFiler: numTaxDependents,
    spouseLivingWithNotIncludedInDependents: numSpouseLivingWithNotIncludedInTaxDependents,
    expectedChildren: numExpectedChildren,
    expectedChildrenOfSpouse: numExpectedChildrenOfSpouse,
    spouseLivingWith: numSpouseLivingWith,
    childrenLivingWith: numChildrenLivingWith,
    siblingsLivingWith: numSiblingsLivingWith,
    parentsLivingWith: numParentsLivingWith,
    filers: 2,
    jointFiler: 1,
  }

  return determineHouseholdSizeFactorsCharityCare({person}).map((factor) => [
    factor,
    counts[factor],
  ])
}

const determineMainFiler = (
  person: ClientType
): PersonWithRelationshipType | null => {
  const {taxFilingStatus, dependentOfTaxFilerId, relationships} = person
  if (
    taxFilingStatus &&
    [TAX_FILING_STATUS_FILER, TAX_FILING_STATUS_FILING_JOINTLY].includes(
      taxFilingStatus
    )
  )
    return {
      ...person,
      relationshipType: 'client',
      personValues: person,
    }
  if (taxFilingStatus !== TAX_FILING_STATUS_DEPENDENT) return null
  if (!dependentOfTaxFilerId) return null
  const dependentOfRelationshipIndex = relationships.findIndex(
    ({otherPerson: {id}}) => id === dependentOfTaxFilerId
  )
  if (dependentOfRelationshipIndex === -1) return null
  const dependentOfRelationship = relationships[dependentOfRelationshipIndex]
  return {
    ...dependentOfRelationship.otherPerson,
    relationshipType: dependentOfRelationship.relationshipType,
    relationshipIndex: dependentOfRelationshipIndex,
    personValues: dependentOfRelationship.otherPerson,
  }
}

export const determineHouseholdSizePartsFullMedicaid = ({
  person: {relationships, pregnant, expectedChild},
  person,
}: CanonicalValues): [
  HouseholdSizeFactor,
  (PersonWithRelationshipType | PersonWithoutIncome)[]
][] => {
  const mainFiler = determineMainFiler(person)
  const dependents = mainFiler
    ? determineDependentsOfExcludingClient([mainFiler])(person)
    : []
  const spouseLivingWith = relationshipToPersonWithRelationshipTypePossiblyUndefined(
    relationships.find(
      ({relationshipType, liveTogether}) =>
        relationshipType === RELATIONSHIP_TYPE_SPOUSE && liveTogether
    )
  )
  let isSpouseMainFiler = false
  const spouseNotNecessarilyLivingWith = relationshipToPersonWithRelationshipTypePossiblyUndefined(
    relationships.find(({relationshipType}, relationshipIndex) => {
      const isSpouse = relationshipType === RELATIONSHIP_TYPE_SPOUSE
      if (isSpouse) {
        isSpouseMainFiler = relationshipIndex === mainFiler?.relationshipIndex
      }
      return isSpouse
    })
  )
  const spouseLivingWithNotIncludedInDependents =
    spouseLivingWith &&
    !(mainFiler && isDependentOf(mainFiler)(spouseLivingWith))
      ? [spouseLivingWith]
      : []
  const allOtherJointFilers = relationships
    .filter(
      ({otherPerson: {taxFilingStatus}}, relationshipIndex) =>
        relationshipIndex !== mainFiler?.relationshipIndex &&
        taxFilingStatus === TAX_FILING_STATUS_FILING_JOINTLY
    )
    .map(relationshipToPersonWithRelationshipType)
  const dependentsOfAnyJointFiler = determineDependentsOfExcludingClient([
    ...(mainFiler ? [mainFiler] : []),
    ...allOtherJointFilers,
  ])(person)
  const numExpectedChildren = pregnant ? expectedChild ?? 0 : 0
  const expectedChildren = range(numExpectedChildren).map(
    () => PERSON_WITHOUT_INCOME
  )
  const numExpectedChildrenOfSpouse = spouseLivingWith?.pregnant
    ? spouseLivingWith.expectedChild ?? 0
    : 0
  const expectedChildrenOfSpouse = range(numExpectedChildrenOfSpouse).map(
    () => PERSON_WITHOUT_INCOME
  )
  const childrenLivingWith = relationships
    .filter(
      ({relationshipType, liveTogether, otherPerson}) =>
        relationshipType &&
        [RELATIONSHIP_TYPE_CHILD, RELATIONSHIP_TYPE_STEPCHILD].includes(
          relationshipType
        ) &&
        liveTogether &&
        isMinorMedicaid(otherPerson)
    )
    .map(relationshipToPersonWithRelationshipType)
  const siblingsLivingWith = relationships
    .filter(
      ({relationshipType, liveTogether, otherPerson}) =>
        relationshipType === RELATIONSHIP_TYPE_SIBLING_STEPSIBLING &&
        liveTogether &&
        isMinorMedicaid(otherPerson)
    )
    .map(relationshipToPersonWithRelationshipType)
  const parentsLivingWith = relationships
    .filter(
      ({relationshipType, liveTogether}) =>
        relationshipType &&
        [RELATIONSHIP_TYPE_PARENT, RELATIONSHIP_TYPE_STEPPARENT].includes(
          relationshipType
        ) &&
        liveTogether
    )
    .map(relationshipToPersonWithRelationshipType)
    .flatMap((person) => [
      person,
      ...range(person.pregnant ? person.expectedChild ?? 0 : 0).map(
        () => PERSON_WITHOUT_INCOME
      ),
    ])

  const peopleByFactor: Record<
    HouseholdSizeFactor,
    (PersonWithRelationshipType | PersonWithoutIncome)[]
  > = {
    client: [
      {
        ...person,
        relationshipType: 'client',
        personValues: person,
      },
    ],
    dependents,
    dependentsOfAnyJointFiler,
    spouseLivingWith: spouseLivingWith ? [spouseLivingWith] : [],
    spouseLivingWithNotIncludedInDependents,
    expectedChildren,
    expectedChildrenOfSpouse,
    childrenLivingWith,
    siblingsLivingWith,
    parentsLivingWith,
    filers: mainFiler
      ? mainFiler.taxFilingStatus === TAX_FILING_STATUS_FILER
        ? isSpouseMainFiler
          ? []
          : [mainFiler]
        : [...(isSpouseMainFiler ? [] : [mainFiler]), ...allOtherJointFilers]
      : [],
    jointFiler: spouseNotNecessarilyLivingWith
      ? [spouseNotNecessarilyLivingWith]
      : [PERSON_WITHOUT_INCOME],
  }

  return determineHouseholdSizeFactorsMedicaid({person}).map((factor) => [
    factor,
    peopleByFactor[factor],
  ])
}

export const determineHouseholdSizePartsFullCharityCare = ({
  person: {relationships, pregnant, expectedChild},
  person,
}: {
  person: ClientType
}): [
  HouseholdSizeFactor,
  (PersonWithRelationshipType | PersonWithoutIncome)[]
][] => {
  const spouseLivingWith = relationshipToPersonWithRelationshipTypePossiblyUndefined(
    relationships.find(
      ({relationshipType, liveTogether}) =>
        relationshipType === RELATIONSHIP_TYPE_SPOUSE && liveTogether
    )
  )
  const numExpectedChildren = pregnant ? expectedChild ?? 0 : 0
  const expectedChildren = range(numExpectedChildren).map(
    () => PERSON_WITHOUT_INCOME
  )
  const numExpectedChildrenOfSpouse = spouseLivingWith?.pregnant
    ? spouseLivingWith.expectedChild ?? 0
    : 0
  const expectedChildrenOfSpouse = range(numExpectedChildrenOfSpouse).map(
    () => PERSON_WITHOUT_INCOME
  )
  const childrenLivingWith = relationships
    .filter(
      ({relationshipType, liveTogether, otherPerson}) =>
        relationshipType &&
        [RELATIONSHIP_TYPE_CHILD, RELATIONSHIP_TYPE_STEPCHILD].includes(
          relationshipType
        ) &&
        liveTogether &&
        isMinorCharityCare(otherPerson, {livesWithParents: true})
    )
    .map(relationshipToPersonWithRelationshipType)
    .flatMap((person) => [
      person,
      ...range(person.pregnant ? person.expectedChild ?? 0 : 0).map(
        () => PERSON_WITHOUT_INCOME
      ),
    ])
  const parentsLivingWith = relationships
    .filter(
      ({relationshipType, liveTogether}) =>
        relationshipType &&
        [RELATIONSHIP_TYPE_PARENT, RELATIONSHIP_TYPE_STEPPARENT].includes(
          relationshipType
        ) &&
        liveTogether
    )
    .map(relationshipToPersonWithRelationshipType)
    .flatMap((person) => [
      person,
      ...range(person.pregnant ? person.expectedChild ?? 0 : 0).map(
        () => PERSON_WITHOUT_INCOME
      ),
    ])
  const siblingsLivingWith = relationships
    .filter(
      ({relationshipType, liveTogether, otherPerson}) =>
        relationshipType === RELATIONSHIP_TYPE_SIBLING_STEPSIBLING &&
        liveTogether &&
        isMinorCharityCare(otherPerson, {
          livesWithParents: parentsLivingWith.length > 0,
        })
    )
    .map(relationshipToPersonWithRelationshipType)
    .flatMap((person) => [
      person,
      ...range(person.pregnant ? person.expectedChild ?? 0 : 0).map(
        () => PERSON_WITHOUT_INCOME
      ),
    ])

  const peopleByFactor: Record<
    HouseholdSizeFactor,
    (PersonWithRelationshipType | PersonWithoutIncome)[]
  > = {
    client: [
      {
        ...person,
        relationshipType: 'client',
        personValues: person,
      },
    ],
    dependents: [],
    dependentsOfAnyJointFiler: [],
    spouseLivingWith: spouseLivingWith ? [spouseLivingWith] : [],
    spouseLivingWithNotIncludedInDependents: [],
    expectedChildren,
    expectedChildrenOfSpouse,
    childrenLivingWith,
    siblingsLivingWith,
    parentsLivingWith,
    filers: [],
    jointFiler: [],
  }

  return determineHouseholdSizeFactorsCharityCare({
    person,
    full: true,
  }).map((factor) => [factor, peopleByFactor[factor]])
}

export const determineHouseholdSizePartsFullSlide = ({
  person: {relationships},
  person,
}: {
  person: ClientType
}): [
  HouseholdSizeFactor,
  (PersonWithRelationshipType | PersonWithoutIncome)[]
][] => {
  const numExpectedChildren = [
    person,
    ...relationships.map(({otherPerson}) => otherPerson),
  ].filter(({pregnant}) => pregnant).length
  const expectedChildren = range(numExpectedChildren).map(
    () => PERSON_WITHOUT_INCOME
  )

  return [
    [
      'client',
      [
        {
          ...person,
          relationshipType: 'client',
          personValues: person,
        },
        ...relationships.map(({otherPerson, relationshipType}) => ({
          ...otherPerson,
          relationshipType,
          personValues: person,
        })),
        ...expectedChildren,
      ],
    ],
  ]
}

export const determineHouseholdSizePreliminaryMedicaid = ({
  person,
}: any): number | undefined => {
  const parts = determineHouseholdSizePartsPreliminaryMedicaid({person})
  if (parts.length <= 1) return

  return sum(parts.map(([_factor, count]) => count))
}

export const determineHouseholdSizePreliminaryCharityCare = ({
  person,
}: any): number | undefined => {
  const parts = determineHouseholdSizePartsPreliminaryCharityCare({person})
  if (parts.length <= 1) return

  return sum(parts.map(([_factor, count]) => count))
}

export const determineHouseholdSizeFullMedicaid = ({
  person,
}: any): number | undefined => {
  const parts = determineHouseholdSizePartsFullMedicaid({person})
  return sum(parts.map(([_factor, people]) => people.length))
}

export const determineHouseholdSizeFullCharityCare = ({
  person,
}: CanonicalValues): number | undefined => {
  const parts = determineHouseholdSizePartsFullCharityCare({person})
  return sum(parts.map(([_factor, people]) => people.length))
}

export const determineHouseholdSizeFullSlide = ({
  person,
}: CanonicalValues): number | undefined => {
  const parts = determineHouseholdSizePartsFullSlide({person})
  return sum(parts.map(([_factor, people]) => people.length))
}

const getHouseholdSizeDescription = ({
  householdSize,
  householdSizeParts,
  t,
  dontShowParts,
}: {
  householdSize: number | undefined
  householdSizeParts: [HouseholdSizeFactor, number][]
  t: TFunction
  dontShowParts?: boolean
}) => {
  const householdSizePartsDescription = householdSizeParts
    .filter(([_factor, count]) => count > 0)
    .map(([factor, count]) =>
      t(`personForm.eligibilityCheck.householdSizeParts.${factor}`, {count})
    )
    .join(', ')
  if (dontShowParts)
    return t('personForm.eligibilityCheck.householdSizeNoParts', {
      size: householdSize ?? 1,
    })
  return t('personForm.eligibilityCheck.householdSize', {
    size: householdSize ?? 1,
    parts: householdSizePartsDescription,
  })
}

export const getHouseholdSizeDescriptionPreliminaryMedicaid = (
  canonicalValues: CanonicalValues,
  t: TFunction
) =>
  getHouseholdSizeDescription({
    householdSize: determineHouseholdSizePreliminaryMedicaid(canonicalValues),
    householdSizeParts: determineHouseholdSizePartsPreliminaryMedicaid(
      canonicalValues
    ),
    t,
  })

export const getHouseholdSizeDescriptionPreliminaryCharityCare = (
  canonicalValues: CanonicalValues,
  t: TFunction
) =>
  getHouseholdSizeDescription({
    householdSize: determineHouseholdSizePreliminaryCharityCare(
      canonicalValues
    ),
    householdSizeParts: determineHouseholdSizePartsPreliminaryCharityCare(
      canonicalValues
    ),
    t,
  })

export const getHouseholdSizeDescriptionFullMedicaid = (
  canonicalValues: CanonicalValues,
  t: TFunction
) =>
  getHouseholdSizeDescription({
    householdSize: determineHouseholdSizeFullMedicaid(canonicalValues),
    householdSizeParts: determineHouseholdSizePartsFullMedicaid(
      canonicalValues
    ).map(([factor, people]) => [factor, people.length]),
    t,
  })

export const getHouseholdSizeDescriptionFullCharityCare = (
  canonicalValues: CanonicalValues,
  t: TFunction
) =>
  getHouseholdSizeDescription({
    householdSize: determineHouseholdSizeFullCharityCare(canonicalValues),
    householdSizeParts: determineHouseholdSizePartsFullCharityCare(
      canonicalValues
    ).map(([factor, people]) => [factor, people.length]),
    t,
  })

export const getHouseholdSizeDescriptionFullSlide = (
  canonicalValues: CanonicalValues,
  t: TFunction
) =>
  getHouseholdSizeDescription({
    householdSize: determineHouseholdSizeFullSlide(canonicalValues),
    householdSizeParts: determineHouseholdSizePartsFullSlide(
      canonicalValues
    ).map(([factor, people]) => [factor, people.length]),
    t,
    dontShowParts: true,
  })

const isSamePerson = (
  householdMember: PersonWithRelationshipType,
  personValues: EditPersonFormPersonType
) => householdMember.personValues === personValues

export const findPersonHouseholdSizeFactor = (benefit: BenefitType) => (
  values: CanonicalValues,
  personValues: EditPersonFormPersonType
): HouseholdSizeFactor | null => {
  const determineHouseholdSizePartsFull =
    benefit === 'medicaid'
      ? determineHouseholdSizePartsFullMedicaid
      : determineHouseholdSizePartsFullCharityCare
  const householdSizeParts = determineHouseholdSizePartsFull(values)
  const part = householdSizeParts.find(([_factor, people]) =>
    people.some(
      (householdMember) =>
        !isPersonWithoutIncome(householdMember) &&
        isSamePerson(householdMember, personValues)
    )
  )
  if (!part) return null
  return part[0]
}

export const isPersonInHousehold = (benefit: BenefitType) => (
  values: CanonicalValues,
  personValues: EditPersonFormPersonType
): boolean => {
  const determineHouseholdSizePartsFull =
    benefit === 'medicaid'
      ? determineHouseholdSizePartsFullMedicaid
      : determineHouseholdSizePartsFullCharityCare
  const householdSizeParts = determineHouseholdSizePartsFull(values)
  const part = householdSizeParts.find(([_factor, people]) =>
    people.some(
      (householdMember) =>
        !isPersonWithoutIncome(householdMember) &&
        isSamePerson(householdMember, personValues)
    )
  )
  return !!part
}
