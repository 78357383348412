/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {EditableFileStatus} from './globalTypes' // ====================================================
// GraphQL mutation operation: MarkEditableFileReviewed
// ====================================================

import {MarkEditableFileReviewedVariables as MarkEditableFileReviewedVariablesOrig} from '../types/MarkEditableFileReviewed'
import {MarkEditableFileReviewed as MarkEditableFileReviewedOrig} from '../types/MarkEditableFileReviewed'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {MarkEditableFileReviewed_markEditableFileReviewed as MarkEditableFileReviewed_markEditableFileReviewedOrig} from '../types/MarkEditableFileReviewed'
export interface MarkEditableFileReviewed_markEditableFileReviewed {
  __typename: 'EditableFile'
  id: string
  fileKey: string
  filename: string
  updatedAt: Date
  status: EditableFileStatus
  allowSigning: boolean | null
  documentSendType: string | null
  isUnreviewed: boolean
}
export const deserializeMarkEditableFileReviewed_markEditableFileReviewed = (
  serialized: MarkEditableFileReviewed_markEditableFileReviewedOrig
): MarkEditableFileReviewed_markEditableFileReviewed => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
})
export const serializeMarkEditableFileReviewed_markEditableFileReviewed = (
  deserialized: MarkEditableFileReviewed_markEditableFileReviewed
): MarkEditableFileReviewed_markEditableFileReviewedOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
})
export interface MarkEditableFileReviewed {
  markEditableFileReviewed: MarkEditableFileReviewed_markEditableFileReviewed
}
export const deserializeMarkEditableFileReviewed = (
  serialized: MarkEditableFileReviewedOrig
): MarkEditableFileReviewed => ({
  ...serialized,
  markEditableFileReviewed: deserializeMarkEditableFileReviewed_markEditableFileReviewed(
    serialized.markEditableFileReviewed
  ),
})
export const serializeMarkEditableFileReviewed = (
  deserialized: MarkEditableFileReviewed
): MarkEditableFileReviewedOrig => ({
  ...deserialized,
  markEditableFileReviewed: serializeMarkEditableFileReviewed_markEditableFileReviewed(
    deserialized.markEditableFileReviewed
  ),
})
export interface MarkEditableFileReviewedVariables {
  editableFileId: string
  applicationId: string
}
export const deserializeMarkEditableFileReviewedVariables = (
  serialized: MarkEditableFileReviewedVariablesOrig
): MarkEditableFileReviewedVariables => ({...serialized})
export const serializeMarkEditableFileReviewedVariables = (
  deserialized: MarkEditableFileReviewedVariables
): MarkEditableFileReviewedVariablesOrig => ({...deserialized})
