import React, {FC} from 'react'
import {flowMax, addDisplayName, addHandlers} from 'ad-hok'
import Dropzone, {
  IFileWithMeta,
  StatusValue,
  IUploadParams,
} from 'react-dropzone-uploader'
import {v4 as uuid} from 'uuid'

import {makeClasses, addClasses} from 'theme'
import {addTranslationHelpers} from 'utils/i18n'
import addApolloClient from 'utils/addApolloClient'
import {addDocumentFilesContext} from 'components/DocumentFiles/context'
import addFetchSignedDocumentUploadUrl from 'utils/addFetchSignedDocumentUploadUrl'

const classes = makeClasses((theme) => ({
  dropzone: {
    marginTop: theme.spacing(2),
    borderColor: '#C0C0C0',
    borderWidth: 1,
  },
  inputLabel: {
    fontFamily: 'Roboto',
    fontWeight: 400,
  },
  inputLabelWithFiles: {
    display: 'none',
  },
}))

export interface UploadedFile {
  name: string
  fileKey: string
}

interface Props {
  onFileUpload: (opts: {file: UploadedFile}) => void
  onFileRemove?: (opts: {file: UploadedFile}) => void
}

const DocumentFileUploader: FC<Props> = flowMax(
  addDisplayName('DocumentFileUploader'),
  addApolloClient,
  addDocumentFilesContext,
  addFetchSignedDocumentUploadUrl,
  addHandlers({
    getUploadParams: ({fileKeyPrefix, fetchSignedDocumentUploadUrl}) => async ({
      file,
      meta: {name},
    }: IFileWithMeta): Promise<IUploadParams> => {
      const fileKey = `${fileKeyPrefix}/${uuid()}/${name}`
      const url = await fetchSignedDocumentUploadUrl(fileKey)

      return {
        body: file,
        url,
        method: 'PUT',
        meta: {fileKey},
      }
    },
    handleChangeStatus: ({onFileUpload, onFileRemove}) => (
      {meta: {name, fileKey}}: IFileWithMeta & {meta: {fileKey: string}},
      status: StatusValue
    ) => {
      if (status === 'done') {
        onFileUpload({file: {name, fileKey}})
      } else if (status === 'removed') {
        onFileRemove?.({file: {name, fileKey}})
      }
    },
  }),
  addClasses(classes),
  addTranslationHelpers,
  ({getUploadParams, handleChangeStatus, classes, t}) => (
    <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      addClassNames={classes}
      inputContent={t('documentUpload.instructions')}
      inputWithFilesContent={null}
      multiple={false}
      maxFiles={1}
    />
  )
)

export default DocumentFileUploader
