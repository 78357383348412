import React, {ReactNode} from 'react'
import {Select} from 'formik-material-ui'
import {FastField} from 'formik'
import {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addProps,
  addWrapper,
  addHandlers,
  addMemoBoundary,
  branch,
  renderNothing,
} from 'ad-hok'
import BaseSelect from '@material-ui/core/Select'
import {isFunction, get} from 'lodash/fp'
import {
  addPropIdentityStabilization,
  addComponentBoundary,
  removeProps,
  getContextHelpers,
  toObjectKeys,
} from 'ad-hok-utils'
import {TFunction} from 'i18next'

import {FieldComponentProps} from 'components/TextField'
import FormControl from 'components/FormControl'
import InputLabel from 'components/InputLabel'
import {makeClasses, addClasses} from 'theme'
import FormHelperText from 'components/FormHelperText'
import {addFieldError} from 'utils/field'
import {FormFieldWithFeedbackIconContainer} from 'components/FormFieldWithFeedbackIconContainer'
import {addFormContext} from 'utils/form/context'
import {addFormik} from 'utils/form/formik'
import {FormValues} from 'utils/form/schema'
import {addTranslationHelpers} from 'utils/i18n'
import MenuItem from 'components/MenuItem'
import {SelectFieldOption} from 'utils/form/fieldTypes'
import Checkbox from 'components/Checkbox'
import ListItemText from 'components/ListItemText'
import {getCommaSeparated} from 'utils/string'
import {FormFieldConfirmFreshnessButton} from 'components/FormFieldConfirmFreshnessButton'
import {addFieldInfoWithMemoBoundary} from 'components/Field'
import FieldScrollTarget from 'components/FieldScrollTarget'

const classes = makeClasses((theme) => ({
  container: {
    display: 'flex',
    marginBottom: theme.spacing(4),
    position: 'relative',
  },
}))

const defaultFastFieldShouldUpdate = (props: any, propsPrevious: any) => {
  const {
    name,
    formik: {values, errors, touched, isSubmitting},
  } = props
  const {
    formik: {
      values: valuesPrevious,
      errors: errorsPrevious,
      touched: touchedPrevious,
      isSubmitting: isSubmittingPrevious,
    },
  } = propsPrevious

  return (
    name !== propsPrevious.name ||
    get(name, valuesPrevious) !== get(name, values) ||
    get(name, errorsPrevious) !== get(name, errors) ||
    get(name, touchedPrevious) !== get(name, touched) ||
    Object.keys(props).length !== Object.keys(propsPrevious).length ||
    isSubmittingPrevious !== isSubmitting
  )
}

interface MultiSelectOptionCheckboxProps {
  name: string
  value: string
}

const MultiSelectOptionCheckbox: FC<MultiSelectOptionCheckboxProps> = flowMax(
  addDisplayName('MultiSelectOptionCheckbox'),
  addFormik,
  addProps(
    ({formik: {values}, name}) => ({
      currentValue: get(name, values) as string[],
    }),
    ['formik.values', 'name']
  ),
  addProps(
    ({currentValue, value}) => ({
      checked: currentValue.includes(value),
    }),
    ['currentValue', 'value']
  ),
  ({checked}) => <Checkbox checked={checked} />
)

const isCollectionParentMissing = ({
  name,
  values,
}: {
  name: string
  values: any
}): boolean => {
  const endBracketIndex = name.lastIndexOf(']')
  if (!(endBracketIndex >= 0)) return false
  const parentCollectionPath = name.substring(0, endBracketIndex + 1)
  const parentCollectionValues = get(parentCollectionPath, values)
  if (parentCollectionValues == null) return true
  return false
}

const [addLocalContextProvider, addLocalContext] = getContextHelpers<{
  options: SelectFieldOption[]
  fieldError: string | null
  disabled?: boolean | undefined
  name: string
  id: string
  labelId: string
}>(toObjectKeys(['options', 'fieldError', 'disabled', 'name', 'id', 'labelId']))

export interface SelectFieldProps extends FieldComponentProps {
  multiple?: boolean
  options?: SelectFieldOption[]
  renderValue?: (selected: string[]) => ReactNode
  renderOptionIcon?: (opts: {value: string}) => ReactNode
  shouldIgnoreTouchedForFieldError?: boolean
  showSelectedOptionForNonexistentValue?: (opts: {
    values: FormValues<any>
    name: string
    t: TFunction
  }) => SelectFieldOption
}

const SelectField: FC<SelectFieldProps> = flowMax(
  addDisplayName('SelectField'),
  addMemoBoundary(['options']),
  addProps({
    exampleValue: '' as string,
  }),
  addFieldInfoWithMemoBoundary(['options']),
  addFormContext,
  addProps(({formName, name}) => ({
    id: `${formName}-${name}-select`,
  })),
  addProps(({id}) => ({
    labelId: `${id}-label`,
  })),
  addComponentBoundary,
  addFormik,
  branch(
    ({name, formik: {values}}) => isCollectionParentMissing({name, values}),
    renderNothing()
  ),
  addMemoBoundary(
    ({fieldSchema: {options}}) => !!options && !isFunction(options)
  ),
  addTranslationHelpers,
  addProps(
    ({options, fieldSchema, formik: {values}, t}) => ({
      options:
        options ??
        (isFunction(fieldSchema.options)
          ? fieldSchema.options({values: values as FormValues<any>, t})
          : fieldSchema.options!),
    }),
    ['options', 'fieldSchema', 'formik.values', 't']
  ),
  addProps(
    ({
      options,
      formik: {values},
      showSelectedOptionForNonexistentValue,
      name,
      t,
    }) => {
      if (!showSelectedOptionForNonexistentValue) return {options}
      const value = get(name, values)
      if (!value) return {options}
      if (options.some((option) => option.value === value)) return {options}
      return {
        options: [
          showSelectedOptionForNonexistentValue({
            values: values as FormValues<any>,
            name,
            t,
          }),
          ...options,
        ],
      }
    },
    [
      'options',
      'formik.values',
      'showSelectedOptionForNonexistentValue',
      'name',
      't',
    ]
  ),
  addPropIdentityStabilization('options'),
  addComponentBoundary,
  addFieldError,
  addMemoBoundary([
    'options',
    'fieldError',
    'disabled',
    'name',
    'id',
    'labelId',
  ]),
  addClasses(classes),
  removeProps([
    'exampleValue',
    'fieldSchema',
    'formName',
    'formSchema',
    'registerFieldToFormSection',
    'formSections',
    'scrollToFormSection',
    'addFormSectionRef',
    'scrollToFormField',
    'addFormFieldRef',
    't',
    'shouldTrackFieldsUpdatedAt',
    'toggleFreshnessConfirmed',
    'setCollectionItemFieldsFreshness',
    'removeCollectionItemFieldsFreshness',
    'setCollectionItemFieldsUpdatedAt',
    'removeCollectionItemFieldsUpdatedAt',
    'shouldIgnoreTouchedForFieldError',
    'showSelectedOptionForNonexistentValue',
  ]),
  addLocalContextProvider,
  addWrapper(
    (
      render,
      {
        required,
        classes,
        fieldError,
        'data-testid': testId,
        noIcon,
        name,
        lazyIcon,
        callbackRef,
      }
    ) => (
      <FormControl
        variant="outlined"
        required={required}
        className={classes.container}
        error={!!fieldError}
        data-testid={testId}
      >
        <FieldScrollTarget ref={callbackRef} offset={40} />
        {noIcon ? (
          render()
        ) : (
          <FormFieldWithFeedbackIconContainer name={name} lazy={lazyIcon}>
            {render()}
          </FormFieldWithFeedbackIconContainer>
        )}
      </FormControl>
    )
  ),
  addLocalContext,
  addWrapper((render, {showConfirmButton, noConfirmButton, name, lazyIcon}) =>
    showConfirmButton && !noConfirmButton ? (
      <>
        {render()}
        <FormFieldConfirmFreshnessButton
          name={name}
          anchored
          lazyIcon={lazyIcon}
        />
      </>
    ) : (
      render()
    )
  ),
  addProps(({helperText, fieldError}) => ({
    helperText: fieldError || helperText,
  })),
  removeProps([
    'noIcon',
    'showConfirmButton',
    'noConfirmButton',
    'fieldError',
    'required',
    'classes',
    'data-testid',
    'lazyIcon',
    'callbackRef',
  ]),
  addHandlers({
    shouldUpdate: () => (nextProps: any, props: any) => {
      if (nextProps.options !== props.options) return true
      return defaultFastFieldShouldUpdate(nextProps, props)
    },
  }),
  addProps(
    ({renderValue, multiple, options}) => ({
      renderValue: multiple
        ? ((renderValue ??
            ((selected: string[]) =>
              getCommaSeparated(
                selected.map(
                  (value) =>
                    options.find((option) => option.value === value)?.label
                )
              ))) as (selected: unknown) => ReactNode)
        : undefined,
    }),
    ['renderValue', 'multiple', 'options']
  ),
  ({
    label,
    id,
    name,
    options,
    labelId,
    helperText,
    shouldUpdate,
    multiple,
    disabled,
    renderValue,
    renderOptionIcon,
    ...props
  }) => (
    <>
      <InputLabel id={labelId} htmlFor={id}>
        {label}
      </InputLabel>
      <FastField
        component={Select}
        name={name}
        id={id}
        labelId={labelId}
        fullWidth
        label={label}
        native={!multiple}
        multiple={multiple}
        displayEmpty={multiple}
        renderValue={renderValue}
        shouldUpdate={shouldUpdate}
        options={options}
        disabled={disabled}
        MenuProps={
          multiple
            ? {
                variant: 'menu',
                getContentAnchorEl: null,
              }
            : {}
        }
        {...props}
      >
        <option value=""></option>
        {options.map(({label, value}) =>
          multiple ? (
            <MenuItem value={value} key={value}>
              <MultiSelectOptionCheckbox value={value} name={name} />
              <ListItemText primary={label} />
              {renderOptionIcon?.({value})}
            </MenuItem>
          ) : (
            <option value={value} key={value}>
              {label}
            </option>
          )
        )}
      </FastField>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </>
  )
)

export default SelectField

interface StandaloneSelectFieldProps {
  value: string
  onChange: (event: React.ChangeEvent<{value: unknown}>) => void
  id: string
  label: string
  options: {
    label: string
    value: string
  }[]
  className?: string
  shrink?: boolean
  disabled?: boolean
}

export const StandaloneSelectField: FC<StandaloneSelectFieldProps> = flowMax(
  addDisplayName('StandaloneSelectField'),
  addProps(({id}) => ({
    labelId: `${id}-label`,
  })),
  ({
    label,
    id,
    options,
    labelId,
    value,
    onChange,
    className,
    shrink,
    disabled,
  }) => (
    <FormControl variant="outlined" className={className}>
      <InputLabel id={labelId} htmlFor={id} shrink={shrink}>
        {label}
      </InputLabel>
      <BaseSelect
        value={value}
        onChange={onChange}
        id={id}
        labelId={labelId}
        label={label}
        native
        disabled={disabled}
      >
        {options.map(({label, value}) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </BaseSelect>
    </FormControl>
  )
)
