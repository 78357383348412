import {
  getContextHelpers,
  toObjectKeys,
  branchIfPassesPredicate,
} from 'ad-hok-utils'
import {
  flowMax,
  SimpleUnchangedProps,
  addProps,
  SimplePropsAdder,
  addHandlers,
} from 'ad-hok'

import {
  Config_config,
  Config_config_facilities,
  Config_config_applicationBenefits,
  Config_config_departments,
  Config_config_charityCareTypes,
} from 'graphql/deserializedTypes/Config'
import {addConfigQuery} from 'graphql/generated'
import {isDataLoading} from 'utils/dataLoading'
import {BenefitType} from 'utils/benefits'
import {
  Config_config_financialOffices,
  Config_config_inboundReasons,
} from 'graphql/types/Config'

const [addConfigQueryContextProvider, addConfigContext] = getContextHelpers<{
  config: Config_config
}>(toObjectKeys(['config']))

export {addConfigContext}

export const addConfigContextProvider: SimpleUnchangedProps = flowMax(
  addConfigQuery({}),
  branchIfPassesPredicate('config', isDataLoading),
  addProps(({config: {data}}) => ({
    config: data,
  })),
  addConfigQueryContextProvider
)

type AddCCTypes = SimplePropsAdder<{
  charityCareTypes: Config_config_charityCareTypes[]
}>

export const addCharityCareTypes: AddCCTypes = flowMax(
  addProps((props) => ({
    _incomingProps: props,
  })),
  addConfigContext,
  addProps(({config: {charityCareTypes}}) => ({
    charityCareTypes,
  })),
  addProps(({_incomingProps}) =>
    'config' in _incomingProps ? {config: (_incomingProps as any).config} : {}
  )
)

type AddFacilities = SimplePropsAdder<{
  facilities: Config_config_facilities[]
}>

export const addFacilities: AddFacilities = flowMax(
  addProps((props) => ({
    _incomingProps: props,
  })),
  addConfigContext,
  addProps(({config: {facilities}}) => ({
    facilities,
  })),
  addProps(({_incomingProps}) =>
    'config' in _incomingProps ? {config: (_incomingProps as any).config} : {}
  )
)

type AddDepartments = SimplePropsAdder<{
  departments: Config_config_departments[]
}>

export const addDepartments: AddDepartments = flowMax(
  addProps((props) => ({
    _incomingProps: props,
  })),
  addConfigContext,
  addProps(({config: {departments}}) => ({
    departments,
  })),
  addProps(({_incomingProps}) =>
    'config' in _incomingProps ? {config: (_incomingProps as any).config} : {}
  )
)

type AddFinancialOffices = SimplePropsAdder<{
  financialOffices: Config_config_financialOffices[]
}>

export const addFinancialOffices: AddFinancialOffices = flowMax(
  addProps((props) => ({
    _incomingProps: props,
  })),
  addConfigContext,
  addProps(({config: {financialOffices}}) => ({
    financialOffices,
  })),
  addProps(({_incomingProps}) =>
    'config' in _incomingProps ? {config: (_incomingProps as any).config} : {}
  )
)

type AddInboundReasons = SimplePropsAdder<{
  inboundReasons: Config_config_inboundReasons[]
}>

export const addInboundReasons: AddInboundReasons = flowMax(
  addProps((props) => ({
    _incomingProps: props,
  })),
  addConfigContext,
  addProps(({config: {inboundReasons}}) => ({
    inboundReasons,
  })),
  addProps(({_incomingProps}) =>
    'config' in _incomingProps ? {config: (_incomingProps as any).config} : {}
  )
)

type AddBenefits = SimplePropsAdder<{
  benefits: Config_config_applicationBenefits[]
}>

export const addBenefits: AddBenefits = flowMax(
  addProps((props) => ({
    _incomingProps: props,
  })),
  addConfigContext,
  addProps(({config: {applicationBenefits: benefits}}) => ({
    benefits,
  })),
  addProps(({_incomingProps}) =>
    'config' in _incomingProps ? {config: (_incomingProps as any).config} : {}
  )
)

type AddGetIsConfiguredBenefit = SimplePropsAdder<{
  getIsConfiguredBenefit: (benefit: BenefitType) => boolean
}>

export const addGetIsConfiguredBenefit: AddGetIsConfiguredBenefit = flowMax(
  addProps((props) => ({
    _incomingProps: props,
  })),
  addConfigContext,
  addHandlers(
    {
      getIsConfiguredBenefit: ({config: {applicationBenefits: benefits}}) => (
        benefit: BenefitType
      ) =>
        benefits.some(
          (applicationBenefit) => applicationBenefit.benefit === benefit
        ),
    },
    ['config']
  ),
  addProps(({_incomingProps}) =>
    'config' in _incomingProps ? {config: (_incomingProps as any).config} : {}
  )
)

type AddShowTriage = SimplePropsAdder<{
  showTriage: boolean
}>

export const addShowTriage: AddShowTriage = flowMax(
  addProps((props) => ({
    _incomingProps: props,
  })),
  addConfigContext,
  addProps(
    ({config: {showTriage}}) => ({
      showTriage,
    }),
    ['config']
  ),
  addProps(({_incomingProps}) =>
    'config' in _incomingProps ? {config: (_incomingProps as any).config} : {}
  )
)

type AddShowDocumentFiles = SimplePropsAdder<{
  showDocumentFiles: boolean
}>

export const addShowDocumentFiles: AddShowDocumentFiles = flowMax(
  addProps((props) => ({
    _incomingProps: props,
  })),
  addConfigContext,
  addProps(
    ({config: {showDocumentFiles}}) => ({
      showDocumentFiles,
    }),
    ['config']
  ),
  addProps(({_incomingProps}) =>
    'config' in _incomingProps ? {config: (_incomingProps as any).config} : {}
  )
)

type AddShowEsignReview = SimplePropsAdder<{
  showEsignReview: boolean
}>

export const addShowEsignReview: AddShowEsignReview = flowMax(
  addProps((props) => ({
    _incomingProps: props,
  })),
  addConfigContext,
  addProps(
    ({config: {showEsignReview}}) => ({
      showEsignReview,
    }),
    ['config']
  ),
  addProps(({_incomingProps}) =>
    'config' in _incomingProps ? {config: (_incomingProps as any).config} : {}
  )
)

type AddShowAccounts = SimplePropsAdder<{
  showAccounts: boolean
}>

export const addShowAccounts: AddShowAccounts = flowMax(
  addProps((props) => ({
    _incomingProps: props,
  })),
  addConfigContext,
  addProps(
    ({config: {showAccounts}}) => ({
      showAccounts,
    }),
    ['config']
  ),
  addProps(({_incomingProps}) =>
    'config' in _incomingProps ? {config: (_incomingProps as any).config} : {}
  )
)
