import {PureQueryOptions} from 'apollo-boost'
import {getContextHelpers, toObjectKeys} from 'ad-hok-utils'

import {DocumentFileFields} from 'graphql/deserializedTypes/DocumentFileFields'

export type DocumentFileType = DocumentFileFields & {
  documents: {
    id: string
  }[]
}

const [
  addDocumentFilesContextProvider,
  addDocumentFilesContext,
] = getContextHelpers<{
  documentFiles: DocumentFileType[]
  isFileEditingFrozen: boolean
  refetchQueries: PureQueryOptions[]
  additionalCreateDocumentFileMutateVariables:
    | {
        applicationId: string
      }
    | {
        personId: string
      }
  fileKeyPrefix: string
  documentFileDialogTitle: string
  shouldHideCreatedAtColumn?: boolean
}>(
  toObjectKeys([
    'documentFiles',
    'isFileEditingFrozen',
    'refetchQueries',
    'additionalCreateDocumentFileMutateVariables',
    'fileKeyPrefix',
    'documentFileDialogTitle',
    'shouldHideCreatedAtColumn',
  ])
)

export {addDocumentFilesContextProvider, addDocumentFilesContext}
