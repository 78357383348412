/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {EditableFileInput, EditableFileStatus} from './globalTypes' // ====================================================
// GraphQL mutation operation: CreateEditableFile
// ====================================================

import {deserializeEditableFileInput} from './globalTypes'
import {serializeEditableFileInput} from './globalTypes'
import {CreateEditableFileVariables as CreateEditableFileVariablesOrig} from '../types/CreateEditableFile'
import {CreateEditableFile as CreateEditableFileOrig} from '../types/CreateEditableFile'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {CreateEditableFile_createEditableFile as CreateEditableFile_createEditableFileOrig} from '../types/CreateEditableFile'
export interface CreateEditableFile_createEditableFile {
  __typename: 'EditableFile'
  id: string
  fileKey: string
  filename: string
  updatedAt: Date
  status: EditableFileStatus
  allowSigning: boolean | null
  documentSendType: string | null
}
export const deserializeCreateEditableFile_createEditableFile = (
  serialized: CreateEditableFile_createEditableFileOrig
): CreateEditableFile_createEditableFile => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
})
export const serializeCreateEditableFile_createEditableFile = (
  deserialized: CreateEditableFile_createEditableFile
): CreateEditableFile_createEditableFileOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
})
export interface CreateEditableFile {
  /**
   * Create editable file
   */
  createEditableFile: CreateEditableFile_createEditableFile
}
export const deserializeCreateEditableFile = (
  serialized: CreateEditableFileOrig
): CreateEditableFile => ({
  ...serialized,
  createEditableFile: deserializeCreateEditableFile_createEditableFile(
    serialized.createEditableFile
  ),
})
export const serializeCreateEditableFile = (
  deserialized: CreateEditableFile
): CreateEditableFileOrig => ({
  ...deserialized,
  createEditableFile: serializeCreateEditableFile_createEditableFile(
    deserialized.createEditableFile
  ),
})
export interface CreateEditableFileVariables {
  editableFile: EditableFileInput
  sendForSigning?: boolean | null
  sendForRemoteSigningSessionId?: string | null
  setUnreviewed?: boolean | null
  applicationId?: string | null
  sendDocumentType?: string | null
  isDocumentSendOnly?: boolean | null
  isDocumentSendAndSave?: boolean | null
  isEsignDocument?: boolean | null
}
export const deserializeCreateEditableFileVariables = (
  serialized: CreateEditableFileVariablesOrig
): CreateEditableFileVariables => ({
  ...serialized,
  editableFile: deserializeEditableFileInput(serialized.editableFile),
})
export const serializeCreateEditableFileVariables = (
  deserialized: CreateEditableFileVariables
): CreateEditableFileVariablesOrig => ({
  ...deserialized,
  editableFile: serializeEditableFileInput(deserialized.editableFile),
})
