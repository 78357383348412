/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {UpdateEditableFileInput, EditableFileStatus} from './globalTypes' // ====================================================
// GraphQL mutation operation: UpdateEditableFile
// ====================================================

import {deserializeUpdateEditableFileInput} from './globalTypes'
import {serializeUpdateEditableFileInput} from './globalTypes'
import {UpdateEditableFileVariables as UpdateEditableFileVariablesOrig} from '../types/UpdateEditableFile'
import {UpdateEditableFile as UpdateEditableFileOrig} from '../types/UpdateEditableFile'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {UpdateEditableFile_updateEditableFile as UpdateEditableFile_updateEditableFileOrig} from '../types/UpdateEditableFile'
export interface UpdateEditableFile_updateEditableFile {
  __typename: 'EditableFile'
  id: string
  fileKey: string
  filename: string
  updatedAt: Date
  status: EditableFileStatus
  allowSigning: boolean | null
  documentSendType: string | null
}
export const deserializeUpdateEditableFile_updateEditableFile = (
  serialized: UpdateEditableFile_updateEditableFileOrig
): UpdateEditableFile_updateEditableFile => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
})
export const serializeUpdateEditableFile_updateEditableFile = (
  deserialized: UpdateEditableFile_updateEditableFile
): UpdateEditableFile_updateEditableFileOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
})
export interface UpdateEditableFile {
  /**
   * Update editable file
   */
  updateEditableFile: UpdateEditableFile_updateEditableFile
}
export const deserializeUpdateEditableFile = (
  serialized: UpdateEditableFileOrig
): UpdateEditableFile => ({
  ...serialized,
  updateEditableFile: deserializeUpdateEditableFile_updateEditableFile(
    serialized.updateEditableFile
  ),
})
export const serializeUpdateEditableFile = (
  deserialized: UpdateEditableFile
): UpdateEditableFileOrig => ({
  ...deserialized,
  updateEditableFile: serializeUpdateEditableFile_updateEditableFile(
    deserialized.updateEditableFile
  ),
})
export interface UpdateEditableFileVariables {
  editableFile: UpdateEditableFileInput
  sendForSigning?: boolean | null
  sendForRemoteSigningSessionId?: string | null
  markUnreviewed?: boolean | null
  applicationId?: string | null
  sendDocumentType?: string | null
  isDocumentSendOnly?: boolean | null
  isDocumentSendAndSave?: boolean | null
  isEsignDocument?: boolean | null
}
export const deserializeUpdateEditableFileVariables = (
  serialized: UpdateEditableFileVariablesOrig
): UpdateEditableFileVariables => ({
  ...serialized,
  editableFile: deserializeUpdateEditableFileInput(serialized.editableFile),
})
export const serializeUpdateEditableFileVariables = (
  deserialized: UpdateEditableFileVariables
): UpdateEditableFileVariablesOrig => ({
  ...deserialized,
  editableFile: serializeUpdateEditableFileInput(deserialized.editableFile),
})
