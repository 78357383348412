import {Benefit} from 'graphql/deserializedTypes/globalTypes'

export {Benefit as BenefitType}

export const getIsFacilitySpecificBenefitType = (benefit: Benefit) =>
  [Benefit.charityCare, Benefit.slide].includes(benefit)

export const getIsInitialDateOfServiceRequiredWhenCreatingBenefitType = (
  benefit: Benefit
) => [Benefit.charityCare, Benefit.medicaid].includes(benefit)
