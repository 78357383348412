import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  branch,
  returns,
  addProps,
  addWrapper,
  addStateHandlers,
  addHandlers,
  addMemoBoundary,
  addEffect,
} from 'ad-hok'
import {Theme} from '@material-ui/core'
import {TFunction} from 'i18next'
import {addInterval, addPropIdentityStabilization} from 'ad-hok-utils'
import {uniq} from 'lodash'
import {flow, map, reduce} from 'lodash/fp'

import {addClasses, makeClasses} from 'theme'
import Body1 from './Body1'
import {addTranslationHelpers} from 'utils/i18n'
import {addLoadingIndicator} from 'utils/dataLoading'
import {addApplicationsQuery, addUsersQuery} from 'graphql/generated'
import ApplicationItem from 'components/ApplicationItem'
import addFilterControls, {
  makeFilters,
  FilterValues,
} from 'utils/addFilterControls'
import {getIsFacilitySpecificBenefitType} from 'utils/benefits'
import {APPLICATION_STATUSES} from 'utils/applicationStatuses'
import {getBenefitName} from 'utils/application'
import {
  WORKLIST_POLL_INTERVAL,
  addResultsLimitedMessage,
} from 'components/TriageWorklist'
import ApplicationSearch, {
  ApplicationSearchStatus,
} from 'components/ApplicationSearch'
import FilterChips from 'components/FilterChips'
import {APPLICATIONS_QUERY} from 'graphql/queries'
// import {FacilityFields} from 'graphql/deserializedTypes/FacilityFields'
import {CharityCareTypeFields} from 'graphql/deserializedTypes/CharityCareTypeFields'
import {
  addFacilities,
  addBenefits,
  addShowEsignReview,
  addCharityCareTypes,
} from 'utils/configContext'
import {getStringValuesFromEnumType} from 'utils/form/fieldTypes'
import {McdType} from 'graphql/deserializedTypes/globalTypes'
import {callWith} from 'utils/fp'
import typedAs from 'utils/typedAs'
import {ApplicationBenefitFields} from 'graphql/deserializedTypes/ApplicationBenefitFields'
import {UserFields} from 'graphql/deserializedTypes/UserFields'
import {usersToSelectOptions} from 'components/TaskDialog'
import addRouteParams from 'utils/addRouteParams'

const classes = makeClasses((theme: Theme) => ({
  noResults: {
    marginTop: theme.spacing(2),
  },
  filterContainer: {
    alignItems: 'flex-end',
  },
  filterField: {
    marginBottom: theme.spacing(2),
    maxWidth: 320,
  },
}))

const ALL_AGES = ['under 65', '65+']

export const UNREVIEWED_EDITABLE_FILES_FILTER_UNREVEVIEWED_REMOTE_REQUEST =
  'Unreviewed remote request files'

const getApplicationFilters = ({
  //  facilities,
  benefits,
  users,
  showEsignReview,
  charityCareTypes,
  t,
}: {
  //  facilities: FacilityFields[]
  benefits: ApplicationBenefitFields[]
  users: UserFields[]
  showEsignReview: boolean
  charityCareTypes: CharityCareTypeFields[]
  t: TFunction
}) =>
  makeFilters({
    statuses: {
      type: 'multiselect',
      label: t('worklist.applicationFilters.status'),
      options: APPLICATION_STATUSES.map(({status, closedOrVoid}) => ({
        value: status,
        label: closedOrVoid
          ? t('worklist.applicationFilters.statusLimitLast30DaysLabel', {
              status,
            })
          : status,
        isClosed: closedOrVoid,
      })),
    },
    benefits: {
      type: 'multiselect',
      label: t('worklist.applicationFilters.benefit'),
      options: benefits.flatMap(({benefit}) =>
        getIsFacilitySpecificBenefitType(benefit)
          ? charityCareTypes.map(({name}) => ({
              value: JSON.stringify({
                benefit,
                charityCareType: name,
              }),
              label: t('worklist.applicationFilters.benefitWithSubtype', {
                benefit: getBenefitName({benefit, t}),
                subtype: name,
              }),
            }))
          : benefit === 'medicaid'
          ? getStringValuesFromEnumType(McdType).flatMap((mcdType) =>
              mcdType === 'AEMA'
                ? ALL_AGES.map((age) => ({
                    value: JSON.stringify({
                      benefit,
                      mcdType,
                      age,
                    }),
                    label: t('worklist.applicationFilters.benefitWithAge', {
                      benefit: getBenefitName({
                        benefit,
                        t,
                      }),
                      subtype: mcdType,
                      age,
                    }),
                  }))
                : [
                    {
                      value: JSON.stringify({
                        benefit,
                        mcdType,
                      }),
                      label: t(
                        'worklist.applicationFilters.benefitWithSubtype',
                        {
                          benefit: getBenefitName({benefit, t}),
                          subtype: mcdType,
                        }
                      ),
                    },
                  ]
            )
          : [
              {
                value: JSON.stringify({benefit}),
                label: getBenefitName({benefit, t}),
              },
            ]
      ),
    },
    initialDateOfService: {
      type: 'multiselect',
      label: t('worklist.applicationFilters.initialDateOfService'),
      options: [
        'None',
        'Past 5 days',
        '6 - 30 days ago',
        '31 - 60 days ago',
        '> 60 days ago',
      ].map((value) => ({
        value,
        label: value,
      })),
    },
    ...(showEsignReview
      ? {
          esignNeedsReview: {
            type: 'multiselect',
            label: t('worklist.applicationFilters.esignNeedsReview'),
            options: ['Any session', 'Completed session', 'None'].map(
              (value) => ({
                value,
                label: value,
              })
            ),
          },
          completedEsignSessions: {
            type: 'multiselect',
            label: t('worklist.applicationFilters.completedEsignSessions'),
            options: ['None', 'At least one', 'All'].map((value) => ({
              value,
              label: value,
            })),
          },
        }
      : {}),
    createdByIds: {
      type: 'multiselect',
      label: t('worklist.applicationFilters.createdByIds'),
      options: usersToSelectOptions(users),
    },
    assignedToIds: {
      type: 'multiselect',
      label: t('worklist.taskFilters.assignedToIds'),
      options: [
        {
          label: t('form.none'),
          value: 'None',
        },
        ...usersToSelectOptions(users),
      ],
    },
    unreviewedEditableFiles: {
      type: 'multiselect',
      label: t('worklist.applicationFilters.unreviewedEditableFiles'),
      options: [
        'No unreviewed files',
        UNREVIEWED_EDITABLE_FILES_FILTER_UNREVEVIEWED_REMOTE_REQUEST,
        'Other unreviewed files',
      ].map((value) => ({
        value,
        label: value,
      })),
    },
    hasUnreadTextMessages: {
      type: 'multiselect',
      label: t('worklist.applicationFilters.hasUnreadTextMessages'),
      options: [
        {
          label: t('form.yes'),
          value: 'Yes',
        },
        {
          label: t('form.no'),
          value: 'No',
        },
      ],
    },
    pastDateOfService: {
      type: 'multiselect',
      label: t('worklist.applicationFilters.pastDateOfService'),
      options: [
        'No DOS',
        'Past 5 days',
        '6 - 30 days ago',
        '31 - 90 days ago',
        '> 90 days ago',
      ].map((value) => ({
        value,
        label: value,
      })),
    },
  })

type FiltersType = ReturnType<typeof getApplicationFilters>

export const WorklistNoResults: FC = flowMax(
  addDisplayName('WorklistNoResults'),
  addTranslationHelpers,
  addClasses(classes),
  ({t, classes}) => (
    <Body1 className={classes.noResults}>{t('worklist.noResults')}</Body1>
  )
)

const possiblyAppendString = (
  array: string[],
  value: string | undefined
): string[] => uniq([...array, ...(value ? [value] : [])])

const getFilterValueVariables = ({
  benefits,
  ...filterValues
}: FilterValues<FiltersType>): FilterValues<FiltersType> & {
  charityCareTypes: string[]
  mcdType: string[]
  age: string[]
} => ({
  ...filterValues,
  ...callWith(
    benefits,
    flow(
      map(
        (benefit) =>
          JSON.parse(benefit) as {
            benefit: string
            charityCareType?: string
            mcdType?: string
            age?: string
          }
      ),
      reduce(
        (filters, {benefit, charityCareType, mcdType, age}) => ({
          benefits: possiblyAppendString(filters.benefits, benefit),
          charityCareTypes: possiblyAppendString(
            filters.charityCareTypes,
            charityCareType
          ),
          mcdType: possiblyAppendString(filters.mcdType, mcdType),
          age: possiblyAppendString(filters.age, age),
        }),
        {
          benefits: typedAs<string[]>([]),
          charityCareTypes: typedAs<string[]>([]),
          mcdType: typedAs<string[]>([]),
          age: typedAs<string[]>([]),
        }
      )
    )
  ),
})

const ApplicationWorklist: FC = flowMax(
  addDisplayName('ApplicationWorklist'),
  addTranslationHelpers,
  addClasses(classes),
  addStateHandlers(
    {
      filtersDisabled: false,
      search: '',
    },
    {
      disableFilters: () => () => ({filtersDisabled: true}),
      enableFilters: () => () => ({filtersDisabled: false}),
      setSearch: () => (search: string) => ({search}),
      clearSearch: () => () => ({search: ''}),
    }
  ),
  addHandlers({
    onSearchStatus: ({disableFilters, enableFilters, clearSearch}) => (
      status: ApplicationSearchStatus
    ) => {
      if (status === 'active') {
        disableFilters()
      }
      if (status === 'inactive') {
        enableFilters()
        clearSearch()
      }
    },
  }),
  addFacilities,
  addBenefits,
  addCharityCareTypes,
  addUsersQuery({
    variables: () => ({
      includeBlockedUsersWithAssignedApplications: true,
    }),
  }),
  addLoadingIndicator({}),
  addShowEsignReview,
  addRouteParams<{
    assignedToFilter: string | undefined
    unreviewedEditableFileFilter: string | undefined
    unreadMessages: string | undefined
  }>(),
  addProps(
    ({benefits, users, showEsignReview, charityCareTypes, t}) => ({
      filters: getApplicationFilters({
        benefits,
        users,
        showEsignReview,
        charityCareTypes,
        t,
      }),
      initialFilterValues: {
        statuses: [],
        benefits: [],
        initialDateOfService: [],
        ...(showEsignReview
          ? {
              esignNeedsReview: [],
              completedEsignSessions: [],
            }
          : {}),
        createdByIds: [],
        assignedToIds: [],
        unreviewedEditableFiles: [],
        hasUnreadTextMessages: [],
        pastDateOfService: [],
      },
    }),
    [
      // 'facilities',
      'benefits',
      'users',
      'showEsignReview',
      'charityCareTypes',
      't',
    ]
  ),
  addFilterControls<FiltersType>({
    renderExtraControls: ({onSearchStatus, setSearch}) => (
      <ApplicationSearch onSearchStatus={onSearchStatus} onSearch={setSearch} />
    ),
    className: ({classes}) => classes.filterContainer,
    filterFieldClassName: ({classes}) => classes.filterField,
    disableFilterControls: ({filtersDisabled}) => filtersDisabled,
    persistenceKey: 'applications',
    showClearButton: true,
    forceFilterValues: ({
      assignedToFilter,
      unreviewedEditableFileFilter,
      unreadMessages,
    }) => ({
      ...(assignedToFilter ? {assignedToIds: [assignedToFilter]} : undefined),
      ...(unreviewedEditableFileFilter
        ? {unreviewedEditableFiles: [unreviewedEditableFileFilter]}
        : undefined),
      ...(unreadMessages
        ? {hasUnreadTextMessages: [unreadMessages]}
        : undefined),
    }),
  }),
  addEffect(
    ({
      assignedToFilter,
      unreviewedEditableFileFilter,
      unreadMessages,
      applyForcedFilters,
    }) => () => {
      if (assignedToFilter || unreviewedEditableFileFilter || unreadMessages) {
        applyForcedFilters()
        window.history.replaceState(null, '', window.location.pathname)
      }
    }
  ),
  addWrapper((render, {filterValues: {statuses}, search, t}) => (
    <>
      {statuses.length > 0 && !search && (
        <FilterChips
          labels={statuses}
          label={t('worklist.applicationFilters.activeStatusFilters')}
        />
      )}
      {render()}
    </>
  )),
  addProps(({filterValues, search}) => ({
    variables: search
      ? {
          statuses: [],
          benefits: [],
          search,
        }
      : {...getFilterValueVariables(filterValues), search: null},
  })),
  addApplicationsQuery({
    variables: ({variables}) => variables,
  }),
  addInterval(
    ({variables, refetchApplications}) => () => {
      refetchApplications(variables)
    },
    WORKLIST_POLL_INTERVAL,
    ['filterValues']
  ),
  addLoadingIndicator({}),
  branch(
    ({applications}) => !applications.length,
    returns(() => <WorklistNoResults />)
  ),
  addPropIdentityStabilization('applications'),
  addMemoBoundary(['applications']),
  addResultsLimitedMessage('applications'),
  ({applications, variables}) => (
    <>
      {applications.map((application) => (
        <ApplicationItem
          application={application}
          refetchQueriesOnStatusUpdate={[
            {
              query: APPLICATIONS_QUERY,
              variables,
            },
          ]}
          key={application.id}
        />
      ))}
    </>
  )
)

export default ApplicationWorklist
