/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {
  UpdatePersonInput,
  NjmmisCheckResult,
  Benefit,
  McdType,
} from './globalTypes' // ====================================================
// GraphQL mutation operation: UpdatePerson
// ====================================================

import {deserializeUpdatePersonInput} from './globalTypes'
import {serializeUpdatePersonInput} from './globalTypes'
import {UpdatePersonVariables as UpdatePersonVariablesOrig} from '../types/UpdatePerson'
import {UpdatePerson as UpdatePersonOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson as UpdatePerson_updatePersonOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_mostRecentEligibilityDeterminations as UpdatePerson_updatePerson_mostRecentEligibilityDeterminationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_mostRecentAccount as UpdatePerson_updatePerson_mostRecentAccountOrig} from '../types/UpdatePerson'
import {WebformMergeResult} from 'utils/form/getWebformMergeResult'
import {parseWebformMergeResult} from 'utils/form/getWebformMergeResult'
import {serializeWebformMergeResult} from 'utils/form/getWebformMergeResult'
import {UpdatePerson_updatePerson_webformMergeAttempts as UpdatePerson_updatePerson_webformMergeAttemptsOrig} from '../types/UpdatePerson'
import {ReceivedWebformResponse} from 'utils/form/getReceivedWebformResponse'
import {parseReceivedWebformResponse} from 'utils/form/getReceivedWebformResponse'
import {serializeReceivedWebformResponse} from 'utils/form/getReceivedWebformResponse'
import {UpdatePerson_updatePerson_webformMergeAttempts_receivedWebform as UpdatePerson_updatePerson_webformMergeAttempts_receivedWebformOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_webformMergeAttempts_receivedWebform_webform as UpdatePerson_updatePerson_webformMergeAttempts_receivedWebform_webformOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_tasks as UpdatePerson_updatePerson_tasksOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_tasks_linkedTo as UpdatePerson_updatePerson_tasks_linkedToOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_tasks_createdBy as UpdatePerson_updatePerson_tasks_createdByOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_tasks_assignedTo as UpdatePerson_updatePerson_tasks_assignedToOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_accounts as UpdatePerson_updatePerson_accountsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_documentFiles as UpdatePerson_updatePerson_documentFilesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_documentFiles_documents as UpdatePerson_updatePerson_documentFiles_documentsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_benefitOutcomes as UpdatePerson_updatePerson_benefitOutcomesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_benefitOutcomes_application as UpdatePerson_updatePerson_benefitOutcomes_applicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_benefitOutcomes_application_SlideApplication as UpdatePerson_updatePerson_benefitOutcomes_application_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_benefitOutcomes_application_CharityCareApplication as UpdatePerson_updatePerson_benefitOutcomes_application_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplication as UpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplication_primaryPointOfContact as UpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplication_primaryPointOfContactOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_benefitOutcomes_application_MedicaidApplication as UpdatePerson_updatePerson_benefitOutcomes_application_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications as UpdatePerson_updatePerson_openPrimaryPointOfContactApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openHouseholdMemberApplications as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openHouseholdMemberApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_SlideApplication as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_CharityCareApplication as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_MedicaidApplication as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openHouseholdMemberApplications as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openHouseholdMemberApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_SlideApplication as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_CharityCareApplication as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_MedicaidApplication as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openHouseholdMemberApplications as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openHouseholdMemberApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_SlideApplication as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_CharityCareApplication as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_MedicaidApplication as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_primaryPointOfContact as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_primaryPointOfContactOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openHouseholdMemberApplications as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openHouseholdMemberApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_SlideApplication as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_CharityCareApplication as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_MedicaidApplication as UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications as UpdatePerson_updatePerson_allOpenHouseholdMemberApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documentsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_application as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_applicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_person as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_files as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_filesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembers as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembers_person as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembers_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationshipsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPersonOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_assets as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_assetsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_deductions as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_deductionsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_incomeSources as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_incomeSourcesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_phoneNumbers as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_phoneNumbersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_assets as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_assetsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_deductions as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_deductionsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_incomeSources as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_incomeSourcesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_phoneNumbers as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_phoneNumbersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplications as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplication as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplication as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplication as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documentsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_application as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_applicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_person as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_files as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_filesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembers as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembers_person as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembers_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationshipsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPersonOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_assets as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_assetsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_deductions as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_deductionsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_incomeSources as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_incomeSourcesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_phoneNumbers as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_phoneNumbersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_assets as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_assetsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_deductions as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_deductionsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_incomeSources as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_incomeSourcesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_phoneNumbers as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_phoneNumbersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplications as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplication as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplication as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplication as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documentsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_application as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_applicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_person as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_files as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_filesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembers as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembers_person as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembers_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationshipsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPersonOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_assets as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_assetsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_deductions as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_deductionsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_incomeSources as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_incomeSourcesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_phoneNumbers as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_phoneNumbersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_assets as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_assetsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_deductions as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_deductionsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_incomeSources as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_incomeSourcesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_phoneNumbers as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_phoneNumbersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplications as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplication as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplication as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplication as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_primaryPointOfContact as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_primaryPointOfContactOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documentsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_application as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_applicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_person as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_files as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_filesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembers as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembers_person as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembers_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationshipsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPersonOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_assets as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_assetsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_deductions as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_deductionsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_incomeSources as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_incomeSourcesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_phoneNumbers as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_phoneNumbersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_assets as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_assetsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_deductions as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_deductionsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_incomeSources as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_incomeSourcesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_phoneNumbers as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_phoneNumbersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplications as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplication as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplication as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplication as UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications as UpdatePerson_updatePerson_openHouseholdMemberApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication as UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person as UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplications as UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications as UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplication as UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplication as UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplication as UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication as UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person as UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplications as UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications as UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplication as UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplication as UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplication as UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication as UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person as UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplications as UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications as UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplication as UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplication as UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplication as UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_primaryPointOfContact as UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_primaryPointOfContactOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication as UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person as UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplications as UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications as UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplication as UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplication as UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplication as UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications as UpdatePerson_updatePerson_openApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_SlideApplication as UpdatePerson_updatePerson_openApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_SlideApplication_person as UpdatePerson_updatePerson_openApplications_SlideApplication_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_SlideApplication_person_openHouseholdMemberApplications as UpdatePerson_updatePerson_openApplications_SlideApplication_person_openHouseholdMemberApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications as UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_SlideApplication as UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_CharityCareApplication as UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_MedicaidApplication as UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_SlideApplication_documents as UpdatePerson_updatePerson_openApplications_SlideApplication_documentsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_SlideApplication_documents_application as UpdatePerson_updatePerson_openApplications_SlideApplication_documents_applicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_SlideApplication_documents_person as UpdatePerson_updatePerson_openApplications_SlideApplication_documents_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_SlideApplication_documents_files as UpdatePerson_updatePerson_openApplications_SlideApplication_documents_filesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_SlideApplication_householdMembers as UpdatePerson_updatePerson_openApplications_SlideApplication_householdMembersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_SlideApplication_householdMembers_person as UpdatePerson_updatePerson_openApplications_SlideApplication_householdMembers_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_SlideApplication_eligibilityDeterminations as UpdatePerson_updatePerson_openApplications_SlideApplication_eligibilityDeterminationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_CharityCareApplication as UpdatePerson_updatePerson_openApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_CharityCareApplication_person as UpdatePerson_updatePerson_openApplications_CharityCareApplication_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openHouseholdMemberApplications as UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openHouseholdMemberApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications as UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_SlideApplication as UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_CharityCareApplication as UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_MedicaidApplication as UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents as UpdatePerson_updatePerson_openApplications_CharityCareApplication_documentsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_application as UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_applicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_person as UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_files as UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_filesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembers as UpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembers_person as UpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembers_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_CharityCareApplication_eligibilityDeterminations as UpdatePerson_updatePerson_openApplications_CharityCareApplication_eligibilityDeterminationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MonitorApplication as UpdatePerson_updatePerson_openApplications_MonitorApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MonitorApplication_person as UpdatePerson_updatePerson_openApplications_MonitorApplication_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openHouseholdMemberApplications as UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openHouseholdMemberApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications as UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_SlideApplication as UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_CharityCareApplication as UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_MedicaidApplication as UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MonitorApplication_documents as UpdatePerson_updatePerson_openApplications_MonitorApplication_documentsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_application as UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_applicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_person as UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_files as UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_filesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembers as UpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembers_person as UpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembers_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MonitorApplication_eligibilityDeterminations as UpdatePerson_updatePerson_openApplications_MonitorApplication_eligibilityDeterminationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MonitorApplication_primaryPointOfContact as UpdatePerson_updatePerson_openApplications_MonitorApplication_primaryPointOfContactOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MedicaidApplication as UpdatePerson_updatePerson_openApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MedicaidApplication_person as UpdatePerson_updatePerson_openApplications_MedicaidApplication_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openHouseholdMemberApplications as UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openHouseholdMemberApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications as UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_SlideApplication as UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_CharityCareApplication as UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_MedicaidApplication as UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents as UpdatePerson_updatePerson_openApplications_MedicaidApplication_documentsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_application as UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_applicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_person as UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_files as UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_filesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembers as UpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembers_person as UpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembers_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openApplications_MedicaidApplication_eligibilityDeterminations as UpdatePerson_updatePerson_openApplications_MedicaidApplication_eligibilityDeterminationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications as UpdatePerson_updatePerson_allApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_SlideApplication as UpdatePerson_updatePerson_allApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_SlideApplication_householdMembers as UpdatePerson_updatePerson_allApplications_SlideApplication_householdMembersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_SlideApplication_householdMembers_person as UpdatePerson_updatePerson_allApplications_SlideApplication_householdMembers_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_SlideApplication_assignedTo as UpdatePerson_updatePerson_allApplications_SlideApplication_assignedToOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_SlideApplication_person as UpdatePerson_updatePerson_allApplications_SlideApplication_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_SlideApplication_person_openHouseholdMemberApplications as UpdatePerson_updatePerson_allApplications_SlideApplication_person_openHouseholdMemberApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications as UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_SlideApplication as UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_CharityCareApplication as UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_MedicaidApplication as UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_SlideApplication_person_deductions as UpdatePerson_updatePerson_allApplications_SlideApplication_person_deductionsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources as UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSourcesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSession as UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSessionOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSession_esignWizards as UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSession_esignWizardsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomes as UpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomes_application as UpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomes_applicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers as UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSession as UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSessionOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSession_esignWizards as UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_CharityCareApplication as UpdatePerson_updatePerson_allApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembers as UpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembers_person as UpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembers_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_CharityCareApplication_assignedTo as UpdatePerson_updatePerson_allApplications_CharityCareApplication_assignedToOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_CharityCareApplication_person as UpdatePerson_updatePerson_allApplications_CharityCareApplication_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openHouseholdMemberApplications as UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openHouseholdMemberApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications as UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_SlideApplication as UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_CharityCareApplication as UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_MedicaidApplication as UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_deductions as UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_deductionsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources as UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSourcesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSession as UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSessionOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSession_esignWizards as UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSession_esignWizardsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomes as UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomes_application as UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomes_applicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers as UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSession as UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSessionOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards as UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MonitorApplication as UpdatePerson_updatePerson_allApplications_MonitorApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembers as UpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembers_person as UpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembers_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MonitorApplication_assignedTo as UpdatePerson_updatePerson_allApplications_MonitorApplication_assignedToOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MonitorApplication_person as UpdatePerson_updatePerson_allApplications_MonitorApplication_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openHouseholdMemberApplications as UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openHouseholdMemberApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications as UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_SlideApplication as UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_CharityCareApplication as UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_MedicaidApplication as UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MonitorApplication_person_deductions as UpdatePerson_updatePerson_allApplications_MonitorApplication_person_deductionsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources as UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSourcesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSession as UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSessionOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSession_esignWizards as UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSession_esignWizardsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomes as UpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomes_application as UpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomes_applicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers as UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSession as UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSessionOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSession_esignWizards as UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MonitorApplication_primaryPointOfContact as UpdatePerson_updatePerson_allApplications_MonitorApplication_primaryPointOfContactOrig} from '../types/UpdatePerson'
import {deserializeMcdType} from 'graphql/converters'
import {serializeMcdType} from 'graphql/converters'
import {UpdatePerson_updatePerson_allApplications_MedicaidApplication as UpdatePerson_updatePerson_allApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembers as UpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembers_person as UpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembers_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MedicaidApplication_assignedTo as UpdatePerson_updatePerson_allApplications_MedicaidApplication_assignedToOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MedicaidApplication_person as UpdatePerson_updatePerson_allApplications_MedicaidApplication_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openHouseholdMemberApplications as UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openHouseholdMemberApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications as UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_SlideApplication as UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_SlideApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_CharityCareApplication as UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_CharityCareApplicationOrig} from '../types/UpdatePerson'
import {BenefitType} from 'utils/benefits'
import {deserializeBenefit} from 'graphql/converters'
import {serializeBenefit} from 'graphql/converters'
import {UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_MedicaidApplication as UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_MedicaidApplicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_deductions as UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_deductionsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources as UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSourcesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSession as UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSessionOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSession_esignWizards as UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSession_esignWizardsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomes as UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomes_application as UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomes_applicationOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers as UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSession as UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSessionOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards as UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizardsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_relationships as UpdatePerson_updatePerson_relationshipsOrig} from '../types/UpdatePerson'
import {DeserializedFieldsUpdatedAt} from 'utils/form/getFieldsUpdatedAt'
import {parseFieldsUpdatedAt} from 'utils/form/getFieldsUpdatedAt'
import {serializeFieldsUpdatedAt} from 'utils/form/getFieldsUpdatedAt'
import {UpdatePerson_updatePerson_relationships_otherPerson as UpdatePerson_updatePerson_relationships_otherPersonOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplications as UpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplications_person as UpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplications_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_relationships_otherPerson_openApplications as UpdatePerson_updatePerson_relationships_otherPerson_openApplicationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_relationships_otherPerson_openApplications_person as UpdatePerson_updatePerson_relationships_otherPerson_openApplications_personOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_relationships_otherPerson_assets as UpdatePerson_updatePerson_relationships_otherPerson_assetsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_relationships_otherPerson_deductions as UpdatePerson_updatePerson_relationships_otherPerson_deductionsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_relationships_otherPerson_incomeSources as UpdatePerson_updatePerson_relationships_otherPerson_incomeSourcesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_relationships_otherPerson_phoneNumbers as UpdatePerson_updatePerson_relationships_otherPerson_phoneNumbersOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openTriages as UpdatePerson_updatePerson_openTriagesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openTriages_eligibilityDeterminations as UpdatePerson_updatePerson_openTriages_eligibilityDeterminationsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_openTriages_account as UpdatePerson_updatePerson_openTriages_accountOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_triages as UpdatePerson_updatePerson_triagesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_triages_assignedTo as UpdatePerson_updatePerson_triages_assignedToOrig} from '../types/UpdatePerson'
import {AdditionalData} from 'utils/getAdditionalData'
import {parseAdditionalData} from 'utils/getAdditionalData'
import {serializeAdditionalData} from 'utils/getAdditionalData'
import {UpdatePerson_updatePerson_triages_eligibilityDeterminations as UpdatePerson_updatePerson_triages_eligibilityDeterminationsOrig} from '../types/UpdatePerson'
import {deserializeNjmmisCheckResult} from 'graphql/converters'
import {serializeNjmmisCheckResult} from 'graphql/converters'
import formatISO from 'date-fns/formatISO'
import {parseDate} from 'utils/form/fieldTypes'
import {formatISODate} from 'utils/date'
import {UpdatePerson_updatePerson_triages_account as UpdatePerson_updatePerson_triages_accountOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_assets as UpdatePerson_updatePerson_assetsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_deductions as UpdatePerson_updatePerson_deductionsOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_incomeSources as UpdatePerson_updatePerson_incomeSourcesOrig} from '../types/UpdatePerson'
import {UpdatePerson_updatePerson_phoneNumbers as UpdatePerson_updatePerson_phoneNumbersOrig} from '../types/UpdatePerson'
export interface UpdatePerson_updatePerson_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
}
export const deserializeUpdatePerson_updatePerson_phoneNumbers = (
  serialized: UpdatePerson_updatePerson_phoneNumbersOrig
): UpdatePerson_updatePerson_phoneNumbers => ({...serialized})
export const serializeUpdatePerson_updatePerson_phoneNumbers = (
  deserialized: UpdatePerson_updatePerson_phoneNumbers
): UpdatePerson_updatePerson_phoneNumbersOrig => ({...deserialized})
export interface UpdatePerson_updatePerson_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
}
export const deserializeUpdatePerson_updatePerson_incomeSources = (
  serialized: UpdatePerson_updatePerson_incomeSourcesOrig
): UpdatePerson_updatePerson_incomeSources => ({...serialized})
export const serializeUpdatePerson_updatePerson_incomeSources = (
  deserialized: UpdatePerson_updatePerson_incomeSources
): UpdatePerson_updatePerson_incomeSourcesOrig => ({...deserialized})
export interface UpdatePerson_updatePerson_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeUpdatePerson_updatePerson_deductions = (
  serialized: UpdatePerson_updatePerson_deductionsOrig
): UpdatePerson_updatePerson_deductions => ({...serialized})
export const serializeUpdatePerson_updatePerson_deductions = (
  deserialized: UpdatePerson_updatePerson_deductions
): UpdatePerson_updatePerson_deductionsOrig => ({...deserialized})
export interface UpdatePerson_updatePerson_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeUpdatePerson_updatePerson_assets = (
  serialized: UpdatePerson_updatePerson_assetsOrig
): UpdatePerson_updatePerson_assets => ({...serialized})
export const serializeUpdatePerson_updatePerson_assets = (
  deserialized: UpdatePerson_updatePerson_assets
): UpdatePerson_updatePerson_assetsOrig => ({...deserialized})
export interface UpdatePerson_updatePerson_triages_account {
  __typename: 'Account'
  id: string
  hospitalAccountId: string | null
  dateOfService: Date
  timeOfService: string | null
  serviceTimeZone: string
  facility: string
  department: string
  location: string | null
  insuranceType: string
  status: string
  createdAt: Date
  updatedAt: Date
  notes: string | null
  accountAmount: number | null
  njmmisCheck: string | null
  previousInsurance: string | null
  invoiceMonth: string | null
  invoiceYear: string | null
  dischargeDate: Date | null
  isScheduled: boolean
}
export const deserializeUpdatePerson_updatePerson_triages_account = (
  serialized: UpdatePerson_updatePerson_triages_accountOrig
): UpdatePerson_updatePerson_triages_account => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  njmmisCheck:
    serialized.njmmisCheck != null
      ? deserializeNjmmisCheckResult(serialized.njmmisCheck)
      : serialized.njmmisCheck,
  dischargeDate:
    serialized.dischargeDate != null
      ? parseDate(serialized.dischargeDate)
      : serialized.dischargeDate,
})
export const serializeUpdatePerson_updatePerson_triages_account = (
  deserialized: UpdatePerson_updatePerson_triages_account
): UpdatePerson_updatePerson_triages_accountOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  njmmisCheck:
    deserialized.njmmisCheck != null
      ? serializeNjmmisCheckResult(deserialized.njmmisCheck)
      : deserialized.njmmisCheck,
  dischargeDate:
    deserialized.dischargeDate != null
      ? formatISODate(deserialized.dischargeDate)
      : deserialized.dischargeDate,
})
export interface UpdatePerson_updatePerson_triages_eligibilityDeterminations {
  __typename: 'EligibilityDetermination'
  id: string
  benefit: string
  status: string
  reasonOrInfo: string | null
  secondaryInfo: string | null
  householdSizeDescription: string | null
  month: Date | null
  numMonths: number | null
  createdAt: Date
  additionalData: AdditionalData | null
}
export const deserializeUpdatePerson_updatePerson_triages_eligibilityDeterminations = (
  serialized: UpdatePerson_updatePerson_triages_eligibilityDeterminationsOrig
): UpdatePerson_updatePerson_triages_eligibilityDeterminations => ({
  ...serialized,
  month:
    serialized.month != null ? parseDate(serialized.month) : serialized.month,
  createdAt: parseDate(serialized.createdAt),
  additionalData:
    serialized.additionalData != null
      ? parseAdditionalData(serialized.additionalData)
      : serialized.additionalData,
})
export const serializeUpdatePerson_updatePerson_triages_eligibilityDeterminations = (
  deserialized: UpdatePerson_updatePerson_triages_eligibilityDeterminations
): UpdatePerson_updatePerson_triages_eligibilityDeterminationsOrig => ({
  ...deserialized,
  month:
    deserialized.month != null
      ? formatISODate(deserialized.month)
      : deserialized.month,
  createdAt: formatISO(deserialized.createdAt),
  additionalData:
    deserialized.additionalData != null
      ? serializeAdditionalData(deserialized.additionalData)
      : deserialized.additionalData,
})
export interface UpdatePerson_updatePerson_triages_assignedTo {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeUpdatePerson_updatePerson_triages_assignedTo = (
  serialized: UpdatePerson_updatePerson_triages_assignedToOrig
): UpdatePerson_updatePerson_triages_assignedTo => ({...serialized})
export const serializeUpdatePerson_updatePerson_triages_assignedTo = (
  deserialized: UpdatePerson_updatePerson_triages_assignedTo
): UpdatePerson_updatePerson_triages_assignedToOrig => ({...deserialized})
export interface UpdatePerson_updatePerson_triages {
  __typename: 'Triage'
  id: string
  account: UpdatePerson_updatePerson_triages_account
  createdAt: Date
  updatedAt: Date
  status: string
  closeReason: string | null
  closeComment: string | null
  eligibilityDeterminations: UpdatePerson_updatePerson_triages_eligibilityDeterminations[]
  assignedTo: UpdatePerson_updatePerson_triages_assignedTo | null
}
export const deserializeUpdatePerson_updatePerson_triages = (
  serialized: UpdatePerson_updatePerson_triagesOrig
): UpdatePerson_updatePerson_triages => ({
  ...serialized,
  account: deserializeUpdatePerson_updatePerson_triages_account(
    serialized.account
  ),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  eligibilityDeterminations: serialized.eligibilityDeterminations.map(
    deserializeUpdatePerson_updatePerson_triages_eligibilityDeterminations
  ),
  assignedTo:
    serialized.assignedTo != null
      ? deserializeUpdatePerson_updatePerson_triages_assignedTo(
          serialized.assignedTo
        )
      : serialized.assignedTo,
})
export const serializeUpdatePerson_updatePerson_triages = (
  deserialized: UpdatePerson_updatePerson_triages
): UpdatePerson_updatePerson_triagesOrig => ({
  ...deserialized,
  account: serializeUpdatePerson_updatePerson_triages_account(
    deserialized.account
  ),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  eligibilityDeterminations: deserialized.eligibilityDeterminations.map(
    serializeUpdatePerson_updatePerson_triages_eligibilityDeterminations
  ),
  assignedTo:
    deserialized.assignedTo != null
      ? serializeUpdatePerson_updatePerson_triages_assignedTo(
          deserialized.assignedTo
        )
      : deserialized.assignedTo,
})
export interface UpdatePerson_updatePerson_openTriages_account {
  __typename: 'Account'
  id: string
  hospitalAccountId: string | null
}
export const deserializeUpdatePerson_updatePerson_openTriages_account = (
  serialized: UpdatePerson_updatePerson_openTriages_accountOrig
): UpdatePerson_updatePerson_openTriages_account => ({...serialized})
export const serializeUpdatePerson_updatePerson_openTriages_account = (
  deserialized: UpdatePerson_updatePerson_openTriages_account
): UpdatePerson_updatePerson_openTriages_accountOrig => ({...deserialized})
export interface UpdatePerson_updatePerson_openTriages_eligibilityDeterminations {
  __typename: 'EligibilityDetermination'
  id: string
  benefit: string
  status: string
  reasonOrInfo: string | null
  secondaryInfo: string | null
  householdSizeDescription: string | null
  month: Date | null
  numMonths: number | null
  createdAt: Date
  additionalData: AdditionalData | null
}
export const deserializeUpdatePerson_updatePerson_openTriages_eligibilityDeterminations = (
  serialized: UpdatePerson_updatePerson_openTriages_eligibilityDeterminationsOrig
): UpdatePerson_updatePerson_openTriages_eligibilityDeterminations => ({
  ...serialized,
  month:
    serialized.month != null ? parseDate(serialized.month) : serialized.month,
  createdAt: parseDate(serialized.createdAt),
  additionalData:
    serialized.additionalData != null
      ? parseAdditionalData(serialized.additionalData)
      : serialized.additionalData,
})
export const serializeUpdatePerson_updatePerson_openTriages_eligibilityDeterminations = (
  deserialized: UpdatePerson_updatePerson_openTriages_eligibilityDeterminations
): UpdatePerson_updatePerson_openTriages_eligibilityDeterminationsOrig => ({
  ...deserialized,
  month:
    deserialized.month != null
      ? formatISODate(deserialized.month)
      : deserialized.month,
  createdAt: formatISO(deserialized.createdAt),
  additionalData:
    deserialized.additionalData != null
      ? serializeAdditionalData(deserialized.additionalData)
      : deserialized.additionalData,
})
export interface UpdatePerson_updatePerson_openTriages {
  __typename: 'Triage'
  id: string
  status: string
  account: UpdatePerson_updatePerson_openTriages_account
  eligibilityDeterminations: UpdatePerson_updatePerson_openTriages_eligibilityDeterminations[]
}
export const deserializeUpdatePerson_updatePerson_openTriages = (
  serialized: UpdatePerson_updatePerson_openTriagesOrig
): UpdatePerson_updatePerson_openTriages => ({
  ...serialized,
  account: deserializeUpdatePerson_updatePerson_openTriages_account(
    serialized.account
  ),
  eligibilityDeterminations: serialized.eligibilityDeterminations.map(
    deserializeUpdatePerson_updatePerson_openTriages_eligibilityDeterminations
  ),
})
export const serializeUpdatePerson_updatePerson_openTriages = (
  deserialized: UpdatePerson_updatePerson_openTriages
): UpdatePerson_updatePerson_openTriagesOrig => ({
  ...deserialized,
  account: serializeUpdatePerson_updatePerson_openTriages_account(
    deserialized.account
  ),
  eligibilityDeterminations: deserialized.eligibilityDeterminations.map(
    serializeUpdatePerson_updatePerson_openTriages_eligibilityDeterminations
  ),
})
export interface UpdatePerson_updatePerson_relationships_otherPerson_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
}
export const deserializeUpdatePerson_updatePerson_relationships_otherPerson_phoneNumbers = (
  serialized: UpdatePerson_updatePerson_relationships_otherPerson_phoneNumbersOrig
): UpdatePerson_updatePerson_relationships_otherPerson_phoneNumbers => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_relationships_otherPerson_phoneNumbers = (
  deserialized: UpdatePerson_updatePerson_relationships_otherPerson_phoneNumbers
): UpdatePerson_updatePerson_relationships_otherPerson_phoneNumbersOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_relationships_otherPerson_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
}
export const deserializeUpdatePerson_updatePerson_relationships_otherPerson_incomeSources = (
  serialized: UpdatePerson_updatePerson_relationships_otherPerson_incomeSourcesOrig
): UpdatePerson_updatePerson_relationships_otherPerson_incomeSources => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_relationships_otherPerson_incomeSources = (
  deserialized: UpdatePerson_updatePerson_relationships_otherPerson_incomeSources
): UpdatePerson_updatePerson_relationships_otherPerson_incomeSourcesOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_relationships_otherPerson_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeUpdatePerson_updatePerson_relationships_otherPerson_deductions = (
  serialized: UpdatePerson_updatePerson_relationships_otherPerson_deductionsOrig
): UpdatePerson_updatePerson_relationships_otherPerson_deductions => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_relationships_otherPerson_deductions = (
  deserialized: UpdatePerson_updatePerson_relationships_otherPerson_deductions
): UpdatePerson_updatePerson_relationships_otherPerson_deductionsOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_relationships_otherPerson_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeUpdatePerson_updatePerson_relationships_otherPerson_assets = (
  serialized: UpdatePerson_updatePerson_relationships_otherPerson_assetsOrig
): UpdatePerson_updatePerson_relationships_otherPerson_assets => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_relationships_otherPerson_assets = (
  deserialized: UpdatePerson_updatePerson_relationships_otherPerson_assets
): UpdatePerson_updatePerson_relationships_otherPerson_assetsOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_relationships_otherPerson_openApplications_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_relationships_otherPerson_openApplications_person = (
  serialized: UpdatePerson_updatePerson_relationships_otherPerson_openApplications_personOrig
): UpdatePerson_updatePerson_relationships_otherPerson_openApplications_person => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_relationships_otherPerson_openApplications_person = (
  deserialized: UpdatePerson_updatePerson_relationships_otherPerson_openApplications_person
): UpdatePerson_updatePerson_relationships_otherPerson_openApplications_personOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_relationships_otherPerson_openApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  person: UpdatePerson_updatePerson_relationships_otherPerson_openApplications_person
}
export const deserializeUpdatePerson_updatePerson_relationships_otherPerson_openApplications = (
  serialized: UpdatePerson_updatePerson_relationships_otherPerson_openApplicationsOrig
): UpdatePerson_updatePerson_relationships_otherPerson_openApplications => ({
  ...serialized,
  person: deserializeUpdatePerson_updatePerson_relationships_otherPerson_openApplications_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_relationships_otherPerson_openApplications = (
  deserialized: UpdatePerson_updatePerson_relationships_otherPerson_openApplications
): UpdatePerson_updatePerson_relationships_otherPerson_openApplicationsOrig => ({
  ...deserialized,
  person: serializeUpdatePerson_updatePerson_relationships_otherPerson_openApplications_person(
    deserialized.person
  ),
})
export interface UpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplications_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplications_person = (
  serialized: UpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplications_personOrig
): UpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplications_person => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplications_person = (
  deserialized: UpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplications_person
): UpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplications_personOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  person: UpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplications_person
}
export const deserializeUpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplications = (
  serialized: UpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplicationsOrig
): UpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplications => ({
  ...serialized,
  person: deserializeUpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplications_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplications = (
  deserialized: UpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplications
): UpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  person: serializeUpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplications_person(
    deserialized.person
  ),
})
export interface UpdatePerson_updatePerson_relationships_otherPerson {
  __typename: 'Person'
  id: string
  updatedAt: Date
  firstName: string | null
  middleName: string | null
  lastName: string | null
  suffix: string | null
  preferredName: string | null
  dob: Date | null
  gender: string | null
  ssn: string | null
  phoneNumbers: UpdatePerson_updatePerson_relationships_otherPerson_phoneNumbers[]
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  email: string | null
  emailComment: string | null
  preferredLanguage: string | null
  hospitalPatientId: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  incomeSources: UpdatePerson_updatePerson_relationships_otherPerson_incomeSources[]
  deductions: UpdatePerson_updatePerson_relationships_otherPerson_deductions[]
  assets: UpdatePerson_updatePerson_relationships_otherPerson_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  unreadMessageStatus: boolean | null
  fieldsUpdatedAt: DeserializedFieldsUpdatedAt | null
  openApplications: UpdatePerson_updatePerson_relationships_otherPerson_openApplications[]
  openHouseholdMemberApplications: UpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplications[]
}
export const deserializeUpdatePerson_updatePerson_relationships_otherPerson = (
  serialized: UpdatePerson_updatePerson_relationships_otherPersonOrig
): UpdatePerson_updatePerson_relationships_otherPerson => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeUpdatePerson_updatePerson_relationships_otherPerson_phoneNumbers
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeUpdatePerson_updatePerson_relationships_otherPerson_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeUpdatePerson_updatePerson_relationships_otherPerson_deductions
  ),
  assets: serialized.assets.map(
    deserializeUpdatePerson_updatePerson_relationships_otherPerson_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
  openApplications: serialized.openApplications.map(
    deserializeUpdatePerson_updatePerson_relationships_otherPerson_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeUpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplications
  ),
})
export const serializeUpdatePerson_updatePerson_relationships_otherPerson = (
  deserialized: UpdatePerson_updatePerson_relationships_otherPerson
): UpdatePerson_updatePerson_relationships_otherPersonOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeUpdatePerson_updatePerson_relationships_otherPerson_phoneNumbers
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeUpdatePerson_updatePerson_relationships_otherPerson_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeUpdatePerson_updatePerson_relationships_otherPerson_deductions
  ),
  assets: deserialized.assets.map(
    serializeUpdatePerson_updatePerson_relationships_otherPerson_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
  openApplications: deserialized.openApplications.map(
    serializeUpdatePerson_updatePerson_relationships_otherPerson_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeUpdatePerson_updatePerson_relationships_otherPerson_openHouseholdMemberApplications
  ),
})
export interface UpdatePerson_updatePerson_relationships {
  __typename: 'Relationship'
  id: string
  relationshipType: string | null
  otherPerson: UpdatePerson_updatePerson_relationships_otherPerson
  liveTogether: boolean | null
  fieldsUpdatedAt: DeserializedFieldsUpdatedAt | null
}
export const deserializeUpdatePerson_updatePerson_relationships = (
  serialized: UpdatePerson_updatePerson_relationshipsOrig
): UpdatePerson_updatePerson_relationships => ({
  ...serialized,
  otherPerson: deserializeUpdatePerson_updatePerson_relationships_otherPerson(
    serialized.otherPerson
  ),
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
})
export const serializeUpdatePerson_updatePerson_relationships = (
  deserialized: UpdatePerson_updatePerson_relationships
): UpdatePerson_updatePerson_relationshipsOrig => ({
  ...deserialized,
  otherPerson: serializeUpdatePerson_updatePerson_relationships_otherPerson(
    deserialized.otherPerson
  ),
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
})
export interface UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards = (
  serialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizardsOrig
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSession = (
  serialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSessionOrig
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSession = (
  deserialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSession
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSession | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers = (
  serialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbersOrig
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers = (
  deserialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomes_application {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
}
export const deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomes_application = (
  serialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomes_applicationOrig
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomes_application => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomes_application = (
  deserialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomes_application
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomes_applicationOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomes_application | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomes = (
  serialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomesOrig
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomes = (
  deserialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomes
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSession_esignWizards = (
  serialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSession_esignWizardsOrig
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSession_esignWizards = (
  deserialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSession_esignWizards
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSession = (
  serialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSessionOrig
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSession = (
  deserialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSession
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export interface UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSession | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources = (
  serialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSourcesOrig
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources = (
  deserialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_deductions = (
  serialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_deductionsOrig
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_deductions => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_deductions = (
  deserialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_deductions
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_deductionsOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_MedicaidApplication
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_CharityCareApplication
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_SlideApplication
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications =
  | UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_MedicaidApplication
  | UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_CharityCareApplication
  | UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications = (
  serialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplicationsOrig
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications'
  )
}
export const serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications = (
  deserialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications'
  )
}
export interface UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openHouseholdMemberApplications = (
  serialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openHouseholdMemberApplicationsOrig
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openHouseholdMemberApplications = (
  deserialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openHouseholdMemberApplications
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allApplications_MedicaidApplication_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomes[]
  incomeSources: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources[]
  deductions: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  openApplications: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications[]
  openHouseholdMemberApplications: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openHouseholdMemberApplications[]
}
export const deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person = (
  serialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_personOrig
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_deductions
  ),
  openApplications: serialized.openApplications.map(
    deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openHouseholdMemberApplications
  ),
})
export const serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person = (
  deserialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_deductions
  ),
  openApplications: deserialized.openApplications.map(
    serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person_openHouseholdMemberApplications
  ),
})
export interface UpdatePerson_updatePerson_allApplications_MedicaidApplication_assignedTo {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_assignedTo = (
  serialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_assignedToOrig
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_assignedTo => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_assignedTo = (
  deserialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_assignedTo
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_assignedToOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembers_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembers_person = (
  serialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembers_personOrig
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembers_person => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembers_person = (
  deserialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembers_person
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembers_personOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembers {
  __typename: 'ApplicationHouseholdMember'
  id: string
  wantsCoverage: boolean | null
  person: UpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembers_person
}
export const deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembers = (
  serialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembersOrig
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembers => ({
  ...serialized,
  person: deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembers_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembers = (
  deserialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembers
): UpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembersOrig => ({
  ...deserialized,
  person: serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembers_person(
    deserialized.person
  ),
})
export interface UpdatePerson_updatePerson_allApplications_MedicaidApplication {
  __typename: 'MedicaidApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  submitDate: Date | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  initialDateOfService: Date | null
  mcdType: string
  person: UpdatePerson_updatePerson_allApplications_MedicaidApplication_person
  assignedTo: UpdatePerson_updatePerson_allApplications_MedicaidApplication_assignedTo | null
  householdMembers: UpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembers[]
}
export const deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_allApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_allApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  mcdType: deserializeMcdType(serialized.mcdType),
  person: deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person(
    serialized.person
  ),
  assignedTo:
    serialized.assignedTo != null
      ? deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_assignedTo(
          serialized.assignedTo
        )
      : serialized.assignedTo,
  householdMembers: serialized.householdMembers.map(
    deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembers
  ),
})
export const serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_allApplications_MedicaidApplication
): UpdatePerson_updatePerson_allApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  mcdType: serializeMcdType(deserialized.mcdType),
  person: serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_person(
    deserialized.person
  ),
  assignedTo:
    deserialized.assignedTo != null
      ? serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_assignedTo(
          deserialized.assignedTo
        )
      : deserialized.assignedTo,
  householdMembers: deserialized.householdMembers.map(
    serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication_householdMembers
  ),
})
export interface UpdatePerson_updatePerson_allApplications_MonitorApplication_primaryPointOfContact {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_primaryPointOfContact = (
  serialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_primaryPointOfContactOrig
): UpdatePerson_updatePerson_allApplications_MonitorApplication_primaryPointOfContact => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_primaryPointOfContact = (
  deserialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_primaryPointOfContact
): UpdatePerson_updatePerson_allApplications_MonitorApplication_primaryPointOfContactOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSession_esignWizards = (
  serialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSession_esignWizardsOrig
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSession_esignWizards
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSession = (
  serialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSessionOrig
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSession = (
  deserialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSession
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSession | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers = (
  serialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbersOrig
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers = (
  deserialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface UpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomes_application {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
}
export const deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomes_application = (
  serialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomes_applicationOrig
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomes_application => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomes_application = (
  deserialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomes_application
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomes_applicationOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomes_application | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomes = (
  serialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomesOrig
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomes = (
  deserialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomes
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSession_esignWizards = (
  serialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSession_esignWizardsOrig
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSession_esignWizards = (
  deserialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSession_esignWizards
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSession = (
  serialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSessionOrig
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSession = (
  deserialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSession
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export interface UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSession | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources = (
  serialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSourcesOrig
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources = (
  deserialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface UpdatePerson_updatePerson_allApplications_MonitorApplication_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_deductions = (
  serialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_deductionsOrig
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_deductions => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_deductions = (
  deserialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_deductions
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_deductionsOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_MedicaidApplication
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_CharityCareApplication
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_SlideApplication
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications =
  | UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_MedicaidApplication
  | UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_CharityCareApplication
  | UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications = (
  serialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplicationsOrig
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications'
  )
}
export const serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications = (
  deserialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications'
  )
}
export interface UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_openHouseholdMemberApplications = (
  serialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openHouseholdMemberApplicationsOrig
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_openHouseholdMemberApplications = (
  deserialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openHouseholdMemberApplications
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allApplications_MonitorApplication_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomes[]
  incomeSources: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources[]
  deductions: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  openApplications: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications[]
  openHouseholdMemberApplications: UpdatePerson_updatePerson_allApplications_MonitorApplication_person_openHouseholdMemberApplications[]
}
export const deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person = (
  serialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_personOrig
): UpdatePerson_updatePerson_allApplications_MonitorApplication_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_deductions
  ),
  openApplications: serialized.openApplications.map(
    deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_openHouseholdMemberApplications
  ),
})
export const serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person = (
  deserialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_person
): UpdatePerson_updatePerson_allApplications_MonitorApplication_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_deductions
  ),
  openApplications: deserialized.openApplications.map(
    serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person_openHouseholdMemberApplications
  ),
})
export interface UpdatePerson_updatePerson_allApplications_MonitorApplication_assignedTo {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_assignedTo = (
  serialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_assignedToOrig
): UpdatePerson_updatePerson_allApplications_MonitorApplication_assignedTo => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_assignedTo = (
  deserialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_assignedTo
): UpdatePerson_updatePerson_allApplications_MonitorApplication_assignedToOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembers_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembers_person = (
  serialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembers_personOrig
): UpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembers_person => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembers_person = (
  deserialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembers_person
): UpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembers_personOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembers {
  __typename: 'ApplicationHouseholdMember'
  id: string
  wantsCoverage: boolean | null
  person: UpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembers_person
}
export const deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembers = (
  serialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembersOrig
): UpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembers => ({
  ...serialized,
  person: deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembers_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembers = (
  deserialized: UpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembers
): UpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembersOrig => ({
  ...deserialized,
  person: serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembers_person(
    deserialized.person
  ),
})
export interface UpdatePerson_updatePerson_allApplications_MonitorApplication {
  __typename: 'MonitorApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  submitDate: Date | null
  applicationType: string | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  darSubmitDate: Date | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  primaryPointOfContact: UpdatePerson_updatePerson_allApplications_MonitorApplication_primaryPointOfContact | null
  initialDateOfService: Date | null
  person: UpdatePerson_updatePerson_allApplications_MonitorApplication_person
  assignedTo: UpdatePerson_updatePerson_allApplications_MonitorApplication_assignedTo | null
  householdMembers: UpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembers[]
}
export const deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication = (
  serialized: UpdatePerson_updatePerson_allApplications_MonitorApplicationOrig
): UpdatePerson_updatePerson_allApplications_MonitorApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  darSubmitDate:
    serialized.darSubmitDate != null
      ? parseDate(serialized.darSubmitDate)
      : serialized.darSubmitDate,
  primaryPointOfContact:
    serialized.primaryPointOfContact != null
      ? deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_primaryPointOfContact(
          serialized.primaryPointOfContact
        )
      : serialized.primaryPointOfContact,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  person: deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person(
    serialized.person
  ),
  assignedTo:
    serialized.assignedTo != null
      ? deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_assignedTo(
          serialized.assignedTo
        )
      : serialized.assignedTo,
  householdMembers: serialized.householdMembers.map(
    deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembers
  ),
})
export const serializeUpdatePerson_updatePerson_allApplications_MonitorApplication = (
  deserialized: UpdatePerson_updatePerson_allApplications_MonitorApplication
): UpdatePerson_updatePerson_allApplications_MonitorApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  darSubmitDate:
    deserialized.darSubmitDate != null
      ? formatISODate(deserialized.darSubmitDate)
      : deserialized.darSubmitDate,
  primaryPointOfContact:
    deserialized.primaryPointOfContact != null
      ? serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_primaryPointOfContact(
          deserialized.primaryPointOfContact
        )
      : deserialized.primaryPointOfContact,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  person: serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_person(
    deserialized.person
  ),
  assignedTo:
    deserialized.assignedTo != null
      ? serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_assignedTo(
          deserialized.assignedTo
        )
      : deserialized.assignedTo,
  householdMembers: deserialized.householdMembers.map(
    serializeUpdatePerson_updatePerson_allApplications_MonitorApplication_householdMembers
  ),
})
export interface UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards = (
  serialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizardsOrig
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSession = (
  serialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSessionOrig
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSession = (
  deserialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSession
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSession | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers = (
  serialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbersOrig
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers = (
  deserialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomes_application {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
}
export const deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomes_application = (
  serialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomes_applicationOrig
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomes_application => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomes_application = (
  deserialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomes_application
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomes_applicationOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomes_application | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomes = (
  serialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomesOrig
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomes = (
  deserialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomes
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSession_esignWizards = (
  serialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSession_esignWizardsOrig
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSession_esignWizards = (
  deserialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSession_esignWizards
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSession = (
  serialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSessionOrig
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSession = (
  deserialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSession
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export interface UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSession | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources = (
  serialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSourcesOrig
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources = (
  deserialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_deductions = (
  serialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_deductionsOrig
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_deductions => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_deductions = (
  deserialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_deductions
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_deductionsOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_MedicaidApplication
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_CharityCareApplication
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_SlideApplication
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications =
  | UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_MedicaidApplication
  | UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_CharityCareApplication
  | UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications = (
  serialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplicationsOrig
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications'
  )
}
export const serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications = (
  deserialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications'
  )
}
export interface UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openHouseholdMemberApplications = (
  serialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openHouseholdMemberApplicationsOrig
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openHouseholdMemberApplications = (
  deserialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openHouseholdMemberApplications
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allApplications_CharityCareApplication_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomes[]
  incomeSources: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources[]
  deductions: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  openApplications: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications[]
  openHouseholdMemberApplications: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openHouseholdMemberApplications[]
}
export const deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person = (
  serialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_personOrig
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_deductions
  ),
  openApplications: serialized.openApplications.map(
    deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openHouseholdMemberApplications
  ),
})
export const serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person = (
  deserialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_deductions
  ),
  openApplications: deserialized.openApplications.map(
    serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person_openHouseholdMemberApplications
  ),
})
export interface UpdatePerson_updatePerson_allApplications_CharityCareApplication_assignedTo {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_assignedTo = (
  serialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_assignedToOrig
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_assignedTo => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_assignedTo = (
  deserialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_assignedTo
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_assignedToOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembers_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembers_person = (
  serialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembers_personOrig
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembers_person => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembers_person = (
  deserialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembers_person
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembers_personOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembers {
  __typename: 'ApplicationHouseholdMember'
  id: string
  wantsCoverage: boolean | null
  person: UpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembers_person
}
export const deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembers = (
  serialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembersOrig
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembers => ({
  ...serialized,
  person: deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembers_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembers = (
  deserialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembers
): UpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembersOrig => ({
  ...deserialized,
  person: serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembers_person(
    deserialized.person
  ),
})
export interface UpdatePerson_updatePerson_allApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  initialDateOfService: Date | null
  dateOfApplication: Date | null
  requestedDateOfApplication: Date | null
  notes: string | null
  facility: string
  coveragePeriod: number | null
  charityCareType: string
  person: UpdatePerson_updatePerson_allApplications_CharityCareApplication_person
  assignedTo: UpdatePerson_updatePerson_allApplications_CharityCareApplication_assignedTo | null
  householdMembers: UpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembers[]
}
export const deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_allApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_allApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  dateOfApplication:
    serialized.dateOfApplication != null
      ? parseDate(serialized.dateOfApplication)
      : serialized.dateOfApplication,
  requestedDateOfApplication:
    serialized.requestedDateOfApplication != null
      ? parseDate(serialized.requestedDateOfApplication)
      : serialized.requestedDateOfApplication,
  person: deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person(
    serialized.person
  ),
  assignedTo:
    serialized.assignedTo != null
      ? deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_assignedTo(
          serialized.assignedTo
        )
      : serialized.assignedTo,
  householdMembers: serialized.householdMembers.map(
    deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembers
  ),
})
export const serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_allApplications_CharityCareApplication
): UpdatePerson_updatePerson_allApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  dateOfApplication:
    deserialized.dateOfApplication != null
      ? formatISODate(deserialized.dateOfApplication)
      : deserialized.dateOfApplication,
  requestedDateOfApplication:
    deserialized.requestedDateOfApplication != null
      ? formatISODate(deserialized.requestedDateOfApplication)
      : deserialized.requestedDateOfApplication,
  person: serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_person(
    deserialized.person
  ),
  assignedTo:
    deserialized.assignedTo != null
      ? serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_assignedTo(
          deserialized.assignedTo
        )
      : deserialized.assignedTo,
  householdMembers: deserialized.householdMembers.map(
    serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication_householdMembers
  ),
})
export interface UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSession_esignWizards = (
  serialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSession_esignWizardsOrig
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSession_esignWizards = (
  deserialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSession_esignWizards
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSession_esignWizards[]
}
export const deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSession = (
  serialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSessionOrig
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export const serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSession = (
  deserialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSession
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSession_esignWizards
  ),
})
export interface UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  esignSession: UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSession | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers = (
  serialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbersOrig
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers = (
  deserialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbersOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface UpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomes_application {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
}
export const deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomes_application = (
  serialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomes_applicationOrig
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomes_application => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomes_application = (
  deserialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomes_application
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomes_applicationOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: UpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomes_application | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomes = (
  serialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomesOrig
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomes = (
  deserialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomes
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSession_esignWizards {
  __typename: 'EsignWizard'
  id: string
  completedAt: Date | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSession_esignWizards = (
  serialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSession_esignWizardsOrig
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSession_esignWizards => ({
  ...serialized,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
})
export const serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSession_esignWizards = (
  deserialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSession_esignWizards
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSession_esignWizardsOrig => ({
  ...deserialized,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
})
export interface UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSession {
  __typename: 'EsignSession'
  id: string
  unreviewed: boolean | null
  esignWizards: UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSession_esignWizards[]
}
export const deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSession = (
  serialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSessionOrig
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSession => ({
  ...serialized,
  esignWizards: serialized.esignWizards.map(
    deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export const serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSession = (
  deserialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSession
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSessionOrig => ({
  ...deserialized,
  esignWizards: deserialized.esignWizards.map(
    serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSession_esignWizards
  ),
})
export interface UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
  esignSession: UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSession | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources = (
  serialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSourcesOrig
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources => ({
  ...serialized,
  esignSession:
    serialized.esignSession != null
      ? deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSession(
          serialized.esignSession
        )
      : serialized.esignSession,
})
export const serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources = (
  deserialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSourcesOrig => ({
  ...deserialized,
  esignSession:
    deserialized.esignSession != null
      ? serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources_esignSession(
          deserialized.esignSession
        )
      : deserialized.esignSession,
})
export interface UpdatePerson_updatePerson_allApplications_SlideApplication_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_deductions = (
  serialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_deductionsOrig
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_deductions => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_deductions = (
  deserialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_deductions
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_deductionsOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_MedicaidApplication
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_CharityCareApplication
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_SlideApplication
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications =
  | UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_MedicaidApplication
  | UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_CharityCareApplication
  | UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications = (
  serialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplicationsOrig
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications'
  )
}
export const serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications = (
  deserialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications'
  )
}
export interface UpdatePerson_updatePerson_allApplications_SlideApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_openHouseholdMemberApplications = (
  serialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_openHouseholdMemberApplicationsOrig
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_openHouseholdMemberApplications = (
  deserialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person_openHouseholdMemberApplications
): UpdatePerson_updatePerson_allApplications_SlideApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allApplications_SlideApplication_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  ssn: string | null
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  phoneNumbers: UpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers[]
  preferredLanguage: string | null
  benefitOutcomes: UpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomes[]
  incomeSources: UpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources[]
  deductions: UpdatePerson_updatePerson_allApplications_SlideApplication_person_deductions[]
  email: string | null
  hospitalPatientId: string | null
  unreadMessageStatus: boolean | null
  openApplications: UpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications[]
  openHouseholdMemberApplications: UpdatePerson_updatePerson_allApplications_SlideApplication_person_openHouseholdMemberApplications[]
}
export const deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person = (
  serialized: UpdatePerson_updatePerson_allApplications_SlideApplication_personOrig
): UpdatePerson_updatePerson_allApplications_SlideApplication_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomes
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_deductions
  ),
  openApplications: serialized.openApplications.map(
    deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_openHouseholdMemberApplications
  ),
})
export const serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person = (
  deserialized: UpdatePerson_updatePerson_allApplications_SlideApplication_person
): UpdatePerson_updatePerson_allApplications_SlideApplication_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_phoneNumbers
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_benefitOutcomes
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_deductions
  ),
  openApplications: deserialized.openApplications.map(
    serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person_openHouseholdMemberApplications
  ),
})
export interface UpdatePerson_updatePerson_allApplications_SlideApplication_assignedTo {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_assignedTo = (
  serialized: UpdatePerson_updatePerson_allApplications_SlideApplication_assignedToOrig
): UpdatePerson_updatePerson_allApplications_SlideApplication_assignedTo => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allApplications_SlideApplication_assignedTo = (
  deserialized: UpdatePerson_updatePerson_allApplications_SlideApplication_assignedTo
): UpdatePerson_updatePerson_allApplications_SlideApplication_assignedToOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allApplications_SlideApplication_householdMembers_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_householdMembers_person = (
  serialized: UpdatePerson_updatePerson_allApplications_SlideApplication_householdMembers_personOrig
): UpdatePerson_updatePerson_allApplications_SlideApplication_householdMembers_person => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allApplications_SlideApplication_householdMembers_person = (
  deserialized: UpdatePerson_updatePerson_allApplications_SlideApplication_householdMembers_person
): UpdatePerson_updatePerson_allApplications_SlideApplication_householdMembers_personOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allApplications_SlideApplication_householdMembers {
  __typename: 'ApplicationHouseholdMember'
  id: string
  wantsCoverage: boolean | null
  person: UpdatePerson_updatePerson_allApplications_SlideApplication_householdMembers_person
}
export const deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_householdMembers = (
  serialized: UpdatePerson_updatePerson_allApplications_SlideApplication_householdMembersOrig
): UpdatePerson_updatePerson_allApplications_SlideApplication_householdMembers => ({
  ...serialized,
  person: deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_householdMembers_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_allApplications_SlideApplication_householdMembers = (
  deserialized: UpdatePerson_updatePerson_allApplications_SlideApplication_householdMembers
): UpdatePerson_updatePerson_allApplications_SlideApplication_householdMembersOrig => ({
  ...deserialized,
  person: serializeUpdatePerson_updatePerson_allApplications_SlideApplication_householdMembers_person(
    deserialized.person
  ),
})
export interface UpdatePerson_updatePerson_allApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  initialDateOfService: Date | null
  receivedDate: Date | null
  notes: string | null
  facility: string
  person: UpdatePerson_updatePerson_allApplications_SlideApplication_person
  assignedTo: UpdatePerson_updatePerson_allApplications_SlideApplication_assignedTo | null
  householdMembers: UpdatePerson_updatePerson_allApplications_SlideApplication_householdMembers[]
}
export const deserializeUpdatePerson_updatePerson_allApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_allApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_allApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  receivedDate:
    serialized.receivedDate != null
      ? parseDate(serialized.receivedDate)
      : serialized.receivedDate,
  person: deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_person(
    serialized.person
  ),
  assignedTo:
    serialized.assignedTo != null
      ? deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_assignedTo(
          serialized.assignedTo
        )
      : serialized.assignedTo,
  householdMembers: serialized.householdMembers.map(
    deserializeUpdatePerson_updatePerson_allApplications_SlideApplication_householdMembers
  ),
})
export const serializeUpdatePerson_updatePerson_allApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_allApplications_SlideApplication
): UpdatePerson_updatePerson_allApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  receivedDate:
    deserialized.receivedDate != null
      ? formatISODate(deserialized.receivedDate)
      : deserialized.receivedDate,
  person: serializeUpdatePerson_updatePerson_allApplications_SlideApplication_person(
    deserialized.person
  ),
  assignedTo:
    deserialized.assignedTo != null
      ? serializeUpdatePerson_updatePerson_allApplications_SlideApplication_assignedTo(
          deserialized.assignedTo
        )
      : deserialized.assignedTo,
  householdMembers: deserialized.householdMembers.map(
    serializeUpdatePerson_updatePerson_allApplications_SlideApplication_householdMembers
  ),
})
export type UpdatePerson_updatePerson_allApplications =
  | UpdatePerson_updatePerson_allApplications_MedicaidApplication
  | UpdatePerson_updatePerson_allApplications_MonitorApplication
  | UpdatePerson_updatePerson_allApplications_CharityCareApplication
  | UpdatePerson_updatePerson_allApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_allApplications = (
  serialized: UpdatePerson_updatePerson_allApplicationsOrig
): UpdatePerson_updatePerson_allApplications => {
  if (false || serialized.__typename === 'MedicaidApplication')
    return deserializeUpdatePerson_updatePerson_allApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'MonitorApplication')
    return deserializeUpdatePerson_updatePerson_allApplications_MonitorApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_allApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_allApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` + 'UpdatePerson_updatePerson_allApplications'
  )
}
export const serializeUpdatePerson_updatePerson_allApplications = (
  deserialized: UpdatePerson_updatePerson_allApplications
): UpdatePerson_updatePerson_allApplicationsOrig => {
  if (false || deserialized.__typename === 'MedicaidApplication')
    return serializeUpdatePerson_updatePerson_allApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'MonitorApplication')
    return serializeUpdatePerson_updatePerson_allApplications_MonitorApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_allApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_allApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` + 'UpdatePerson_updatePerson_allApplications'
  )
}
export interface UpdatePerson_updatePerson_openApplications_MedicaidApplication_eligibilityDeterminations {
  __typename: 'EligibilityDetermination'
  id: string
  benefit: string
  status: string
  reasonOrInfo: string | null
  secondaryInfo: string | null
  householdSizeDescription: string | null
  month: Date | null
  numMonths: number | null
  createdAt: Date
  additionalData: AdditionalData | null
}
export const deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_eligibilityDeterminations = (
  serialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_eligibilityDeterminationsOrig
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_eligibilityDeterminations => ({
  ...serialized,
  month:
    serialized.month != null ? parseDate(serialized.month) : serialized.month,
  createdAt: parseDate(serialized.createdAt),
  additionalData:
    serialized.additionalData != null
      ? parseAdditionalData(serialized.additionalData)
      : serialized.additionalData,
})
export const serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_eligibilityDeterminations = (
  deserialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_eligibilityDeterminations
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_eligibilityDeterminationsOrig => ({
  ...deserialized,
  month:
    deserialized.month != null
      ? formatISODate(deserialized.month)
      : deserialized.month,
  createdAt: formatISO(deserialized.createdAt),
  additionalData:
    deserialized.additionalData != null
      ? serializeAdditionalData(deserialized.additionalData)
      : deserialized.additionalData,
})
export interface UpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembers_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembers_person = (
  serialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembers_personOrig
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembers_person => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembers_person = (
  deserialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembers_person
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembers_personOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembers {
  __typename: 'ApplicationHouseholdMember'
  id: string
  person: UpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembers_person
}
export const deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembers = (
  serialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembersOrig
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembers => ({
  ...serialized,
  person: deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembers_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembers = (
  deserialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembers
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembersOrig => ({
  ...deserialized,
  person: serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembers_person(
    deserialized.person
  ),
})
export interface UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_files {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_files = (
  serialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_filesOrig
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_files => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_files = (
  deserialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_files
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_filesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_person = (
  serialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_personOrig
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_person => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_person = (
  deserialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_person
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_personOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_application {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
}
export const deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_application = (
  serialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_applicationOrig
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_application => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_application = (
  deserialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_application
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_applicationOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents {
  __typename: 'Document'
  id: string
  documentType: string
  complete: boolean | null
  files: UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_files[]
  updatedAt: Date
  notes: string | null
  person: UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_person | null
  application: UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_application
}
export const deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_documents = (
  serialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_documentsOrig
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents => ({
  ...serialized,
  files: serialized.files.map(
    deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_files
  ),
  updatedAt: parseDate(serialized.updatedAt),
  person:
    serialized.person != null
      ? deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_person(
          serialized.person
        )
      : serialized.person,
  application: deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_application(
    serialized.application
  ),
})
export const serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_documents = (
  deserialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_documentsOrig => ({
  ...deserialized,
  files: deserialized.files.map(
    serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_files
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  person:
    deserialized.person != null
      ? serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_person(
          deserialized.person
        )
      : deserialized.person,
  application: serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_documents_application(
    deserialized.application
  ),
})
export interface UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_MedicaidApplication
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_CharityCareApplication
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_SlideApplication
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications =
  | UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_MedicaidApplication
  | UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_CharityCareApplication
  | UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications = (
  serialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplicationsOrig
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications'
  )
}
export const serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications = (
  deserialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications'
  )
}
export interface UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openHouseholdMemberApplications = (
  serialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openHouseholdMemberApplicationsOrig
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openHouseholdMemberApplications = (
  deserialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openHouseholdMemberApplications
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openApplications_MedicaidApplication_person {
  __typename: 'Person'
  id: string
  openApplications: UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications[]
  openHouseholdMemberApplications: UpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openHouseholdMemberApplications[]
}
export const deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person = (
  serialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_personOrig
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_person => ({
  ...serialized,
  openApplications: serialized.openApplications.map(
    deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openHouseholdMemberApplications
  ),
})
export const serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person = (
  deserialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication_person
): UpdatePerson_updatePerson_openApplications_MedicaidApplication_personOrig => ({
  ...deserialized,
  openApplications: deserialized.openApplications.map(
    serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person_openHouseholdMemberApplications
  ),
})
export interface UpdatePerson_updatePerson_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  submitDate: Date | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  initialDateOfService: Date | null
  mcdType: string
  eligibilityDeterminations: UpdatePerson_updatePerson_openApplications_MedicaidApplication_eligibilityDeterminations[]
  householdMembers: UpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembers[]
  documents: UpdatePerson_updatePerson_openApplications_MedicaidApplication_documents[]
  person: UpdatePerson_updatePerson_openApplications_MedicaidApplication_person
}
export const deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_openApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  mcdType: deserializeMcdType(serialized.mcdType),
  eligibilityDeterminations: serialized.eligibilityDeterminations.map(
    deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_eligibilityDeterminations
  ),
  householdMembers: serialized.householdMembers.map(
    deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembers
  ),
  documents: serialized.documents.map(
    deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_documents
  ),
  person: deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_openApplications_MedicaidApplication
): UpdatePerson_updatePerson_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  mcdType: serializeMcdType(deserialized.mcdType),
  eligibilityDeterminations: deserialized.eligibilityDeterminations.map(
    serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_eligibilityDeterminations
  ),
  householdMembers: deserialized.householdMembers.map(
    serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_householdMembers
  ),
  documents: deserialized.documents.map(
    serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_documents
  ),
  person: serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication_person(
    deserialized.person
  ),
})
export interface UpdatePerson_updatePerson_openApplications_MonitorApplication_primaryPointOfContact {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_primaryPointOfContact = (
  serialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_primaryPointOfContactOrig
): UpdatePerson_updatePerson_openApplications_MonitorApplication_primaryPointOfContact => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_primaryPointOfContact = (
  deserialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_primaryPointOfContact
): UpdatePerson_updatePerson_openApplications_MonitorApplication_primaryPointOfContactOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_openApplications_MonitorApplication_eligibilityDeterminations {
  __typename: 'EligibilityDetermination'
  id: string
  benefit: string
  status: string
  reasonOrInfo: string | null
  secondaryInfo: string | null
  householdSizeDescription: string | null
  month: Date | null
  numMonths: number | null
  createdAt: Date
  additionalData: AdditionalData | null
}
export const deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_eligibilityDeterminations = (
  serialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_eligibilityDeterminationsOrig
): UpdatePerson_updatePerson_openApplications_MonitorApplication_eligibilityDeterminations => ({
  ...serialized,
  month:
    serialized.month != null ? parseDate(serialized.month) : serialized.month,
  createdAt: parseDate(serialized.createdAt),
  additionalData:
    serialized.additionalData != null
      ? parseAdditionalData(serialized.additionalData)
      : serialized.additionalData,
})
export const serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_eligibilityDeterminations = (
  deserialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_eligibilityDeterminations
): UpdatePerson_updatePerson_openApplications_MonitorApplication_eligibilityDeterminationsOrig => ({
  ...deserialized,
  month:
    deserialized.month != null
      ? formatISODate(deserialized.month)
      : deserialized.month,
  createdAt: formatISO(deserialized.createdAt),
  additionalData:
    deserialized.additionalData != null
      ? serializeAdditionalData(deserialized.additionalData)
      : deserialized.additionalData,
})
export interface UpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembers_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembers_person = (
  serialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembers_personOrig
): UpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembers_person => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembers_person = (
  deserialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembers_person
): UpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembers_personOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembers {
  __typename: 'ApplicationHouseholdMember'
  id: string
  person: UpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembers_person
}
export const deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembers = (
  serialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembersOrig
): UpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembers => ({
  ...serialized,
  person: deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembers_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembers = (
  deserialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembers
): UpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembersOrig => ({
  ...deserialized,
  person: serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembers_person(
    deserialized.person
  ),
})
export interface UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_files {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_documents_files = (
  serialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_filesOrig
): UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_files => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_documents_files = (
  deserialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_files
): UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_filesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_documents_person = (
  serialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_personOrig
): UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_person => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_documents_person = (
  deserialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_person
): UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_personOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_application {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
}
export const deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_documents_application = (
  serialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_applicationOrig
): UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_application => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_documents_application = (
  deserialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_application
): UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_applicationOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_openApplications_MonitorApplication_documents {
  __typename: 'Document'
  id: string
  documentType: string
  complete: boolean | null
  files: UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_files[]
  updatedAt: Date
  notes: string | null
  person: UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_person | null
  application: UpdatePerson_updatePerson_openApplications_MonitorApplication_documents_application
}
export const deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_documents = (
  serialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_documentsOrig
): UpdatePerson_updatePerson_openApplications_MonitorApplication_documents => ({
  ...serialized,
  files: serialized.files.map(
    deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_documents_files
  ),
  updatedAt: parseDate(serialized.updatedAt),
  person:
    serialized.person != null
      ? deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_documents_person(
          serialized.person
        )
      : serialized.person,
  application: deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_documents_application(
    serialized.application
  ),
})
export const serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_documents = (
  deserialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_documents
): UpdatePerson_updatePerson_openApplications_MonitorApplication_documentsOrig => ({
  ...deserialized,
  files: deserialized.files.map(
    serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_documents_files
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  person:
    deserialized.person != null
      ? serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_documents_person(
          deserialized.person
        )
      : deserialized.person,
  application: serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_documents_application(
    deserialized.application
  ),
})
export interface UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_MedicaidApplication
): UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_CharityCareApplication
): UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_SlideApplication
): UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications =
  | UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_MedicaidApplication
  | UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_CharityCareApplication
  | UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications = (
  serialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplicationsOrig
): UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications'
  )
}
export const serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications = (
  deserialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications
): UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications'
  )
}
export interface UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person_openHouseholdMemberApplications = (
  serialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openHouseholdMemberApplicationsOrig
): UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person_openHouseholdMemberApplications = (
  deserialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openHouseholdMemberApplications
): UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openApplications_MonitorApplication_person {
  __typename: 'Person'
  id: string
  openApplications: UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications[]
  openHouseholdMemberApplications: UpdatePerson_updatePerson_openApplications_MonitorApplication_person_openHouseholdMemberApplications[]
}
export const deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person = (
  serialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_personOrig
): UpdatePerson_updatePerson_openApplications_MonitorApplication_person => ({
  ...serialized,
  openApplications: serialized.openApplications.map(
    deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person_openHouseholdMemberApplications
  ),
})
export const serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person = (
  deserialized: UpdatePerson_updatePerson_openApplications_MonitorApplication_person
): UpdatePerson_updatePerson_openApplications_MonitorApplication_personOrig => ({
  ...deserialized,
  openApplications: deserialized.openApplications.map(
    serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person_openHouseholdMemberApplications
  ),
})
export interface UpdatePerson_updatePerson_openApplications_MonitorApplication {
  __typename: 'MonitorApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  submitDate: Date | null
  applicationType: string | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  darSubmitDate: Date | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  primaryPointOfContact: UpdatePerson_updatePerson_openApplications_MonitorApplication_primaryPointOfContact | null
  initialDateOfService: Date | null
  eligibilityDeterminations: UpdatePerson_updatePerson_openApplications_MonitorApplication_eligibilityDeterminations[]
  householdMembers: UpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembers[]
  documents: UpdatePerson_updatePerson_openApplications_MonitorApplication_documents[]
  person: UpdatePerson_updatePerson_openApplications_MonitorApplication_person
}
export const deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication = (
  serialized: UpdatePerson_updatePerson_openApplications_MonitorApplicationOrig
): UpdatePerson_updatePerson_openApplications_MonitorApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  darSubmitDate:
    serialized.darSubmitDate != null
      ? parseDate(serialized.darSubmitDate)
      : serialized.darSubmitDate,
  primaryPointOfContact:
    serialized.primaryPointOfContact != null
      ? deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_primaryPointOfContact(
          serialized.primaryPointOfContact
        )
      : serialized.primaryPointOfContact,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  eligibilityDeterminations: serialized.eligibilityDeterminations.map(
    deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_eligibilityDeterminations
  ),
  householdMembers: serialized.householdMembers.map(
    deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembers
  ),
  documents: serialized.documents.map(
    deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_documents
  ),
  person: deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_openApplications_MonitorApplication = (
  deserialized: UpdatePerson_updatePerson_openApplications_MonitorApplication
): UpdatePerson_updatePerson_openApplications_MonitorApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  darSubmitDate:
    deserialized.darSubmitDate != null
      ? formatISODate(deserialized.darSubmitDate)
      : deserialized.darSubmitDate,
  primaryPointOfContact:
    deserialized.primaryPointOfContact != null
      ? serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_primaryPointOfContact(
          deserialized.primaryPointOfContact
        )
      : deserialized.primaryPointOfContact,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  eligibilityDeterminations: deserialized.eligibilityDeterminations.map(
    serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_eligibilityDeterminations
  ),
  householdMembers: deserialized.householdMembers.map(
    serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_householdMembers
  ),
  documents: deserialized.documents.map(
    serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_documents
  ),
  person: serializeUpdatePerson_updatePerson_openApplications_MonitorApplication_person(
    deserialized.person
  ),
})
export interface UpdatePerson_updatePerson_openApplications_CharityCareApplication_eligibilityDeterminations {
  __typename: 'EligibilityDetermination'
  id: string
  benefit: string
  status: string
  reasonOrInfo: string | null
  secondaryInfo: string | null
  householdSizeDescription: string | null
  month: Date | null
  numMonths: number | null
  createdAt: Date
  additionalData: AdditionalData | null
}
export const deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_eligibilityDeterminations = (
  serialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_eligibilityDeterminationsOrig
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_eligibilityDeterminations => ({
  ...serialized,
  month:
    serialized.month != null ? parseDate(serialized.month) : serialized.month,
  createdAt: parseDate(serialized.createdAt),
  additionalData:
    serialized.additionalData != null
      ? parseAdditionalData(serialized.additionalData)
      : serialized.additionalData,
})
export const serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_eligibilityDeterminations = (
  deserialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_eligibilityDeterminations
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_eligibilityDeterminationsOrig => ({
  ...deserialized,
  month:
    deserialized.month != null
      ? formatISODate(deserialized.month)
      : deserialized.month,
  createdAt: formatISO(deserialized.createdAt),
  additionalData:
    deserialized.additionalData != null
      ? serializeAdditionalData(deserialized.additionalData)
      : deserialized.additionalData,
})
export interface UpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembers_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembers_person = (
  serialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembers_personOrig
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembers_person => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembers_person = (
  deserialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembers_person
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembers_personOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembers {
  __typename: 'ApplicationHouseholdMember'
  id: string
  person: UpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembers_person
}
export const deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembers = (
  serialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembersOrig
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembers => ({
  ...serialized,
  person: deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembers_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembers = (
  deserialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembers
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembersOrig => ({
  ...deserialized,
  person: serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembers_person(
    deserialized.person
  ),
})
export interface UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_files {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_files = (
  serialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_filesOrig
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_files => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_files = (
  deserialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_files
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_filesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_person = (
  serialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_personOrig
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_person => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_person = (
  deserialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_person
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_personOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_application {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
}
export const deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_application = (
  serialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_applicationOrig
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_application => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_application = (
  deserialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_application
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_applicationOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents {
  __typename: 'Document'
  id: string
  documentType: string
  complete: boolean | null
  files: UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_files[]
  updatedAt: Date
  notes: string | null
  person: UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_person | null
  application: UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_application
}
export const deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_documents = (
  serialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_documentsOrig
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents => ({
  ...serialized,
  files: serialized.files.map(
    deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_files
  ),
  updatedAt: parseDate(serialized.updatedAt),
  person:
    serialized.person != null
      ? deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_person(
          serialized.person
        )
      : serialized.person,
  application: deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_application(
    serialized.application
  ),
})
export const serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_documents = (
  deserialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_documentsOrig => ({
  ...deserialized,
  files: deserialized.files.map(
    serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_files
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  person:
    deserialized.person != null
      ? serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_person(
          deserialized.person
        )
      : deserialized.person,
  application: serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_documents_application(
    deserialized.application
  ),
})
export interface UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_MedicaidApplication
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_CharityCareApplication
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_SlideApplication
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications =
  | UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_MedicaidApplication
  | UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_CharityCareApplication
  | UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications = (
  serialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplicationsOrig
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications'
  )
}
export const serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications = (
  deserialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications'
  )
}
export interface UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openHouseholdMemberApplications = (
  serialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openHouseholdMemberApplicationsOrig
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openHouseholdMemberApplications = (
  deserialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openHouseholdMemberApplications
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openApplications_CharityCareApplication_person {
  __typename: 'Person'
  id: string
  openApplications: UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications[]
  openHouseholdMemberApplications: UpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openHouseholdMemberApplications[]
}
export const deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person = (
  serialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_personOrig
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_person => ({
  ...serialized,
  openApplications: serialized.openApplications.map(
    deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openHouseholdMemberApplications
  ),
})
export const serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person = (
  deserialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication_person
): UpdatePerson_updatePerson_openApplications_CharityCareApplication_personOrig => ({
  ...deserialized,
  openApplications: deserialized.openApplications.map(
    serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person_openHouseholdMemberApplications
  ),
})
export interface UpdatePerson_updatePerson_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  initialDateOfService: Date | null
  dateOfApplication: Date | null
  requestedDateOfApplication: Date | null
  notes: string | null
  facility: string
  coveragePeriod: number | null
  charityCareType: string
  eligibilityDeterminations: UpdatePerson_updatePerson_openApplications_CharityCareApplication_eligibilityDeterminations[]
  householdMembers: UpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembers[]
  documents: UpdatePerson_updatePerson_openApplications_CharityCareApplication_documents[]
  person: UpdatePerson_updatePerson_openApplications_CharityCareApplication_person
}
export const deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_openApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  dateOfApplication:
    serialized.dateOfApplication != null
      ? parseDate(serialized.dateOfApplication)
      : serialized.dateOfApplication,
  requestedDateOfApplication:
    serialized.requestedDateOfApplication != null
      ? parseDate(serialized.requestedDateOfApplication)
      : serialized.requestedDateOfApplication,
  eligibilityDeterminations: serialized.eligibilityDeterminations.map(
    deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_eligibilityDeterminations
  ),
  householdMembers: serialized.householdMembers.map(
    deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembers
  ),
  documents: serialized.documents.map(
    deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_documents
  ),
  person: deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_openApplications_CharityCareApplication
): UpdatePerson_updatePerson_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  dateOfApplication:
    deserialized.dateOfApplication != null
      ? formatISODate(deserialized.dateOfApplication)
      : deserialized.dateOfApplication,
  requestedDateOfApplication:
    deserialized.requestedDateOfApplication != null
      ? formatISODate(deserialized.requestedDateOfApplication)
      : deserialized.requestedDateOfApplication,
  eligibilityDeterminations: deserialized.eligibilityDeterminations.map(
    serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_eligibilityDeterminations
  ),
  householdMembers: deserialized.householdMembers.map(
    serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_householdMembers
  ),
  documents: deserialized.documents.map(
    serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_documents
  ),
  person: serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication_person(
    deserialized.person
  ),
})
export interface UpdatePerson_updatePerson_openApplications_SlideApplication_eligibilityDeterminations {
  __typename: 'EligibilityDetermination'
  id: string
  benefit: string
  status: string
  reasonOrInfo: string | null
  secondaryInfo: string | null
  householdSizeDescription: string | null
  month: Date | null
  numMonths: number | null
  createdAt: Date
  additionalData: AdditionalData | null
}
export const deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_eligibilityDeterminations = (
  serialized: UpdatePerson_updatePerson_openApplications_SlideApplication_eligibilityDeterminationsOrig
): UpdatePerson_updatePerson_openApplications_SlideApplication_eligibilityDeterminations => ({
  ...serialized,
  month:
    serialized.month != null ? parseDate(serialized.month) : serialized.month,
  createdAt: parseDate(serialized.createdAt),
  additionalData:
    serialized.additionalData != null
      ? parseAdditionalData(serialized.additionalData)
      : serialized.additionalData,
})
export const serializeUpdatePerson_updatePerson_openApplications_SlideApplication_eligibilityDeterminations = (
  deserialized: UpdatePerson_updatePerson_openApplications_SlideApplication_eligibilityDeterminations
): UpdatePerson_updatePerson_openApplications_SlideApplication_eligibilityDeterminationsOrig => ({
  ...deserialized,
  month:
    deserialized.month != null
      ? formatISODate(deserialized.month)
      : deserialized.month,
  createdAt: formatISO(deserialized.createdAt),
  additionalData:
    deserialized.additionalData != null
      ? serializeAdditionalData(deserialized.additionalData)
      : deserialized.additionalData,
})
export interface UpdatePerson_updatePerson_openApplications_SlideApplication_householdMembers_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_householdMembers_person = (
  serialized: UpdatePerson_updatePerson_openApplications_SlideApplication_householdMembers_personOrig
): UpdatePerson_updatePerson_openApplications_SlideApplication_householdMembers_person => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_openApplications_SlideApplication_householdMembers_person = (
  deserialized: UpdatePerson_updatePerson_openApplications_SlideApplication_householdMembers_person
): UpdatePerson_updatePerson_openApplications_SlideApplication_householdMembers_personOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_openApplications_SlideApplication_householdMembers {
  __typename: 'ApplicationHouseholdMember'
  id: string
  person: UpdatePerson_updatePerson_openApplications_SlideApplication_householdMembers_person
}
export const deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_householdMembers = (
  serialized: UpdatePerson_updatePerson_openApplications_SlideApplication_householdMembersOrig
): UpdatePerson_updatePerson_openApplications_SlideApplication_householdMembers => ({
  ...serialized,
  person: deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_householdMembers_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_openApplications_SlideApplication_householdMembers = (
  deserialized: UpdatePerson_updatePerson_openApplications_SlideApplication_householdMembers
): UpdatePerson_updatePerson_openApplications_SlideApplication_householdMembersOrig => ({
  ...deserialized,
  person: serializeUpdatePerson_updatePerson_openApplications_SlideApplication_householdMembers_person(
    deserialized.person
  ),
})
export interface UpdatePerson_updatePerson_openApplications_SlideApplication_documents_files {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_documents_files = (
  serialized: UpdatePerson_updatePerson_openApplications_SlideApplication_documents_filesOrig
): UpdatePerson_updatePerson_openApplications_SlideApplication_documents_files => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeUpdatePerson_updatePerson_openApplications_SlideApplication_documents_files = (
  deserialized: UpdatePerson_updatePerson_openApplications_SlideApplication_documents_files
): UpdatePerson_updatePerson_openApplications_SlideApplication_documents_filesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface UpdatePerson_updatePerson_openApplications_SlideApplication_documents_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_documents_person = (
  serialized: UpdatePerson_updatePerson_openApplications_SlideApplication_documents_personOrig
): UpdatePerson_updatePerson_openApplications_SlideApplication_documents_person => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_openApplications_SlideApplication_documents_person = (
  deserialized: UpdatePerson_updatePerson_openApplications_SlideApplication_documents_person
): UpdatePerson_updatePerson_openApplications_SlideApplication_documents_personOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_openApplications_SlideApplication_documents_application {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
}
export const deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_documents_application = (
  serialized: UpdatePerson_updatePerson_openApplications_SlideApplication_documents_applicationOrig
): UpdatePerson_updatePerson_openApplications_SlideApplication_documents_application => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_openApplications_SlideApplication_documents_application = (
  deserialized: UpdatePerson_updatePerson_openApplications_SlideApplication_documents_application
): UpdatePerson_updatePerson_openApplications_SlideApplication_documents_applicationOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_openApplications_SlideApplication_documents {
  __typename: 'Document'
  id: string
  documentType: string
  complete: boolean | null
  files: UpdatePerson_updatePerson_openApplications_SlideApplication_documents_files[]
  updatedAt: Date
  notes: string | null
  person: UpdatePerson_updatePerson_openApplications_SlideApplication_documents_person | null
  application: UpdatePerson_updatePerson_openApplications_SlideApplication_documents_application
}
export const deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_documents = (
  serialized: UpdatePerson_updatePerson_openApplications_SlideApplication_documentsOrig
): UpdatePerson_updatePerson_openApplications_SlideApplication_documents => ({
  ...serialized,
  files: serialized.files.map(
    deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_documents_files
  ),
  updatedAt: parseDate(serialized.updatedAt),
  person:
    serialized.person != null
      ? deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_documents_person(
          serialized.person
        )
      : serialized.person,
  application: deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_documents_application(
    serialized.application
  ),
})
export const serializeUpdatePerson_updatePerson_openApplications_SlideApplication_documents = (
  deserialized: UpdatePerson_updatePerson_openApplications_SlideApplication_documents
): UpdatePerson_updatePerson_openApplications_SlideApplication_documentsOrig => ({
  ...deserialized,
  files: deserialized.files.map(
    serializeUpdatePerson_updatePerson_openApplications_SlideApplication_documents_files
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  person:
    deserialized.person != null
      ? serializeUpdatePerson_updatePerson_openApplications_SlideApplication_documents_person(
          deserialized.person
        )
      : deserialized.person,
  application: serializeUpdatePerson_updatePerson_openApplications_SlideApplication_documents_application(
    deserialized.application
  ),
})
export interface UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_MedicaidApplication
): UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_CharityCareApplication
): UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_SlideApplication
): UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications =
  | UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_MedicaidApplication
  | UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_CharityCareApplication
  | UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications = (
  serialized: UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplicationsOrig
): UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications'
  )
}
export const serializeUpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications = (
  deserialized: UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications
): UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeUpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications'
  )
}
export interface UpdatePerson_updatePerson_openApplications_SlideApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_person_openHouseholdMemberApplications = (
  serialized: UpdatePerson_updatePerson_openApplications_SlideApplication_person_openHouseholdMemberApplicationsOrig
): UpdatePerson_updatePerson_openApplications_SlideApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openApplications_SlideApplication_person_openHouseholdMemberApplications = (
  deserialized: UpdatePerson_updatePerson_openApplications_SlideApplication_person_openHouseholdMemberApplications
): UpdatePerson_updatePerson_openApplications_SlideApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openApplications_SlideApplication_person {
  __typename: 'Person'
  id: string
  openApplications: UpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications[]
  openHouseholdMemberApplications: UpdatePerson_updatePerson_openApplications_SlideApplication_person_openHouseholdMemberApplications[]
}
export const deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_person = (
  serialized: UpdatePerson_updatePerson_openApplications_SlideApplication_personOrig
): UpdatePerson_updatePerson_openApplications_SlideApplication_person => ({
  ...serialized,
  openApplications: serialized.openApplications.map(
    deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_person_openHouseholdMemberApplications
  ),
})
export const serializeUpdatePerson_updatePerson_openApplications_SlideApplication_person = (
  deserialized: UpdatePerson_updatePerson_openApplications_SlideApplication_person
): UpdatePerson_updatePerson_openApplications_SlideApplication_personOrig => ({
  ...deserialized,
  openApplications: deserialized.openApplications.map(
    serializeUpdatePerson_updatePerson_openApplications_SlideApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeUpdatePerson_updatePerson_openApplications_SlideApplication_person_openHouseholdMemberApplications
  ),
})
export interface UpdatePerson_updatePerson_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  initialDateOfService: Date | null
  receivedDate: Date | null
  notes: string | null
  facility: string
  eligibilityDeterminations: UpdatePerson_updatePerson_openApplications_SlideApplication_eligibilityDeterminations[]
  householdMembers: UpdatePerson_updatePerson_openApplications_SlideApplication_householdMembers[]
  documents: UpdatePerson_updatePerson_openApplications_SlideApplication_documents[]
  person: UpdatePerson_updatePerson_openApplications_SlideApplication_person
}
export const deserializeUpdatePerson_updatePerson_openApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_openApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  receivedDate:
    serialized.receivedDate != null
      ? parseDate(serialized.receivedDate)
      : serialized.receivedDate,
  eligibilityDeterminations: serialized.eligibilityDeterminations.map(
    deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_eligibilityDeterminations
  ),
  householdMembers: serialized.householdMembers.map(
    deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_householdMembers
  ),
  documents: serialized.documents.map(
    deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_documents
  ),
  person: deserializeUpdatePerson_updatePerson_openApplications_SlideApplication_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_openApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_openApplications_SlideApplication
): UpdatePerson_updatePerson_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  receivedDate:
    deserialized.receivedDate != null
      ? formatISODate(deserialized.receivedDate)
      : deserialized.receivedDate,
  eligibilityDeterminations: deserialized.eligibilityDeterminations.map(
    serializeUpdatePerson_updatePerson_openApplications_SlideApplication_eligibilityDeterminations
  ),
  householdMembers: deserialized.householdMembers.map(
    serializeUpdatePerson_updatePerson_openApplications_SlideApplication_householdMembers
  ),
  documents: deserialized.documents.map(
    serializeUpdatePerson_updatePerson_openApplications_SlideApplication_documents
  ),
  person: serializeUpdatePerson_updatePerson_openApplications_SlideApplication_person(
    deserialized.person
  ),
})
export type UpdatePerson_updatePerson_openApplications =
  | UpdatePerson_updatePerson_openApplications_MedicaidApplication
  | UpdatePerson_updatePerson_openApplications_MonitorApplication
  | UpdatePerson_updatePerson_openApplications_CharityCareApplication
  | UpdatePerson_updatePerson_openApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_openApplications = (
  serialized: UpdatePerson_updatePerson_openApplicationsOrig
): UpdatePerson_updatePerson_openApplications => {
  if (false || serialized.__typename === 'MedicaidApplication')
    return deserializeUpdatePerson_updatePerson_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'MonitorApplication')
    return deserializeUpdatePerson_updatePerson_openApplications_MonitorApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_openApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` + 'UpdatePerson_updatePerson_openApplications'
  )
}
export const serializeUpdatePerson_updatePerson_openApplications = (
  deserialized: UpdatePerson_updatePerson_openApplications
): UpdatePerson_updatePerson_openApplicationsOrig => {
  if (false || deserialized.__typename === 'MedicaidApplication')
    return serializeUpdatePerson_updatePerson_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'MonitorApplication')
    return serializeUpdatePerson_updatePerson_openApplications_MonitorApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_openApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` + 'UpdatePerson_updatePerson_openApplications'
  )
}
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplication
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplication
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplication
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications =
  | UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplication
  | UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplication
  | UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplicationsOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications'
  )
}
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications'
  )
}
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplications = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplicationsOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplications = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplications
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person {
  __typename: 'Person'
  id: string
  openApplications: UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications[]
  openHouseholdMemberApplications: UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplications[]
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_personOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person => ({
  ...serialized,
  openApplications: serialized.openApplications.map(
    deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplications
  ),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_personOrig => ({
  ...deserialized,
  openApplications: deserialized.openApplications.map(
    serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplications
  ),
})
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication {
  __typename: 'MedicaidApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  submitDate: Date | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  initialDateOfService: Date | null
  mcdType: string
  person: UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  mcdType: deserializeMcdType(serialized.mcdType),
  person: deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  mcdType: serializeMcdType(deserialized.mcdType),
  person: serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication_person(
    deserialized.person
  ),
})
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_primaryPointOfContact {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_primaryPointOfContact = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_primaryPointOfContactOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_primaryPointOfContact => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_primaryPointOfContact = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_primaryPointOfContact
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_primaryPointOfContactOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplication
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplication
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplication
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications =
  | UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplication
  | UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplication
  | UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplicationsOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications'
  )
}
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications'
  )
}
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplications = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplicationsOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplications = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplications
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person {
  __typename: 'Person'
  id: string
  openApplications: UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications[]
  openHouseholdMemberApplications: UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplications[]
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_personOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person => ({
  ...serialized,
  openApplications: serialized.openApplications.map(
    deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplications
  ),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_personOrig => ({
  ...deserialized,
  openApplications: deserialized.openApplications.map(
    serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplications
  ),
})
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication {
  __typename: 'MonitorApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  submitDate: Date | null
  applicationType: string | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  darSubmitDate: Date | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  primaryPointOfContact: UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_primaryPointOfContact | null
  initialDateOfService: Date | null
  person: UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplicationOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  darSubmitDate:
    serialized.darSubmitDate != null
      ? parseDate(serialized.darSubmitDate)
      : serialized.darSubmitDate,
  primaryPointOfContact:
    serialized.primaryPointOfContact != null
      ? deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_primaryPointOfContact(
          serialized.primaryPointOfContact
        )
      : serialized.primaryPointOfContact,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  person: deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication
): UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  darSubmitDate:
    deserialized.darSubmitDate != null
      ? formatISODate(deserialized.darSubmitDate)
      : deserialized.darSubmitDate,
  primaryPointOfContact:
    deserialized.primaryPointOfContact != null
      ? serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_primaryPointOfContact(
          deserialized.primaryPointOfContact
        )
      : deserialized.primaryPointOfContact,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  person: serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication_person(
    deserialized.person
  ),
})
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplication
): UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplication
): UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplication
): UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications =
  | UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplication
  | UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplication
  | UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplicationsOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications'
  )
}
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications
): UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications'
  )
}
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplications = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplicationsOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplications = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplications
): UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person {
  __typename: 'Person'
  id: string
  openApplications: UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications[]
  openHouseholdMemberApplications: UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplications[]
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_personOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person => ({
  ...serialized,
  openApplications: serialized.openApplications.map(
    deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplications
  ),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person
): UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_personOrig => ({
  ...deserialized,
  openApplications: deserialized.openApplications.map(
    serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplications
  ),
})
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  initialDateOfService: Date | null
  dateOfApplication: Date | null
  requestedDateOfApplication: Date | null
  notes: string | null
  facility: string
  coveragePeriod: number | null
  charityCareType: string
  person: UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  dateOfApplication:
    serialized.dateOfApplication != null
      ? parseDate(serialized.dateOfApplication)
      : serialized.dateOfApplication,
  requestedDateOfApplication:
    serialized.requestedDateOfApplication != null
      ? parseDate(serialized.requestedDateOfApplication)
      : serialized.requestedDateOfApplication,
  person: deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication
): UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  dateOfApplication:
    deserialized.dateOfApplication != null
      ? formatISODate(deserialized.dateOfApplication)
      : deserialized.dateOfApplication,
  requestedDateOfApplication:
    deserialized.requestedDateOfApplication != null
      ? formatISODate(deserialized.requestedDateOfApplication)
      : deserialized.requestedDateOfApplication,
  person: serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication_person(
    deserialized.person
  ),
})
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplication
): UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplication
): UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplication
): UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications =
  | UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplication
  | UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplication
  | UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplicationsOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications'
  )
}
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications
): UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications'
  )
}
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplications = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplicationsOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplications = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplications
): UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person {
  __typename: 'Person'
  id: string
  openApplications: UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications[]
  openHouseholdMemberApplications: UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplications[]
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_personOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person => ({
  ...serialized,
  openApplications: serialized.openApplications.map(
    deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplications
  ),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person
): UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_personOrig => ({
  ...deserialized,
  openApplications: deserialized.openApplications.map(
    serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplications
  ),
})
export interface UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  initialDateOfService: Date | null
  receivedDate: Date | null
  notes: string | null
  facility: string
  person: UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person
}
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  receivedDate:
    serialized.receivedDate != null
      ? parseDate(serialized.receivedDate)
      : serialized.receivedDate,
  person: deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication
): UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  receivedDate:
    deserialized.receivedDate != null
      ? formatISODate(deserialized.receivedDate)
      : deserialized.receivedDate,
  person: serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication_person(
    deserialized.person
  ),
})
export type UpdatePerson_updatePerson_openHouseholdMemberApplications =
  | UpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication
  | UpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication
  | UpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication
  | UpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications = (
  serialized: UpdatePerson_updatePerson_openHouseholdMemberApplicationsOrig
): UpdatePerson_updatePerson_openHouseholdMemberApplications => {
  if (false || serialized.__typename === 'MedicaidApplication')
    return deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'MonitorApplication')
    return deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openHouseholdMemberApplications'
  )
}
export const serializeUpdatePerson_updatePerson_openHouseholdMemberApplications = (
  deserialized: UpdatePerson_updatePerson_openHouseholdMemberApplications
): UpdatePerson_updatePerson_openHouseholdMemberApplicationsOrig => {
  if (false || deserialized.__typename === 'MedicaidApplication')
    return serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'MonitorApplication')
    return serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_MonitorApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_openHouseholdMemberApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openHouseholdMemberApplications'
  )
}
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplication
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplication
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplication
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications =
  | UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplication
  | UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplication
  | UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplicationsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications'
  )
}
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications'
  )
}
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplications = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplicationsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplications = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplications
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_phoneNumbers = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_phoneNumbersOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_phoneNumbers => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_phoneNumbers = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_phoneNumbers
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_phoneNumbersOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_incomeSources = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_incomeSourcesOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_incomeSources => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_incomeSources = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_incomeSources
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_incomeSourcesOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_deductions = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_deductionsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_deductions => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_deductions = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_deductions
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_deductionsOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_assets = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_assetsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_assets => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_assets = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_assets
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_assetsOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_phoneNumbers = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_phoneNumbersOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_phoneNumbers => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_phoneNumbers = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_phoneNumbers
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_phoneNumbersOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_incomeSources = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_incomeSourcesOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_incomeSources => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_incomeSources = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_incomeSources
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_incomeSourcesOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_deductions = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_deductionsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_deductions => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_deductions = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_deductions
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_deductionsOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_assets = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_assetsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_assets => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_assets = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_assets
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_assetsOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson {
  __typename: 'Person'
  id: string
  updatedAt: Date
  firstName: string | null
  middleName: string | null
  lastName: string | null
  suffix: string | null
  preferredName: string | null
  dob: Date | null
  gender: string | null
  ssn: string | null
  phoneNumbers: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_phoneNumbers[]
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  email: string | null
  emailComment: string | null
  preferredLanguage: string | null
  hospitalPatientId: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  incomeSources: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_incomeSources[]
  deductions: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_deductions[]
  assets: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  unreadMessageStatus: boolean | null
  fieldsUpdatedAt: DeserializedFieldsUpdatedAt | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPersonOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_phoneNumbers
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_deductions
  ),
  assets: serialized.assets.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPersonOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_phoneNumbers
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_deductions
  ),
  assets: deserialized.assets.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships {
  __typename: 'Relationship'
  id: string
  relationshipType: string | null
  otherPerson: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson
  liveTogether: boolean | null
  fieldsUpdatedAt: DeserializedFieldsUpdatedAt | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationshipsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships => ({
  ...serialized,
  otherPerson: deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson(
    serialized.otherPerson
  ),
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationshipsOrig => ({
  ...deserialized,
  otherPerson: serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships_otherPerson(
    deserialized.otherPerson
  ),
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person {
  __typename: 'Person'
  id: string
  openApplications: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications[]
  openHouseholdMemberApplications: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplications[]
  updatedAt: Date
  firstName: string | null
  middleName: string | null
  lastName: string | null
  suffix: string | null
  preferredName: string | null
  dob: Date | null
  gender: string | null
  ssn: string | null
  phoneNumbers: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_phoneNumbers[]
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  email: string | null
  emailComment: string | null
  preferredLanguage: string | null
  hospitalPatientId: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  incomeSources: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_incomeSources[]
  deductions: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_deductions[]
  assets: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  unreadMessageStatus: boolean | null
  relationships: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships[]
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_personOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person => ({
  ...serialized,
  openApplications: serialized.openApplications.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplications
  ),
  updatedAt: parseDate(serialized.updatedAt),
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_phoneNumbers
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_deductions
  ),
  assets: serialized.assets.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
  relationships: serialized.relationships.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships
  ),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_personOrig => ({
  ...deserialized,
  openApplications: deserialized.openApplications.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_openHouseholdMemberApplications
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_phoneNumbers
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_deductions
  ),
  assets: deserialized.assets.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
  relationships: deserialized.relationships.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person_relationships
  ),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembers_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembers_person = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembers_personOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembers_person => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembers_person = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembers_person
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembers_personOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembers {
  __typename: 'ApplicationHouseholdMember'
  id: string
  person: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembers_person
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembers = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembersOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembers => ({
  ...serialized,
  person: deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembers_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembers = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembers
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembersOrig => ({
  ...deserialized,
  person: serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembers_person(
    deserialized.person
  ),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_files {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_files = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_filesOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_files => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_files = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_files
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_filesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_person = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_personOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_person => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_person = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_person
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_personOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_application {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_application = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_applicationOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_application => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_application = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_application
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_applicationOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents {
  __typename: 'Document'
  id: string
  documentType: string
  complete: boolean | null
  files: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_files[]
  updatedAt: Date
  notes: string | null
  person: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_person | null
  application: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_application
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documentsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents => ({
  ...serialized,
  files: serialized.files.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_files
  ),
  updatedAt: parseDate(serialized.updatedAt),
  person:
    serialized.person != null
      ? deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_person(
          serialized.person
        )
      : serialized.person,
  application: deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_application(
    serialized.application
  ),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documentsOrig => ({
  ...deserialized,
  files: deserialized.files.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_files
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  person:
    deserialized.person != null
      ? serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_person(
          deserialized.person
        )
      : deserialized.person,
  application: serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents_application(
    deserialized.application
  ),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication {
  __typename: 'MedicaidApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  submitDate: Date | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  initialDateOfService: Date | null
  mcdType: string
  person: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person
  householdMembers: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembers[]
  documents: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents[]
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  mcdType: deserializeMcdType(serialized.mcdType),
  person: deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person(
    serialized.person
  ),
  householdMembers: serialized.householdMembers.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembers
  ),
  documents: serialized.documents.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents
  ),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  mcdType: serializeMcdType(deserialized.mcdType),
  person: serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_person(
    deserialized.person
  ),
  householdMembers: deserialized.householdMembers.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_householdMembers
  ),
  documents: deserialized.documents.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication_documents
  ),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_primaryPointOfContact {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_primaryPointOfContact = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_primaryPointOfContactOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_primaryPointOfContact => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_primaryPointOfContact = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_primaryPointOfContact
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_primaryPointOfContactOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplication
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplication
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplication
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications =
  | UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplication
  | UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplication
  | UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplicationsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications'
  )
}
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications'
  )
}
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplications = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplicationsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplications = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplications
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_phoneNumbers = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_phoneNumbersOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_phoneNumbers => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_phoneNumbers = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_phoneNumbers
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_phoneNumbersOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_incomeSources = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_incomeSourcesOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_incomeSources => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_incomeSources = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_incomeSources
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_incomeSourcesOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_deductions = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_deductionsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_deductions => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_deductions = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_deductions
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_deductionsOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_assets = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_assetsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_assets => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_assets = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_assets
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_assetsOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_phoneNumbers = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_phoneNumbersOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_phoneNumbers => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_phoneNumbers = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_phoneNumbers
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_phoneNumbersOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_incomeSources = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_incomeSourcesOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_incomeSources => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_incomeSources = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_incomeSources
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_incomeSourcesOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_deductions = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_deductionsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_deductions => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_deductions = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_deductions
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_deductionsOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_assets = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_assetsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_assets => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_assets = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_assets
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_assetsOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson {
  __typename: 'Person'
  id: string
  updatedAt: Date
  firstName: string | null
  middleName: string | null
  lastName: string | null
  suffix: string | null
  preferredName: string | null
  dob: Date | null
  gender: string | null
  ssn: string | null
  phoneNumbers: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_phoneNumbers[]
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  email: string | null
  emailComment: string | null
  preferredLanguage: string | null
  hospitalPatientId: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  incomeSources: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_incomeSources[]
  deductions: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_deductions[]
  assets: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  unreadMessageStatus: boolean | null
  fieldsUpdatedAt: DeserializedFieldsUpdatedAt | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPersonOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_phoneNumbers
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_deductions
  ),
  assets: serialized.assets.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPersonOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_phoneNumbers
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_deductions
  ),
  assets: deserialized.assets.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships {
  __typename: 'Relationship'
  id: string
  relationshipType: string | null
  otherPerson: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson
  liveTogether: boolean | null
  fieldsUpdatedAt: DeserializedFieldsUpdatedAt | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationshipsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships => ({
  ...serialized,
  otherPerson: deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson(
    serialized.otherPerson
  ),
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationshipsOrig => ({
  ...deserialized,
  otherPerson: serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships_otherPerson(
    deserialized.otherPerson
  ),
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person {
  __typename: 'Person'
  id: string
  openApplications: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications[]
  openHouseholdMemberApplications: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplications[]
  updatedAt: Date
  firstName: string | null
  middleName: string | null
  lastName: string | null
  suffix: string | null
  preferredName: string | null
  dob: Date | null
  gender: string | null
  ssn: string | null
  phoneNumbers: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_phoneNumbers[]
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  email: string | null
  emailComment: string | null
  preferredLanguage: string | null
  hospitalPatientId: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  incomeSources: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_incomeSources[]
  deductions: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_deductions[]
  assets: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  unreadMessageStatus: boolean | null
  relationships: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships[]
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_personOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person => ({
  ...serialized,
  openApplications: serialized.openApplications.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplications
  ),
  updatedAt: parseDate(serialized.updatedAt),
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_phoneNumbers
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_deductions
  ),
  assets: serialized.assets.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
  relationships: serialized.relationships.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships
  ),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_personOrig => ({
  ...deserialized,
  openApplications: deserialized.openApplications.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_openHouseholdMemberApplications
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_phoneNumbers
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_deductions
  ),
  assets: deserialized.assets.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
  relationships: deserialized.relationships.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person_relationships
  ),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembers_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembers_person = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembers_personOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembers_person => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembers_person = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembers_person
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembers_personOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembers {
  __typename: 'ApplicationHouseholdMember'
  id: string
  person: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembers_person
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembers = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembersOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembers => ({
  ...serialized,
  person: deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembers_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembers = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembers
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembersOrig => ({
  ...deserialized,
  person: serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembers_person(
    deserialized.person
  ),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_files {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_files = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_filesOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_files => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_files = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_files
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_filesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_person = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_personOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_person => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_person = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_person
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_personOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_application {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_application = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_applicationOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_application => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_application = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_application
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_applicationOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents {
  __typename: 'Document'
  id: string
  documentType: string
  complete: boolean | null
  files: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_files[]
  updatedAt: Date
  notes: string | null
  person: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_person | null
  application: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_application
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documentsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents => ({
  ...serialized,
  files: serialized.files.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_files
  ),
  updatedAt: parseDate(serialized.updatedAt),
  person:
    serialized.person != null
      ? deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_person(
          serialized.person
        )
      : serialized.person,
  application: deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_application(
    serialized.application
  ),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documentsOrig => ({
  ...deserialized,
  files: deserialized.files.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_files
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  person:
    deserialized.person != null
      ? serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_person(
          deserialized.person
        )
      : deserialized.person,
  application: serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents_application(
    deserialized.application
  ),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication {
  __typename: 'MonitorApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  submitDate: Date | null
  applicationType: string | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  darSubmitDate: Date | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  primaryPointOfContact: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_primaryPointOfContact | null
  initialDateOfService: Date | null
  person: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person
  householdMembers: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembers[]
  documents: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents[]
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplicationOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  darSubmitDate:
    serialized.darSubmitDate != null
      ? parseDate(serialized.darSubmitDate)
      : serialized.darSubmitDate,
  primaryPointOfContact:
    serialized.primaryPointOfContact != null
      ? deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_primaryPointOfContact(
          serialized.primaryPointOfContact
        )
      : serialized.primaryPointOfContact,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  person: deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person(
    serialized.person
  ),
  householdMembers: serialized.householdMembers.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembers
  ),
  documents: serialized.documents.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents
  ),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  darSubmitDate:
    deserialized.darSubmitDate != null
      ? formatISODate(deserialized.darSubmitDate)
      : deserialized.darSubmitDate,
  primaryPointOfContact:
    deserialized.primaryPointOfContact != null
      ? serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_primaryPointOfContact(
          deserialized.primaryPointOfContact
        )
      : deserialized.primaryPointOfContact,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  person: serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_person(
    deserialized.person
  ),
  householdMembers: deserialized.householdMembers.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_householdMembers
  ),
  documents: deserialized.documents.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication_documents
  ),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplication
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplication
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplication
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications =
  | UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplication
  | UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplication
  | UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplicationsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications'
  )
}
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications'
  )
}
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplications = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplicationsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplications = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplications
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_phoneNumbers = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_phoneNumbersOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_phoneNumbers => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_phoneNumbers = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_phoneNumbers
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_phoneNumbersOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_incomeSources = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_incomeSourcesOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_incomeSources => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_incomeSources = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_incomeSources
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_incomeSourcesOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_deductions = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_deductionsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_deductions => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_deductions = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_deductions
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_deductionsOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_assets = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_assetsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_assets => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_assets = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_assets
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_assetsOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_phoneNumbers = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_phoneNumbersOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_phoneNumbers => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_phoneNumbers = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_phoneNumbers
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_phoneNumbersOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_incomeSources = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_incomeSourcesOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_incomeSources => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_incomeSources = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_incomeSources
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_incomeSourcesOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_deductions = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_deductionsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_deductions => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_deductions = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_deductions
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_deductionsOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_assets = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_assetsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_assets => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_assets = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_assets
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_assetsOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson {
  __typename: 'Person'
  id: string
  updatedAt: Date
  firstName: string | null
  middleName: string | null
  lastName: string | null
  suffix: string | null
  preferredName: string | null
  dob: Date | null
  gender: string | null
  ssn: string | null
  phoneNumbers: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_phoneNumbers[]
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  email: string | null
  emailComment: string | null
  preferredLanguage: string | null
  hospitalPatientId: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  incomeSources: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_incomeSources[]
  deductions: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_deductions[]
  assets: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  unreadMessageStatus: boolean | null
  fieldsUpdatedAt: DeserializedFieldsUpdatedAt | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPersonOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_phoneNumbers
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_deductions
  ),
  assets: serialized.assets.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPersonOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_phoneNumbers
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_deductions
  ),
  assets: deserialized.assets.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships {
  __typename: 'Relationship'
  id: string
  relationshipType: string | null
  otherPerson: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson
  liveTogether: boolean | null
  fieldsUpdatedAt: DeserializedFieldsUpdatedAt | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationshipsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships => ({
  ...serialized,
  otherPerson: deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson(
    serialized.otherPerson
  ),
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationshipsOrig => ({
  ...deserialized,
  otherPerson: serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships_otherPerson(
    deserialized.otherPerson
  ),
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person {
  __typename: 'Person'
  id: string
  openApplications: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications[]
  openHouseholdMemberApplications: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplications[]
  updatedAt: Date
  firstName: string | null
  middleName: string | null
  lastName: string | null
  suffix: string | null
  preferredName: string | null
  dob: Date | null
  gender: string | null
  ssn: string | null
  phoneNumbers: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_phoneNumbers[]
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  email: string | null
  emailComment: string | null
  preferredLanguage: string | null
  hospitalPatientId: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  incomeSources: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_incomeSources[]
  deductions: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_deductions[]
  assets: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  unreadMessageStatus: boolean | null
  relationships: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships[]
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_personOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person => ({
  ...serialized,
  openApplications: serialized.openApplications.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplications
  ),
  updatedAt: parseDate(serialized.updatedAt),
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_phoneNumbers
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_deductions
  ),
  assets: serialized.assets.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
  relationships: serialized.relationships.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships
  ),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_personOrig => ({
  ...deserialized,
  openApplications: deserialized.openApplications.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_openHouseholdMemberApplications
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_phoneNumbers
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_deductions
  ),
  assets: deserialized.assets.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
  relationships: deserialized.relationships.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person_relationships
  ),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembers_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembers_person = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembers_personOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembers_person => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembers_person = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembers_person
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembers_personOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembers {
  __typename: 'ApplicationHouseholdMember'
  id: string
  person: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembers_person
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembers = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembersOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembers => ({
  ...serialized,
  person: deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembers_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembers = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembers
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembersOrig => ({
  ...deserialized,
  person: serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembers_person(
    deserialized.person
  ),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_files {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_files = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_filesOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_files => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_files = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_files
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_filesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_person = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_personOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_person => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_person = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_person
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_personOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_application {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_application = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_applicationOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_application => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_application = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_application
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_applicationOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents {
  __typename: 'Document'
  id: string
  documentType: string
  complete: boolean | null
  files: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_files[]
  updatedAt: Date
  notes: string | null
  person: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_person | null
  application: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_application
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documentsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents => ({
  ...serialized,
  files: serialized.files.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_files
  ),
  updatedAt: parseDate(serialized.updatedAt),
  person:
    serialized.person != null
      ? deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_person(
          serialized.person
        )
      : serialized.person,
  application: deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_application(
    serialized.application
  ),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documentsOrig => ({
  ...deserialized,
  files: deserialized.files.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_files
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  person:
    deserialized.person != null
      ? serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_person(
          deserialized.person
        )
      : deserialized.person,
  application: serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents_application(
    deserialized.application
  ),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  initialDateOfService: Date | null
  dateOfApplication: Date | null
  requestedDateOfApplication: Date | null
  notes: string | null
  facility: string
  coveragePeriod: number | null
  charityCareType: string
  person: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person
  householdMembers: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembers[]
  documents: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents[]
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  dateOfApplication:
    serialized.dateOfApplication != null
      ? parseDate(serialized.dateOfApplication)
      : serialized.dateOfApplication,
  requestedDateOfApplication:
    serialized.requestedDateOfApplication != null
      ? parseDate(serialized.requestedDateOfApplication)
      : serialized.requestedDateOfApplication,
  person: deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person(
    serialized.person
  ),
  householdMembers: serialized.householdMembers.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembers
  ),
  documents: serialized.documents.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents
  ),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  dateOfApplication:
    deserialized.dateOfApplication != null
      ? formatISODate(deserialized.dateOfApplication)
      : deserialized.dateOfApplication,
  requestedDateOfApplication:
    deserialized.requestedDateOfApplication != null
      ? formatISODate(deserialized.requestedDateOfApplication)
      : deserialized.requestedDateOfApplication,
  person: serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_person(
    deserialized.person
  ),
  householdMembers: deserialized.householdMembers.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_householdMembers
  ),
  documents: deserialized.documents.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication_documents
  ),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplication
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplication
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplication
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications =
  | UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplication
  | UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplication
  | UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplicationsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications'
  )
}
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications'
  )
}
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplications = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplicationsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplications = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplications
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_phoneNumbers = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_phoneNumbersOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_phoneNumbers => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_phoneNumbers = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_phoneNumbers
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_phoneNumbersOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_incomeSources = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_incomeSourcesOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_incomeSources => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_incomeSources = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_incomeSources
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_incomeSourcesOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_deductions = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_deductionsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_deductions => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_deductions = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_deductions
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_deductionsOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_assets = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_assetsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_assets => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_assets = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_assets
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_assetsOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_phoneNumbers = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_phoneNumbersOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_phoneNumbers => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_phoneNumbers = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_phoneNumbers
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_phoneNumbersOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_incomeSources = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_incomeSourcesOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_incomeSources => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_incomeSources = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_incomeSources
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_incomeSourcesOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_deductions = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_deductionsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_deductions => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_deductions = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_deductions
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_deductionsOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_assets = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_assetsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_assets => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_assets = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_assets
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_assetsOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson {
  __typename: 'Person'
  id: string
  updatedAt: Date
  firstName: string | null
  middleName: string | null
  lastName: string | null
  suffix: string | null
  preferredName: string | null
  dob: Date | null
  gender: string | null
  ssn: string | null
  phoneNumbers: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_phoneNumbers[]
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  email: string | null
  emailComment: string | null
  preferredLanguage: string | null
  hospitalPatientId: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  incomeSources: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_incomeSources[]
  deductions: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_deductions[]
  assets: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  unreadMessageStatus: boolean | null
  fieldsUpdatedAt: DeserializedFieldsUpdatedAt | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPersonOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_phoneNumbers
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_deductions
  ),
  assets: serialized.assets.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPersonOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_phoneNumbers
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_deductions
  ),
  assets: deserialized.assets.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships {
  __typename: 'Relationship'
  id: string
  relationshipType: string | null
  otherPerson: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson
  liveTogether: boolean | null
  fieldsUpdatedAt: DeserializedFieldsUpdatedAt | null
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationshipsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships => ({
  ...serialized,
  otherPerson: deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson(
    serialized.otherPerson
  ),
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationshipsOrig => ({
  ...deserialized,
  otherPerson: serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships_otherPerson(
    deserialized.otherPerson
  ),
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person {
  __typename: 'Person'
  id: string
  openApplications: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications[]
  openHouseholdMemberApplications: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplications[]
  updatedAt: Date
  firstName: string | null
  middleName: string | null
  lastName: string | null
  suffix: string | null
  preferredName: string | null
  dob: Date | null
  gender: string | null
  ssn: string | null
  phoneNumbers: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_phoneNumbers[]
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  email: string | null
  emailComment: string | null
  preferredLanguage: string | null
  hospitalPatientId: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  incomeSources: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_incomeSources[]
  deductions: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_deductions[]
  assets: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  unreadMessageStatus: boolean | null
  relationships: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships[]
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_personOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person => ({
  ...serialized,
  openApplications: serialized.openApplications.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplications
  ),
  updatedAt: parseDate(serialized.updatedAt),
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_phoneNumbers
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_deductions
  ),
  assets: serialized.assets.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_assets
  ),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
  relationships: serialized.relationships.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships
  ),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_personOrig => ({
  ...deserialized,
  openApplications: deserialized.openApplications.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_openHouseholdMemberApplications
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_phoneNumbers
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_deductions
  ),
  assets: deserialized.assets.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_assets
  ),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
  relationships: deserialized.relationships.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person_relationships
  ),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembers_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembers_person = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembers_personOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembers_person => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembers_person = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembers_person
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembers_personOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembers {
  __typename: 'ApplicationHouseholdMember'
  id: string
  person: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembers_person
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembers = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembersOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembers => ({
  ...serialized,
  person: deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembers_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembers = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembers
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembersOrig => ({
  ...deserialized,
  person: serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembers_person(
    deserialized.person
  ),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_files {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_files = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_filesOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_files => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_files = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_files
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_filesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_person {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_person = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_personOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_person => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_person = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_person
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_personOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_application {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_application = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_applicationOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_application => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_application = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_application
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_applicationOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents {
  __typename: 'Document'
  id: string
  documentType: string
  complete: boolean | null
  files: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_files[]
  updatedAt: Date
  notes: string | null
  person: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_person | null
  application: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_application
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documentsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents => ({
  ...serialized,
  files: serialized.files.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_files
  ),
  updatedAt: parseDate(serialized.updatedAt),
  person:
    serialized.person != null
      ? deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_person(
          serialized.person
        )
      : serialized.person,
  application: deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_application(
    serialized.application
  ),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documentsOrig => ({
  ...deserialized,
  files: deserialized.files.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_files
  ),
  updatedAt: formatISO(deserialized.updatedAt),
  person:
    deserialized.person != null
      ? serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_person(
          deserialized.person
        )
      : deserialized.person,
  application: serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents_application(
    deserialized.application
  ),
})
export interface UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  initialDateOfService: Date | null
  receivedDate: Date | null
  notes: string | null
  facility: string
  person: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person
  householdMembers: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembers[]
  documents: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents[]
}
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  receivedDate:
    serialized.receivedDate != null
      ? parseDate(serialized.receivedDate)
      : serialized.receivedDate,
  person: deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person(
    serialized.person
  ),
  householdMembers: serialized.householdMembers.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembers
  ),
  documents: serialized.documents.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents
  ),
})
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  receivedDate:
    deserialized.receivedDate != null
      ? formatISODate(deserialized.receivedDate)
      : deserialized.receivedDate,
  person: serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_person(
    deserialized.person
  ),
  householdMembers: deserialized.householdMembers.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_householdMembers
  ),
  documents: deserialized.documents.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication_documents
  ),
})
export type UpdatePerson_updatePerson_allOpenHouseholdMemberApplications =
  | UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication
  | UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication
  | UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication
  | UpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications = (
  serialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplicationsOrig
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplications => {
  if (false || serialized.__typename === 'MedicaidApplication')
    return deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'MonitorApplication')
    return deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_allOpenHouseholdMemberApplications'
  )
}
export const serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications = (
  deserialized: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications
): UpdatePerson_updatePerson_allOpenHouseholdMemberApplicationsOrig => {
  if (false || deserialized.__typename === 'MedicaidApplication')
    return serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'MonitorApplication')
    return serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_MonitorApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_allOpenHouseholdMemberApplications'
  )
}
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_MedicaidApplication
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_CharityCareApplication
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_SlideApplication
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications =
  | UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_MedicaidApplication
  | UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_CharityCareApplication
  | UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplicationsOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications'
  )
}
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications'
  )
}
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openHouseholdMemberApplications = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openHouseholdMemberApplicationsOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openHouseholdMemberApplications = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openHouseholdMemberApplications
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person {
  __typename: 'Person'
  id: string
  openApplications: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications[]
  openHouseholdMemberApplications: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openHouseholdMemberApplications[]
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_personOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person => ({
  ...serialized,
  openApplications: serialized.openApplications.map(
    deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openHouseholdMemberApplications
  ),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_personOrig => ({
  ...deserialized,
  openApplications: deserialized.openApplications.map(
    serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person_openHouseholdMemberApplications
  ),
})
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication {
  __typename: 'MedicaidApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  submitDate: Date | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  initialDateOfService: Date | null
  mcdType: string
  person: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  mcdType: deserializeMcdType(serialized.mcdType),
  person: deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  mcdType: serializeMcdType(deserialized.mcdType),
  person: serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication_person(
    deserialized.person
  ),
})
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_primaryPointOfContact {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_primaryPointOfContact = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_primaryPointOfContactOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_primaryPointOfContact => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_primaryPointOfContact = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_primaryPointOfContact
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_primaryPointOfContactOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_MedicaidApplication
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_CharityCareApplication
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_SlideApplication
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications =
  | UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_MedicaidApplication
  | UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_CharityCareApplication
  | UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplicationsOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications'
  )
}
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications'
  )
}
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openHouseholdMemberApplications = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openHouseholdMemberApplicationsOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openHouseholdMemberApplications = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openHouseholdMemberApplications
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person {
  __typename: 'Person'
  id: string
  openApplications: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications[]
  openHouseholdMemberApplications: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openHouseholdMemberApplications[]
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_personOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person => ({
  ...serialized,
  openApplications: serialized.openApplications.map(
    deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openHouseholdMemberApplications
  ),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_personOrig => ({
  ...deserialized,
  openApplications: deserialized.openApplications.map(
    serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person_openHouseholdMemberApplications
  ),
})
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication {
  __typename: 'MonitorApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  submitDate: Date | null
  applicationType: string | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  darSubmitDate: Date | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  primaryPointOfContact: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_primaryPointOfContact | null
  initialDateOfService: Date | null
  person: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplicationOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  darSubmitDate:
    serialized.darSubmitDate != null
      ? parseDate(serialized.darSubmitDate)
      : serialized.darSubmitDate,
  primaryPointOfContact:
    serialized.primaryPointOfContact != null
      ? deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_primaryPointOfContact(
          serialized.primaryPointOfContact
        )
      : serialized.primaryPointOfContact,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  person: deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  darSubmitDate:
    deserialized.darSubmitDate != null
      ? formatISODate(deserialized.darSubmitDate)
      : deserialized.darSubmitDate,
  primaryPointOfContact:
    deserialized.primaryPointOfContact != null
      ? serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_primaryPointOfContact(
          deserialized.primaryPointOfContact
        )
      : deserialized.primaryPointOfContact,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  person: serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication_person(
    deserialized.person
  ),
})
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_MedicaidApplication
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_CharityCareApplication
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_SlideApplication
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications =
  | UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_MedicaidApplication
  | UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_CharityCareApplication
  | UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplicationsOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications'
  )
}
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications'
  )
}
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openHouseholdMemberApplications = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openHouseholdMemberApplicationsOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openHouseholdMemberApplications = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openHouseholdMemberApplications
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person {
  __typename: 'Person'
  id: string
  openApplications: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications[]
  openHouseholdMemberApplications: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openHouseholdMemberApplications[]
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_personOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person => ({
  ...serialized,
  openApplications: serialized.openApplications.map(
    deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openHouseholdMemberApplications
  ),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_personOrig => ({
  ...deserialized,
  openApplications: deserialized.openApplications.map(
    serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person_openHouseholdMemberApplications
  ),
})
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  initialDateOfService: Date | null
  dateOfApplication: Date | null
  requestedDateOfApplication: Date | null
  notes: string | null
  facility: string
  coveragePeriod: number | null
  charityCareType: string
  person: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  dateOfApplication:
    serialized.dateOfApplication != null
      ? parseDate(serialized.dateOfApplication)
      : serialized.dateOfApplication,
  requestedDateOfApplication:
    serialized.requestedDateOfApplication != null
      ? parseDate(serialized.requestedDateOfApplication)
      : serialized.requestedDateOfApplication,
  person: deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  dateOfApplication:
    deserialized.dateOfApplication != null
      ? formatISODate(deserialized.dateOfApplication)
      : deserialized.dateOfApplication,
  requestedDateOfApplication:
    deserialized.requestedDateOfApplication != null
      ? formatISODate(deserialized.requestedDateOfApplication)
      : deserialized.requestedDateOfApplication,
  person: serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication_person(
    deserialized.person
  ),
})
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_MedicaidApplication {
  __typename: 'MedicaidApplication' | 'MonitorApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_MedicaidApplicationOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_MedicaidApplication
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_CharityCareApplicationOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_CharityCareApplication
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  facility: string
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_SlideApplication
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export type UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications =
  | UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_MedicaidApplication
  | UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_CharityCareApplication
  | UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplicationsOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications => {
  if (
    false ||
    serialized.__typename === 'MedicaidApplication' ||
    serialized.__typename === 'MonitorApplication'
  )
    return deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications'
  )
}
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplicationsOrig => {
  if (
    false ||
    deserialized.__typename === 'MedicaidApplication' ||
    deserialized.__typename === 'MonitorApplication'
  )
    return serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications'
  )
}
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openHouseholdMemberApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openHouseholdMemberApplications = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openHouseholdMemberApplicationsOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openHouseholdMemberApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openHouseholdMemberApplications = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openHouseholdMemberApplications
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openHouseholdMemberApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person {
  __typename: 'Person'
  id: string
  openApplications: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications[]
  openHouseholdMemberApplications: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openHouseholdMemberApplications[]
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_personOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person => ({
  ...serialized,
  openApplications: serialized.openApplications.map(
    deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openHouseholdMemberApplications
  ),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_personOrig => ({
  ...deserialized,
  openApplications: deserialized.openApplications.map(
    serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person_openHouseholdMemberApplications
  ),
})
export interface UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  initialDateOfService: Date | null
  receivedDate: Date | null
  notes: string | null
  facility: string
  person: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person
}
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplicationOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  receivedDate:
    serialized.receivedDate != null
      ? parseDate(serialized.receivedDate)
      : serialized.receivedDate,
  person: deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person(
    serialized.person
  ),
})
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  receivedDate:
    deserialized.receivedDate != null
      ? formatISODate(deserialized.receivedDate)
      : deserialized.receivedDate,
  person: serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication_person(
    deserialized.person
  ),
})
export type UpdatePerson_updatePerson_openPrimaryPointOfContactApplications =
  | UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication
  | UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication
  | UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication
  | UpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication
export const deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications = (
  serialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplicationsOrig
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplications => {
  if (false || serialized.__typename === 'MedicaidApplication')
    return deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'MonitorApplication')
    return deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openPrimaryPointOfContactApplications'
  )
}
export const serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications = (
  deserialized: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications
): UpdatePerson_updatePerson_openPrimaryPointOfContactApplicationsOrig => {
  if (false || deserialized.__typename === 'MedicaidApplication')
    return serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'MonitorApplication')
    return serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_MonitorApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_openPrimaryPointOfContactApplications'
  )
}
export interface UpdatePerson_updatePerson_benefitOutcomes_application_MedicaidApplication {
  __typename: 'MedicaidApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  submitDate: Date | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  initialDateOfService: Date | null
  mcdType: string
}
export const deserializeUpdatePerson_updatePerson_benefitOutcomes_application_MedicaidApplication = (
  serialized: UpdatePerson_updatePerson_benefitOutcomes_application_MedicaidApplicationOrig
): UpdatePerson_updatePerson_benefitOutcomes_application_MedicaidApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  mcdType: deserializeMcdType(serialized.mcdType),
})
export const serializeUpdatePerson_updatePerson_benefitOutcomes_application_MedicaidApplication = (
  deserialized: UpdatePerson_updatePerson_benefitOutcomes_application_MedicaidApplication
): UpdatePerson_updatePerson_benefitOutcomes_application_MedicaidApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  mcdType: serializeMcdType(deserialized.mcdType),
})
export interface UpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplication_primaryPointOfContact {
  __typename: 'Person'
  id: string
}
export const deserializeUpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplication_primaryPointOfContact = (
  serialized: UpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplication_primaryPointOfContactOrig
): UpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplication_primaryPointOfContact => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplication_primaryPointOfContact = (
  deserialized: UpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplication_primaryPointOfContact
): UpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplication_primaryPointOfContactOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplication {
  __typename: 'MonitorApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  submitDate: Date | null
  applicationType: string | null
  submitMethod: string | null
  location: string | null
  countyName: string | null
  darSubmitDate: Date | null
  confirmationNumber: string | null
  policyId: string | null
  notes: string | null
  primaryPointOfContact: UpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplication_primaryPointOfContact | null
  initialDateOfService: Date | null
}
export const deserializeUpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplication = (
  serialized: UpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplicationOrig
): UpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  submitDate:
    serialized.submitDate != null
      ? parseDate(serialized.submitDate)
      : serialized.submitDate,
  darSubmitDate:
    serialized.darSubmitDate != null
      ? parseDate(serialized.darSubmitDate)
      : serialized.darSubmitDate,
  primaryPointOfContact:
    serialized.primaryPointOfContact != null
      ? deserializeUpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplication_primaryPointOfContact(
          serialized.primaryPointOfContact
        )
      : serialized.primaryPointOfContact,
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
})
export const serializeUpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplication = (
  deserialized: UpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplication
): UpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  submitDate:
    deserialized.submitDate != null
      ? formatISODate(deserialized.submitDate)
      : deserialized.submitDate,
  darSubmitDate:
    deserialized.darSubmitDate != null
      ? formatISODate(deserialized.darSubmitDate)
      : deserialized.darSubmitDate,
  primaryPointOfContact:
    deserialized.primaryPointOfContact != null
      ? serializeUpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplication_primaryPointOfContact(
          deserialized.primaryPointOfContact
        )
      : deserialized.primaryPointOfContact,
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
})
export interface UpdatePerson_updatePerson_benefitOutcomes_application_CharityCareApplication {
  __typename: 'CharityCareApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  initialDateOfService: Date | null
  dateOfApplication: Date | null
  requestedDateOfApplication: Date | null
  notes: string | null
  facility: string
  coveragePeriod: number | null
  charityCareType: string
}
export const deserializeUpdatePerson_updatePerson_benefitOutcomes_application_CharityCareApplication = (
  serialized: UpdatePerson_updatePerson_benefitOutcomes_application_CharityCareApplicationOrig
): UpdatePerson_updatePerson_benefitOutcomes_application_CharityCareApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  dateOfApplication:
    serialized.dateOfApplication != null
      ? parseDate(serialized.dateOfApplication)
      : serialized.dateOfApplication,
  requestedDateOfApplication:
    serialized.requestedDateOfApplication != null
      ? parseDate(serialized.requestedDateOfApplication)
      : serialized.requestedDateOfApplication,
})
export const serializeUpdatePerson_updatePerson_benefitOutcomes_application_CharityCareApplication = (
  deserialized: UpdatePerson_updatePerson_benefitOutcomes_application_CharityCareApplication
): UpdatePerson_updatePerson_benefitOutcomes_application_CharityCareApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  dateOfApplication:
    deserialized.dateOfApplication != null
      ? formatISODate(deserialized.dateOfApplication)
      : deserialized.dateOfApplication,
  requestedDateOfApplication:
    deserialized.requestedDateOfApplication != null
      ? formatISODate(deserialized.requestedDateOfApplication)
      : deserialized.requestedDateOfApplication,
})
export interface UpdatePerson_updatePerson_benefitOutcomes_application_SlideApplication {
  __typename: 'SlideApplication'
  id: string
  benefit: BenefitType
  status: string
  createdAt: Date
  updatedAt: Date
  isUnclosableDueToMissingBenefitOutcomes: boolean
  accountNumber: string | null
  detailsUpdatedAt: Date
  initialDateOfService: Date | null
  receivedDate: Date | null
  notes: string | null
  facility: string
}
export const deserializeUpdatePerson_updatePerson_benefitOutcomes_application_SlideApplication = (
  serialized: UpdatePerson_updatePerson_benefitOutcomes_application_SlideApplicationOrig
): UpdatePerson_updatePerson_benefitOutcomes_application_SlideApplication => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  detailsUpdatedAt: parseDate(serialized.detailsUpdatedAt),
  initialDateOfService:
    serialized.initialDateOfService != null
      ? parseDate(serialized.initialDateOfService)
      : serialized.initialDateOfService,
  receivedDate:
    serialized.receivedDate != null
      ? parseDate(serialized.receivedDate)
      : serialized.receivedDate,
})
export const serializeUpdatePerson_updatePerson_benefitOutcomes_application_SlideApplication = (
  deserialized: UpdatePerson_updatePerson_benefitOutcomes_application_SlideApplication
): UpdatePerson_updatePerson_benefitOutcomes_application_SlideApplicationOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  detailsUpdatedAt: formatISO(deserialized.detailsUpdatedAt),
  initialDateOfService:
    deserialized.initialDateOfService != null
      ? formatISODate(deserialized.initialDateOfService)
      : deserialized.initialDateOfService,
  receivedDate:
    deserialized.receivedDate != null
      ? formatISODate(deserialized.receivedDate)
      : deserialized.receivedDate,
})
export type UpdatePerson_updatePerson_benefitOutcomes_application =
  | UpdatePerson_updatePerson_benefitOutcomes_application_MedicaidApplication
  | UpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplication
  | UpdatePerson_updatePerson_benefitOutcomes_application_CharityCareApplication
  | UpdatePerson_updatePerson_benefitOutcomes_application_SlideApplication
export const deserializeUpdatePerson_updatePerson_benefitOutcomes_application = (
  serialized: UpdatePerson_updatePerson_benefitOutcomes_applicationOrig
): UpdatePerson_updatePerson_benefitOutcomes_application => {
  if (false || serialized.__typename === 'MedicaidApplication')
    return deserializeUpdatePerson_updatePerson_benefitOutcomes_application_MedicaidApplication(
      serialized
    )
  if (false || serialized.__typename === 'MonitorApplication')
    return deserializeUpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplication(
      serialized
    )
  if (false || serialized.__typename === 'CharityCareApplication')
    return deserializeUpdatePerson_updatePerson_benefitOutcomes_application_CharityCareApplication(
      serialized
    )
  if (false || serialized.__typename === 'SlideApplication')
    return deserializeUpdatePerson_updatePerson_benefitOutcomes_application_SlideApplication(
      serialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      serialized && (serialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_benefitOutcomes_application'
  )
}
export const serializeUpdatePerson_updatePerson_benefitOutcomes_application = (
  deserialized: UpdatePerson_updatePerson_benefitOutcomes_application
): UpdatePerson_updatePerson_benefitOutcomes_applicationOrig => {
  if (false || deserialized.__typename === 'MedicaidApplication')
    return serializeUpdatePerson_updatePerson_benefitOutcomes_application_MedicaidApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'MonitorApplication')
    return serializeUpdatePerson_updatePerson_benefitOutcomes_application_MonitorApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'CharityCareApplication')
    return serializeUpdatePerson_updatePerson_benefitOutcomes_application_CharityCareApplication(
      deserialized
    )
  if (false || deserialized.__typename === 'SlideApplication')
    return serializeUpdatePerson_updatePerson_benefitOutcomes_application_SlideApplication(
      deserialized
    )
  throw new TypeError(
    `Unexpected GraphQL union type ${
      deserialized && (deserialized as any).__typename
    } when trying to resolve ` +
      'UpdatePerson_updatePerson_benefitOutcomes_application'
  )
}
export interface UpdatePerson_updatePerson_benefitOutcomes {
  __typename: 'BenefitOutcome'
  id: string
  benefit: string
  coveredFacility: string
  outcome: string
  outcomeDate: Date | null
  deniedIneligibleReason: string | null
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
  notes: string | null
  patientResponsibility: number | null
  createdAt: Date
  updatedAt: Date
  application: UpdatePerson_updatePerson_benefitOutcomes_application | null
}
export const deserializeUpdatePerson_updatePerson_benefitOutcomes = (
  serialized: UpdatePerson_updatePerson_benefitOutcomesOrig
): UpdatePerson_updatePerson_benefitOutcomes => ({
  ...serialized,
  outcomeDate:
    serialized.outcomeDate != null
      ? parseDate(serialized.outcomeDate)
      : serialized.outcomeDate,
  effectiveStartDate: parseDate(serialized.effectiveStartDate),
  effectiveEndDate: parseDate(serialized.effectiveEndDate),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  application:
    serialized.application != null
      ? deserializeUpdatePerson_updatePerson_benefitOutcomes_application(
          serialized.application
        )
      : serialized.application,
})
export const serializeUpdatePerson_updatePerson_benefitOutcomes = (
  deserialized: UpdatePerson_updatePerson_benefitOutcomes
): UpdatePerson_updatePerson_benefitOutcomesOrig => ({
  ...deserialized,
  outcomeDate:
    deserialized.outcomeDate != null
      ? formatISODate(deserialized.outcomeDate)
      : deserialized.outcomeDate,
  effectiveStartDate: formatISODate(deserialized.effectiveStartDate),
  effectiveEndDate: formatISODate(deserialized.effectiveEndDate),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  application:
    deserialized.application != null
      ? serializeUpdatePerson_updatePerson_benefitOutcomes_application(
          deserialized.application
        )
      : deserialized.application,
})
export interface UpdatePerson_updatePerson_documentFiles_documents {
  __typename: 'Document'
  id: string
}
export const deserializeUpdatePerson_updatePerson_documentFiles_documents = (
  serialized: UpdatePerson_updatePerson_documentFiles_documentsOrig
): UpdatePerson_updatePerson_documentFiles_documents => ({...serialized})
export const serializeUpdatePerson_updatePerson_documentFiles_documents = (
  deserialized: UpdatePerson_updatePerson_documentFiles_documents
): UpdatePerson_updatePerson_documentFiles_documentsOrig => ({...deserialized})
export interface UpdatePerson_updatePerson_documentFiles {
  __typename: 'DocumentFile'
  id: string
  name: string
  fileKey: string
  createdAt: Date
  documents: UpdatePerson_updatePerson_documentFiles_documents[]
}
export const deserializeUpdatePerson_updatePerson_documentFiles = (
  serialized: UpdatePerson_updatePerson_documentFilesOrig
): UpdatePerson_updatePerson_documentFiles => ({
  ...serialized,
  createdAt: parseDate(serialized.createdAt),
  documents: serialized.documents.map(
    deserializeUpdatePerson_updatePerson_documentFiles_documents
  ),
})
export const serializeUpdatePerson_updatePerson_documentFiles = (
  deserialized: UpdatePerson_updatePerson_documentFiles
): UpdatePerson_updatePerson_documentFilesOrig => ({
  ...deserialized,
  createdAt: formatISO(deserialized.createdAt),
  documents: deserialized.documents.map(
    serializeUpdatePerson_updatePerson_documentFiles_documents
  ),
})
export interface UpdatePerson_updatePerson_accounts {
  __typename: 'Account'
  id: string
  hospitalAccountId: string | null
  dateOfService: Date
  timeOfService: string | null
  serviceTimeZone: string
  facility: string
  department: string
  location: string | null
  insuranceType: string
  status: string
  createdAt: Date
  updatedAt: Date
  notes: string | null
  accountAmount: number | null
  njmmisCheck: string | null
  previousInsurance: string | null
  invoiceMonth: string | null
  invoiceYear: string | null
  dischargeDate: Date | null
  isScheduled: boolean
}
export const deserializeUpdatePerson_updatePerson_accounts = (
  serialized: UpdatePerson_updatePerson_accountsOrig
): UpdatePerson_updatePerson_accounts => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  njmmisCheck:
    serialized.njmmisCheck != null
      ? deserializeNjmmisCheckResult(serialized.njmmisCheck)
      : serialized.njmmisCheck,
  dischargeDate:
    serialized.dischargeDate != null
      ? parseDate(serialized.dischargeDate)
      : serialized.dischargeDate,
})
export const serializeUpdatePerson_updatePerson_accounts = (
  deserialized: UpdatePerson_updatePerson_accounts
): UpdatePerson_updatePerson_accountsOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  njmmisCheck:
    deserialized.njmmisCheck != null
      ? serializeNjmmisCheckResult(deserialized.njmmisCheck)
      : deserialized.njmmisCheck,
  dischargeDate:
    deserialized.dischargeDate != null
      ? formatISODate(deserialized.dischargeDate)
      : deserialized.dischargeDate,
})
export interface UpdatePerson_updatePerson_tasks_assignedTo {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeUpdatePerson_updatePerson_tasks_assignedTo = (
  serialized: UpdatePerson_updatePerson_tasks_assignedToOrig
): UpdatePerson_updatePerson_tasks_assignedTo => ({...serialized})
export const serializeUpdatePerson_updatePerson_tasks_assignedTo = (
  deserialized: UpdatePerson_updatePerson_tasks_assignedTo
): UpdatePerson_updatePerson_tasks_assignedToOrig => ({...deserialized})
export interface UpdatePerson_updatePerson_tasks_createdBy {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeUpdatePerson_updatePerson_tasks_createdBy = (
  serialized: UpdatePerson_updatePerson_tasks_createdByOrig
): UpdatePerson_updatePerson_tasks_createdBy => ({...serialized})
export const serializeUpdatePerson_updatePerson_tasks_createdBy = (
  deserialized: UpdatePerson_updatePerson_tasks_createdBy
): UpdatePerson_updatePerson_tasks_createdByOrig => ({...deserialized})
export interface UpdatePerson_updatePerson_tasks_linkedTo {
  __typename: 'Person'
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  preferredName: string | null
  suffix: string | null
}
export const deserializeUpdatePerson_updatePerson_tasks_linkedTo = (
  serialized: UpdatePerson_updatePerson_tasks_linkedToOrig
): UpdatePerson_updatePerson_tasks_linkedTo => ({...serialized})
export const serializeUpdatePerson_updatePerson_tasks_linkedTo = (
  deserialized: UpdatePerson_updatePerson_tasks_linkedTo
): UpdatePerson_updatePerson_tasks_linkedToOrig => ({...deserialized})
export interface UpdatePerson_updatePerson_tasks {
  __typename: 'Task'
  id: string
  taskType: string
  assignedTo: UpdatePerson_updatePerson_tasks_assignedTo
  createdBy: UpdatePerson_updatePerson_tasks_createdBy
  status: string
  note: string | null
  durationMin: number | null
  dueDate: Date
  linkedTo: UpdatePerson_updatePerson_tasks_linkedTo
  updatedAt: Date
}
export const deserializeUpdatePerson_updatePerson_tasks = (
  serialized: UpdatePerson_updatePerson_tasksOrig
): UpdatePerson_updatePerson_tasks => ({
  ...serialized,
  assignedTo: deserializeUpdatePerson_updatePerson_tasks_assignedTo(
    serialized.assignedTo
  ),
  createdBy: deserializeUpdatePerson_updatePerson_tasks_createdBy(
    serialized.createdBy
  ),
  dueDate: parseDate(serialized.dueDate),
  linkedTo: deserializeUpdatePerson_updatePerson_tasks_linkedTo(
    serialized.linkedTo
  ),
  updatedAt: parseDate(serialized.updatedAt),
})
export const serializeUpdatePerson_updatePerson_tasks = (
  deserialized: UpdatePerson_updatePerson_tasks
): UpdatePerson_updatePerson_tasksOrig => ({
  ...deserialized,
  assignedTo: serializeUpdatePerson_updatePerson_tasks_assignedTo(
    deserialized.assignedTo
  ),
  createdBy: serializeUpdatePerson_updatePerson_tasks_createdBy(
    deserialized.createdBy
  ),
  dueDate: formatISODate(deserialized.dueDate),
  linkedTo: serializeUpdatePerson_updatePerson_tasks_linkedTo(
    deserialized.linkedTo
  ),
  updatedAt: formatISO(deserialized.updatedAt),
})
export interface UpdatePerson_updatePerson_webformMergeAttempts_receivedWebform_webform {
  __typename: 'Webform'
  id: string
  name: string
  slug: string
}
export const deserializeUpdatePerson_updatePerson_webformMergeAttempts_receivedWebform_webform = (
  serialized: UpdatePerson_updatePerson_webformMergeAttempts_receivedWebform_webformOrig
): UpdatePerson_updatePerson_webformMergeAttempts_receivedWebform_webform => ({
  ...serialized,
})
export const serializeUpdatePerson_updatePerson_webformMergeAttempts_receivedWebform_webform = (
  deserialized: UpdatePerson_updatePerson_webformMergeAttempts_receivedWebform_webform
): UpdatePerson_updatePerson_webformMergeAttempts_receivedWebform_webformOrig => ({
  ...deserialized,
})
export interface UpdatePerson_updatePerson_webformMergeAttempts_receivedWebform {
  __typename: 'ReceivedWebform'
  id: string
  completedAt: Date
  response: ReceivedWebformResponse
  noPersonMatch: boolean
  createdAt: Date
  webform: UpdatePerson_updatePerson_webformMergeAttempts_receivedWebform_webform
}
export const deserializeUpdatePerson_updatePerson_webformMergeAttempts_receivedWebform = (
  serialized: UpdatePerson_updatePerson_webformMergeAttempts_receivedWebformOrig
): UpdatePerson_updatePerson_webformMergeAttempts_receivedWebform => ({
  ...serialized,
  completedAt: parseDate(serialized.completedAt),
  response: parseReceivedWebformResponse(serialized.response),
  createdAt: parseDate(serialized.createdAt),
  webform: deserializeUpdatePerson_updatePerson_webformMergeAttempts_receivedWebform_webform(
    serialized.webform
  ),
})
export const serializeUpdatePerson_updatePerson_webformMergeAttempts_receivedWebform = (
  deserialized: UpdatePerson_updatePerson_webformMergeAttempts_receivedWebform
): UpdatePerson_updatePerson_webformMergeAttempts_receivedWebformOrig => ({
  ...deserialized,
  completedAt: formatISO(deserialized.completedAt),
  response: serializeReceivedWebformResponse(deserialized.response),
  createdAt: formatISO(deserialized.createdAt),
  webform: serializeUpdatePerson_updatePerson_webformMergeAttempts_receivedWebform_webform(
    deserialized.webform
  ),
})
export interface UpdatePerson_updatePerson_webformMergeAttempts {
  __typename: 'WebformMergeAttempt'
  id: string
  reviewed: boolean
  mergeResult: WebformMergeResult
  receivedWebform: UpdatePerson_updatePerson_webformMergeAttempts_receivedWebform
}
export const deserializeUpdatePerson_updatePerson_webformMergeAttempts = (
  serialized: UpdatePerson_updatePerson_webformMergeAttemptsOrig
): UpdatePerson_updatePerson_webformMergeAttempts => ({
  ...serialized,
  mergeResult: parseWebformMergeResult(serialized.mergeResult),
  receivedWebform: deserializeUpdatePerson_updatePerson_webformMergeAttempts_receivedWebform(
    serialized.receivedWebform
  ),
})
export const serializeUpdatePerson_updatePerson_webformMergeAttempts = (
  deserialized: UpdatePerson_updatePerson_webformMergeAttempts
): UpdatePerson_updatePerson_webformMergeAttemptsOrig => ({
  ...deserialized,
  mergeResult: serializeWebformMergeResult(deserialized.mergeResult),
  receivedWebform: serializeUpdatePerson_updatePerson_webformMergeAttempts_receivedWebform(
    deserialized.receivedWebform
  ),
})
export interface UpdatePerson_updatePerson_mostRecentAccount {
  __typename: 'Account'
  id: string
  hospitalAccountId: string | null
  dateOfService: Date
  timeOfService: string | null
  serviceTimeZone: string
  facility: string
  department: string
  location: string | null
  insuranceType: string
  status: string
  createdAt: Date
  updatedAt: Date
  notes: string | null
  accountAmount: number | null
  njmmisCheck: string | null
  previousInsurance: string | null
  invoiceMonth: string | null
  invoiceYear: string | null
  dischargeDate: Date | null
  isScheduled: boolean
}
export const deserializeUpdatePerson_updatePerson_mostRecentAccount = (
  serialized: UpdatePerson_updatePerson_mostRecentAccountOrig
): UpdatePerson_updatePerson_mostRecentAccount => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  njmmisCheck:
    serialized.njmmisCheck != null
      ? deserializeNjmmisCheckResult(serialized.njmmisCheck)
      : serialized.njmmisCheck,
  dischargeDate:
    serialized.dischargeDate != null
      ? parseDate(serialized.dischargeDate)
      : serialized.dischargeDate,
})
export const serializeUpdatePerson_updatePerson_mostRecentAccount = (
  deserialized: UpdatePerson_updatePerson_mostRecentAccount
): UpdatePerson_updatePerson_mostRecentAccountOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  njmmisCheck:
    deserialized.njmmisCheck != null
      ? serializeNjmmisCheckResult(deserialized.njmmisCheck)
      : deserialized.njmmisCheck,
  dischargeDate:
    deserialized.dischargeDate != null
      ? formatISODate(deserialized.dischargeDate)
      : deserialized.dischargeDate,
})
export interface UpdatePerson_updatePerson_mostRecentEligibilityDeterminations {
  __typename: 'EligibilityDetermination'
  id: string
  benefit: string
  status: string
  reasonOrInfo: string | null
  secondaryInfo: string | null
  householdSizeDescription: string | null
  month: Date | null
  numMonths: number | null
  createdAt: Date
  additionalData: AdditionalData | null
}
export const deserializeUpdatePerson_updatePerson_mostRecentEligibilityDeterminations = (
  serialized: UpdatePerson_updatePerson_mostRecentEligibilityDeterminationsOrig
): UpdatePerson_updatePerson_mostRecentEligibilityDeterminations => ({
  ...serialized,
  month:
    serialized.month != null ? parseDate(serialized.month) : serialized.month,
  createdAt: parseDate(serialized.createdAt),
  additionalData:
    serialized.additionalData != null
      ? parseAdditionalData(serialized.additionalData)
      : serialized.additionalData,
})
export const serializeUpdatePerson_updatePerson_mostRecentEligibilityDeterminations = (
  deserialized: UpdatePerson_updatePerson_mostRecentEligibilityDeterminations
): UpdatePerson_updatePerson_mostRecentEligibilityDeterminationsOrig => ({
  ...deserialized,
  month:
    deserialized.month != null
      ? formatISODate(deserialized.month)
      : deserialized.month,
  createdAt: formatISO(deserialized.createdAt),
  additionalData:
    deserialized.additionalData != null
      ? serializeAdditionalData(deserialized.additionalData)
      : deserialized.additionalData,
})
export interface UpdatePerson_updatePerson {
  __typename: 'Person'
  id: string
  updatedAt: Date
  firstName: string | null
  middleName: string | null
  lastName: string | null
  suffix: string | null
  preferredName: string | null
  dob: Date | null
  gender: string | null
  ssn: string | null
  phoneNumbers: UpdatePerson_updatePerson_phoneNumbers[]
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  email: string | null
  emailComment: string | null
  preferredLanguage: string | null
  hospitalPatientId: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  incomeSources: UpdatePerson_updatePerson_incomeSources[]
  deductions: UpdatePerson_updatePerson_deductions[]
  assets: UpdatePerson_updatePerson_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  unreadMessageStatus: boolean | null
  triages: UpdatePerson_updatePerson_triages[]
  openTriages: UpdatePerson_updatePerson_openTriages[]
  relationships: UpdatePerson_updatePerson_relationships[]
  allApplications: UpdatePerson_updatePerson_allApplications[]
  openApplications: UpdatePerson_updatePerson_openApplications[]
  openHouseholdMemberApplications: UpdatePerson_updatePerson_openHouseholdMemberApplications[]
  allOpenHouseholdMemberApplications: UpdatePerson_updatePerson_allOpenHouseholdMemberApplications[]
  openPrimaryPointOfContactApplications: UpdatePerson_updatePerson_openPrimaryPointOfContactApplications[]
  benefitOutcomes: UpdatePerson_updatePerson_benefitOutcomes[]
  documentFiles: UpdatePerson_updatePerson_documentFiles[]
  accounts: UpdatePerson_updatePerson_accounts[]
  tasks: UpdatePerson_updatePerson_tasks[]
  fieldsUpdatedAt: DeserializedFieldsUpdatedAt | null
  webformMergeAttempts: UpdatePerson_updatePerson_webformMergeAttempts[]
  mostRecentAccount: UpdatePerson_updatePerson_mostRecentAccount | null
  mostRecentEligibilityDeterminations: UpdatePerson_updatePerson_mostRecentEligibilityDeterminations[]
}
export const deserializeUpdatePerson_updatePerson = (
  serialized: UpdatePerson_updatePersonOrig
): UpdatePerson_updatePerson => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializeUpdatePerson_updatePerson_phoneNumbers
  ),
  incomeSources: serialized.incomeSources.map(
    deserializeUpdatePerson_updatePerson_incomeSources
  ),
  deductions: serialized.deductions.map(
    deserializeUpdatePerson_updatePerson_deductions
  ),
  assets: serialized.assets.map(deserializeUpdatePerson_updatePerson_assets),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
  triages: serialized.triages.map(deserializeUpdatePerson_updatePerson_triages),
  openTriages: serialized.openTriages.map(
    deserializeUpdatePerson_updatePerson_openTriages
  ),
  relationships: serialized.relationships.map(
    deserializeUpdatePerson_updatePerson_relationships
  ),
  allApplications: serialized.allApplications.map(
    deserializeUpdatePerson_updatePerson_allApplications
  ),
  openApplications: serialized.openApplications.map(
    deserializeUpdatePerson_updatePerson_openApplications
  ),
  openHouseholdMemberApplications: serialized.openHouseholdMemberApplications.map(
    deserializeUpdatePerson_updatePerson_openHouseholdMemberApplications
  ),
  allOpenHouseholdMemberApplications: serialized.allOpenHouseholdMemberApplications.map(
    deserializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications
  ),
  openPrimaryPointOfContactApplications: serialized.openPrimaryPointOfContactApplications.map(
    deserializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications
  ),
  benefitOutcomes: serialized.benefitOutcomes.map(
    deserializeUpdatePerson_updatePerson_benefitOutcomes
  ),
  documentFiles: serialized.documentFiles.map(
    deserializeUpdatePerson_updatePerson_documentFiles
  ),
  accounts: serialized.accounts.map(
    deserializeUpdatePerson_updatePerson_accounts
  ),
  tasks: serialized.tasks.map(deserializeUpdatePerson_updatePerson_tasks),
  fieldsUpdatedAt:
    serialized.fieldsUpdatedAt != null
      ? parseFieldsUpdatedAt(serialized.fieldsUpdatedAt)
      : serialized.fieldsUpdatedAt,
  webformMergeAttempts: serialized.webformMergeAttempts.map(
    deserializeUpdatePerson_updatePerson_webformMergeAttempts
  ),
  mostRecentAccount:
    serialized.mostRecentAccount != null
      ? deserializeUpdatePerson_updatePerson_mostRecentAccount(
          serialized.mostRecentAccount
        )
      : serialized.mostRecentAccount,
  mostRecentEligibilityDeterminations: serialized.mostRecentEligibilityDeterminations.map(
    deserializeUpdatePerson_updatePerson_mostRecentEligibilityDeterminations
  ),
})
export const serializeUpdatePerson_updatePerson = (
  deserialized: UpdatePerson_updatePerson
): UpdatePerson_updatePersonOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializeUpdatePerson_updatePerson_phoneNumbers
  ),
  incomeSources: deserialized.incomeSources.map(
    serializeUpdatePerson_updatePerson_incomeSources
  ),
  deductions: deserialized.deductions.map(
    serializeUpdatePerson_updatePerson_deductions
  ),
  assets: deserialized.assets.map(serializeUpdatePerson_updatePerson_assets),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
  triages: deserialized.triages.map(serializeUpdatePerson_updatePerson_triages),
  openTriages: deserialized.openTriages.map(
    serializeUpdatePerson_updatePerson_openTriages
  ),
  relationships: deserialized.relationships.map(
    serializeUpdatePerson_updatePerson_relationships
  ),
  allApplications: deserialized.allApplications.map(
    serializeUpdatePerson_updatePerson_allApplications
  ),
  openApplications: deserialized.openApplications.map(
    serializeUpdatePerson_updatePerson_openApplications
  ),
  openHouseholdMemberApplications: deserialized.openHouseholdMemberApplications.map(
    serializeUpdatePerson_updatePerson_openHouseholdMemberApplications
  ),
  allOpenHouseholdMemberApplications: deserialized.allOpenHouseholdMemberApplications.map(
    serializeUpdatePerson_updatePerson_allOpenHouseholdMemberApplications
  ),
  openPrimaryPointOfContactApplications: deserialized.openPrimaryPointOfContactApplications.map(
    serializeUpdatePerson_updatePerson_openPrimaryPointOfContactApplications
  ),
  benefitOutcomes: deserialized.benefitOutcomes.map(
    serializeUpdatePerson_updatePerson_benefitOutcomes
  ),
  documentFiles: deserialized.documentFiles.map(
    serializeUpdatePerson_updatePerson_documentFiles
  ),
  accounts: deserialized.accounts.map(
    serializeUpdatePerson_updatePerson_accounts
  ),
  tasks: deserialized.tasks.map(serializeUpdatePerson_updatePerson_tasks),
  fieldsUpdatedAt:
    deserialized.fieldsUpdatedAt != null
      ? serializeFieldsUpdatedAt(deserialized.fieldsUpdatedAt)
      : deserialized.fieldsUpdatedAt,
  webformMergeAttempts: deserialized.webformMergeAttempts.map(
    serializeUpdatePerson_updatePerson_webformMergeAttempts
  ),
  mostRecentAccount:
    deserialized.mostRecentAccount != null
      ? serializeUpdatePerson_updatePerson_mostRecentAccount(
          deserialized.mostRecentAccount
        )
      : deserialized.mostRecentAccount,
  mostRecentEligibilityDeterminations: deserialized.mostRecentEligibilityDeterminations.map(
    serializeUpdatePerson_updatePerson_mostRecentEligibilityDeterminations
  ),
})
export interface UpdatePerson {
  /**
   * Update person
   */
  updatePerson: UpdatePerson_updatePerson
}
export const deserializeUpdatePerson = (
  serialized: UpdatePersonOrig
): UpdatePerson => ({
  ...serialized,
  updatePerson: deserializeUpdatePerson_updatePerson(serialized.updatePerson),
})
export const serializeUpdatePerson = (
  deserialized: UpdatePerson
): UpdatePersonOrig => ({
  ...deserialized,
  updatePerson: serializeUpdatePerson_updatePerson(deserialized.updatePerson),
})
export interface UpdatePersonVariables {
  person: UpdatePersonInput
}
export const deserializeUpdatePersonVariables = (
  serialized: UpdatePersonVariablesOrig
): UpdatePersonVariables => ({
  ...serialized,
  person: deserializeUpdatePersonInput(serialized.person),
})
export const serializeUpdatePersonVariables = (
  deserialized: UpdatePersonVariables
): UpdatePersonVariablesOrig => ({
  ...deserialized,
  person: serializeUpdatePersonInput(deserialized.person),
})
