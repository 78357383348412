import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps, addDefaultProps} from 'ad-hok'
import {isDate} from 'lodash/fp'
import {format} from 'date-fns/fp'
import subMonths from 'date-fns/subMonths'
import {TFunction} from 'i18next'
import {declarePropTypesNarrowing} from 'ad-hok-utils'

import addRouteParams from 'utils/addRouteParams'
import {addApplicationForViewQuery} from 'graphql/generated'
import {addLoadingIndicator} from 'utils/dataLoading'
import {addTranslationHelpers} from 'utils/i18n'
import Heading from 'components/Heading'
import {getExtendedName} from 'utils/name'
import {makeClasses, addClasses} from 'theme'
import Body1 from 'components/Body1'
import {getFormattedPhoneNumber} from 'utils/phone'
import {
  CI_STATUS_US_CITIZEN,
  TAX_FILING_STATUS_NOT_FILING,
  TAX_FILING_STATUS_DEPENDENT,
  TAX_FILING_STATUS_FILING_JOINTLY,
  RELATIONSHIP_TYPE_SPOUSE,
  WHO_SUPPORT_WITHOUT_INCOME_SELF,
  WHO_SUPPORT_WITHOUT_INCOME_OTHER,
} from 'components/EditPersonForm/schema'
import {
  includesMonth,
  getDateFromMonthAndYear,
  getAmountForMonth,
} from 'utils/income'
import {formatCurrency} from 'utils/number'
import {getFormattedSsn} from 'utils/ssn'
import {
  ApplicationForView_application,
  ApplicationForView_application_MedicaidApplication_householdMembers_person,
  ApplicationForView_application_MedicaidApplication,
} from 'graphql/deserializedTypes/ApplicationForView'

const classes = makeClasses((theme) => ({
  container: {
    margin: theme.spacing(2),
  },
  header: {
    fontSize: 28,
    marginBottom: theme.spacing(4),
  },
  itemValue: {
    fontWeight: 'bold',
  },
  sectionHeader: {
    fontSize: 20,
    marginBottom: theme.spacing(1),
  },
  subsectionHeader: {
    fontSize: 18,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    textTransform: 'uppercase',
  },
  itemLabel: {
    fontWeight: 400,
  },
  itemContainer: {
    marginBottom: theme.spacing(0.5),
  },
  sectionContainer: {
    marginBottom: theme.spacing(4),
  },
  incomeSourceSubheader: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
}))

const ItemValue: FC = flowMax(
  addDisplayName('ItemValue'),
  addClasses(classes),
  ({classes, children}) => (
    <div>
      <Body1 className={classes.itemValue}>{children}</Body1>
    </div>
  )
)

interface ItemProps {
  label: string
  value?: string | Date | number | boolean | null
  dateFormat?: string
}

const Item: FC<ItemProps> = flowMax(
  addDisplayName('Item'),
  addDefaultProps({
    dateFormat: 'M/d/yy',
  }),
  addProps(({value, dateFormat}) => ({
    valueFormatted: isDate(value)
      ? format(dateFormat)(value)
      : value === true
      ? 'Yes'
      : value === false
      ? 'No'
      : value,
  })),
  addClasses(classes),
  ({label, valueFormatted, classes, children}) => (
    <div className={classes.itemContainer}>
      <div className={classes.itemLabel}>{label}</div>
      {children ?? <ItemValue>{valueFormatted}</ItemValue>}
    </div>
  )
)

interface SectionProps {
  label: string
}

const Section: FC<SectionProps> = flowMax(
  addDisplayName('Section'),
  addClasses(classes),
  ({label, children, classes}) => (
    <div className={classes.sectionContainer}>
      <Heading component="h4" className={classes.sectionHeader}>
        {label}
      </Heading>
      {children}
    </div>
  )
)

const getRelationshipToTaxFiler = ({
  person,
  application,
  dependentOfTaxFiler,
}: {
  person: ApplicationForView_application_MedicaidApplication_householdMembers_person
  application: ApplicationForView_application
  dependentOfTaxFiler:
    | ApplicationForView_application_MedicaidApplication_householdMembers_person
    | null
    | undefined
}): string | null | undefined => {
  if (!dependentOfTaxFiler) return
  if (dependentOfTaxFiler.id === application.person.id) {
    const relationshipToClient = application.person.relationships.find(
      ({otherPerson}) => otherPerson.id === person.id
    )
    if (!relationshipToClient) return
    return relationshipToClient.relationshipType
  }
  if (person.id === application.person.id) {
    const relationshipToTaxFiler = application.person.relationships.find(
      ({otherPerson}) => otherPerson.id === dependentOfTaxFiler.id
    )
    if (!relationshipToTaxFiler) return
    return relationshipToTaxFiler.reverseRelationshipType
  }
}

const getNameOfSpouseFilingJointly = ({
  person: {taxFilingStatus},
  person,
  application: {
    person: {relationships},
  },
  application,
  t,
}: {
  person: ApplicationForView_application_MedicaidApplication_householdMembers_person
  application: ApplicationForView_application
  t: TFunction
}): string | undefined => {
  if (taxFilingStatus !== TAX_FILING_STATUS_FILING_JOINTLY) return
  const isClient = person.id === application.person.id
  if (isClient) {
    const spouseRelationship = relationships.find(
      ({relationshipType}) => relationshipType === RELATIONSHIP_TYPE_SPOUSE
    )
    if (!spouseRelationship) return
    return getExtendedName({...spouseRelationship.otherPerson, t})
  }
  const isSpouseOfClient =
    relationships.find(
      ({relationshipType}) => relationshipType === RELATIONSHIP_TYPE_SPOUSE
    )?.otherPerson.id === person.id
  if (!isSpouseOfClient) return
  return getExtendedName({...application.person, t})
}

const SubsectionHeader: FC = flowMax(
  addDisplayName('SubsectionHeader'),
  addClasses(classes),
  ({children, classes}) => (
    <Heading component="h5" className={classes.subsectionHeader}>
      {children}
    </Heading>
  )
)

interface IncomeInformationSectionProps {
  person: ApplicationForView_application_MedicaidApplication_householdMembers_person
  application: ApplicationForView_application
}

const IncomeInformationSection: FC<IncomeInformationSectionProps> = flowMax(
  addDisplayName('IncomeInformationSection'),
  addProps(
    () => ({
      thisMonth: new Date(),
    }),
    []
  ),
  addProps(
    ({thisMonth}) => ({
      lastMonth: subMonths(thisMonth, 1),
    }),
    ['thisMonth']
  ),
  addProps(
    ({person: {incomeSources, deductions}, thisMonth, lastMonth}) => ({
      incomeSources: incomeSources.filter(
        (incomeSource) =>
          includesMonth(thisMonth)(incomeSource) ||
          includesMonth(lastMonth)(incomeSource)
      ),
      deductions: deductions.filter(
        (deduction) =>
          includesMonth(thisMonth)(deduction) ||
          includesMonth(lastMonth)(deduction)
      ),
    }),
    ['person', 'thisMonth', 'lastMonth']
  ),
  addProps(
    ({person: {dependentOfTaxFilerId}, application: {householdMembers}}) => ({
      dependentOfTaxFiler: dependentOfTaxFilerId
        ? householdMembers.find(
            ({person: {id: personId}}) => personId === dependentOfTaxFilerId
          )?.person
        : null,
    }),
    ['person', 'application']
  ),
  addProps(
    ({application, dependentOfTaxFiler, person}) => ({
      relationshipToTaxFiler: getRelationshipToTaxFiler({
        dependentOfTaxFiler,
        application,
        person,
      }),
    }),
    ['application', 'dependentOfTaxFiler', 'person']
  ),
  addTranslationHelpers,
  addProps(
    ({person, application: {householdMembers}, application, t}) => ({
      nameOfSpouseFilingJointly: getNameOfSpouseFilingJointly({
        person,
        application,
        t,
      }),
      dependents: householdMembers
        .filter(
          ({person: householdMember}) =>
            householdMember.dependentOfTaxFilerId === person.id
        )
        .map(({person}) => person),
    }),
    ['person', 'application', 't']
  ),
  addClasses(classes),
  ({
    person: {changeJobInLast6Mon, taxFilingStatus},
    person,
    incomeSources,
    deductions,
    thisMonth,
    lastMonth,
    dependentOfTaxFiler,
    relationshipToTaxFiler,
    nameOfSpouseFilingJointly,
    dependents,
    classes,
    t,
  }) => (
    <Section label={`Income Information - ${getExtendedName({...person, t})}`}>
      {incomeSources.map((incomeSource, index) => {
        const {
          id,
          incomeType,
          employerName,
          employerInsurance,
          fullPartTime,
          employerAddressStreet,
          employerAddressCity,
          employerAddressState,
          employerAddressZip,
          employerPhone,
          startMonth,
          startYear,
          endMonth,
          endYear,
          payFrequency,
          amount,
        } = incomeSource
        return (
          <div key={id}>
            <Body1 className={classes.incomeSourceSubheader}>
              Income #{index + 1} -
            </Body1>
            <Item label="Employment Type" value={incomeType} />
            <Item label="Employer Name" value={employerName} />
            <Item
              label="Employer provides insurance"
              value={employerInsurance}
            />
            <Item label="Work Type" value={fullPartTime} />
            <Item label="Employer Address1" value={employerAddressStreet} />
            <Item label="Employer Address city" value={employerAddressCity} />
            <Item label="Employer Address state" value={employerAddressState} />
            <Item label="Employer Address zip" value={employerAddressZip} />
            <Item label="Employer work phone number" value={employerPhone} />
            <Item
              label="Job start date (mm/yyyy)"
              value={
                startMonth &&
                startYear &&
                getDateFromMonthAndYear(startMonth, startYear)
              }
              dateFormat="MM/yyyy"
            />
            <Item
              label="Job end date (mm/yyyy)"
              value={
                endMonth &&
                endYear &&
                getDateFromMonthAndYear(endMonth, endYear)
              }
              dateFormat="MM/yyyy"
            />
            <Item label="Payment Period" value={payFrequency} />
            <Item
              label="Work income (before taxes per pay period)"
              value={amount ? formatCurrency(amount) : ''}
            />
            <Item
              label="Monthly income:"
              value={formatCurrency(
                getAmountForMonth({
                  month: includesMonth(thisMonth)(incomeSource)
                    ? thisMonth
                    : lastMonth,
                  frequency: payFrequency,
                  amount,
                  startMonth,
                  startYear,
                  endMonth,
                  endYear,
                })
              )}
            />
          </div>
        )
      })}
      <Item
        label="Has this person had a change in their employment status in the last 6 months?"
        value={changeJobInLast6Mon}
      />
      <SubsectionHeader>Allowable deductions</SubsectionHeader>
      {deductions.map((deduction, index) => {
        const {
          id,
          deductionType,
          startMonth,
          startYear,
          endMonth,
          endYear,
          deductionFrequency,
          amount,
        } = deduction
        return (
          <div key={id}>
            <Body1 className={classes.incomeSourceSubheader}>
              Deduction #{index + 1} -
            </Body1>
            <Item label="Payment Type" value={deductionType} />
            <Item
              label="Start date (mm/yyyy)"
              value={
                startMonth &&
                startYear &&
                getDateFromMonthAndYear(startMonth, startYear)
              }
              dateFormat="MM/yyyy"
            />
            <Item
              label="End date (mm/yyyy)"
              value={
                endMonth &&
                endYear &&
                getDateFromMonthAndYear(endMonth, endYear)
              }
              dateFormat="MM/yyyy"
            />
            <Item label="Payment Period:" value={deductionFrequency} />
            <Item
              label="Payment Amount:"
              value={amount ? formatCurrency(amount) : ''}
            />
            <Item
              label="Monthly deduction:"
              value={formatCurrency(
                getAmountForMonth({
                  month: includesMonth(thisMonth)(deduction)
                    ? thisMonth
                    : lastMonth,
                  frequency: deductionFrequency,
                  amount,
                  startMonth,
                  startYear,
                  endMonth,
                  endYear,
                })
              )}
            />
          </div>
        )
      })}
      <SubsectionHeader>Tax details</SubsectionHeader>
      <Item
        label="Are you planning to file taxes?"
        value={
          !taxFilingStatus
            ? ''
            : taxFilingStatus !== TAX_FILING_STATUS_NOT_FILING
        }
      />
      <Item
        label="Will you be claimed as a dependent on someone’s tax return?"
        value={
          !taxFilingStatus
            ? ''
            : taxFilingStatus === TAX_FILING_STATUS_DEPENDENT
        }
      />
      <Item
        label="If Yes, please list the name of the tax filer:"
        value={
          dependentOfTaxFiler
            ? getExtendedName({...dependentOfTaxFiler, t})
            : ''
        }
      />
      <Item
        label="How are you related to tax filer:"
        value={relationshipToTaxFiler}
      />
      <Item
        label="Will you file jointly with spouse?"
        value={
          !taxFilingStatus
            ? ''
            : taxFilingStatus === TAX_FILING_STATUS_FILING_JOINTLY
        }
      />
      <Item label="If yes, name of spouse:" value={nameOfSpouseFilingJointly} />
      <Item
        label="Will you claim any dependents on your tax return?"
        value={dependents.length > 0}
      />
      <Item label="Dependent Member:">
        {dependents.map((dependent) => (
          <ItemValue key={dependent.id}>
            {getExtendedName({...dependent, t})}
          </ItemValue>
        ))}
      </Item>
    </Section>
  )
)

interface MemberInformationSectionProps {
  person: ApplicationForView_application_MedicaidApplication_householdMembers_person
}

const MemberInformationSection: FC<MemberInformationSectionProps> = flowMax(
  addDisplayName('MemberInformationSection'),
  addTranslationHelpers,
  ({
    person: {
      wantsCoverage,
      ssn,
      hasInsurance,
      currentInsuranceName,
      currentInsurancePolicyNo,
      alaskanNativeamerican,
      ciStatus,
      dateOfEntry,
      ciDocumentType,
      uscisNo,
      prCardNo,
      ciDocumentName,
      fosterCareAfter18,
      isStudent,
      insuranceLast3Months,
    },
    person,
    t,
  }) => (
    <Section label={`Member Information - ${getExtendedName({...person, t})}`}>
      <Item
        label="Does this person want NJ FamilyCare?"
        value={wantsCoverage}
      />
      <Item label="Evaluate for Plan First" value="" />
      <Item label="SSN" value={getFormattedSsn({ssn, t})} />
      <Item
        label="Does this person have Health Insurance?"
        value={hasInsurance}
      />
      <Item label="Name of insurance company" value={currentInsuranceName} />
      <Item label="Insurance policy number" value={currentInsurancePolicyNo} />
      <Item
        label="Is this person currently enrolled in NJ FamilyCare?"
        value=""
      />
      <Item label="Race" value="" />
      <Item
        label="Are you on anyone in your family Native American Indian or Alaska Native?"
        value={alaskanNativeamerican}
      />
      <Item
        label="US Citizen?"
        value={!ciStatus ? '' : ciStatus === CI_STATUS_US_CITIZEN}
      />
      <Item
        label="If you are not a US Citizen, please enter your date of entry"
        value={dateOfEntry}
      />
      <Item
        label="Immigration Status"
        value={ciStatus !== CI_STATUS_US_CITIZEN ? ciStatus : ''}
      />
      <Item label="Document Type" value={ciDocumentType} />
      <Item label="Alien/USCIS Number" value={uscisNo} />
      <Item label="Immigration card #" value={prCardNo} />
      <Item label="AKA" value={ciDocumentName} />
      <Item
        label="Were you in foster care at age 18 or older?"
        value={fosterCareAfter18}
      />
      <Item label="Full-time student?" value={isStudent} />
      <Item
        label="Has this person had other health insurance in the last three months other than NJ FamilyCare?"
        value={insuranceLast3Months}
      />
      <Item
        label="Does the member requesting coverage have medical bills for the last 3 months that have not been paid?"
        value="Yes"
      />
    </Section>
  )
)

interface HouseholdInformationSectionProps {
  person: ApplicationForView_application_MedicaidApplication_householdMembers_person
}

const HouseholdInformationSection: FC<HouseholdInformationSectionProps> = flowMax(
  addDisplayName('HouseholdInformationSection'),
  addTranslationHelpers,
  ({
    person: {
      firstName,
      middleName,
      lastName,
      dob,
      gender,
      pregnant,
      expectedChild,
      dueDate,
      maritalStatus,
    },
    person,
    t,
  }) => (
    <Section
      label={`Household Information - ${getExtendedName({...person, t})}`}
    >
      <Item label="FirstName" value={firstName} />
      <Item label="MiddleName" value={middleName} />
      <Item label="LastName" value={lastName} />
      <Item label="DOB" value={dob} dateFormat="MM/dd/yyyy" />
      <Item label="Sex" value={gender} />
      <Item label="Is Pregnant" value={pregnant} />
      <Item label="Number of babies expected" value={expectedChild} />
      <Item label="Pregnant Due Date" value={dueDate} />
      <Item label="Marital Status" value={maritalStatus} />
    </Section>
  )
)

const MedicaidApplicationView: FC = flowMax(
  addDisplayName('MedicaidApplicationView'),
  addRouteParams<{id: string}>(),
  addApplicationForViewQuery({
    variables: ({id}) => ({id}),
  }),
  addLoadingIndicator({}),
  declarePropTypesNarrowing<{
    application: ApplicationForView_application_MedicaidApplication
  }>(),
  addTranslationHelpers,
  addClasses(classes),
  ({
    application: {
      id,
      person: {
        homeAddressStreet,
        homeAddressCity,
        homeAddressState,
        homeAddressZip,
        mailingAddressStreet,
        mailingAddressCity,
        mailingAddressState,
        mailingAddressZip,
        phoneNumbers,
        email,
        relationships,
        childrenPcp,
        pcp,
        desiredMco,
        preferredLanguage,
      },
      person,
      householdMembers,
      primaryPointOfContact,
    },
    application,
    t,
    classes,
  }) => (
    <article className={classes.container}>
      <Heading className={classes.header}>
        NJ Medicaid application ({id}) for {getExtendedName({...person, t})}
      </Heading>
      <Section label="Home Address">
        <Item label="Home Addr1/Street" value={homeAddressStreet} />
        <Item label="Home City" value={homeAddressCity} />
        <Item label="Home County" value="" />
        <Item label="Home State" value={homeAddressState} />
        <Item label="Home Zip" value={homeAddressZip} />
      </Section>
      <Section label="Mailing Address">
        {!(
          mailingAddressStreet ||
          mailingAddressCity ||
          mailingAddressState ||
          mailingAddressZip
        ) ? (
          <Body1>Same as home address</Body1>
        ) : (
          <>
            <Item label="Mail Addr1/Street" value={mailingAddressStreet} />
            <Item label="Mail City" value={mailingAddressCity} />
            <Item label="Mail State" value={mailingAddressState} />
            <Item label="Mail Zip" value={mailingAddressZip} />
          </>
        )}
      </Section>
      <Section label="Phone Numbers and Email">
        <Item label="Phone No">
          {phoneNumbers
            .filter(({usedInApp}) => usedInApp)
            .map((phoneNumber) => (
              <ItemValue key={phoneNumber.id}>
                {getFormattedPhoneNumber(phoneNumber.number)}
              </ItemValue>
            ))}
        </Item>
        <Item label="Email" value={email} />
      </Section>
      {householdMembers.map((householdMember) => (
        <HouseholdInformationSection
          person={householdMember.person}
          key={householdMember.id}
        />
      ))}
      <Section label="Relationship information">
        {householdMembers
          .filter(({person: {id: personId}}) => personId !== person.id)
          .map((householdMember) => (
            <ItemValue key={householdMember.id}>
              {getExtendedName({...householdMember.person, t})} is a{' '}
              {relationships.find(
                ({otherPerson}) => otherPerson.id === householdMember.person.id
              )?.relationshipType ?? t('general.unknown')}{' '}
              of {getExtendedName({...person, t})}
            </ItemValue>
          ))}
      </Section>
      {householdMembers.map((householdMember) => (
        <MemberInformationSection
          person={householdMember.person}
          key={householdMember.id}
        />
      ))}
      {householdMembers.map((householdMember) => (
        <IncomeInformationSection
          person={householdMember.person}
          application={application}
          key={householdMember.id}
        />
      ))}
      <Section label="Health Plan">
        <SubsectionHeader>Doctor Information</SubsectionHeader>
        <Item label="Who is your child’s doctor?" value={childrenPcp} />
        <Item label="Address" value="" />
        <Item label="Who is your doctor?" value={pcp} />
        <Item label="Address" value="" />
        <Item label="Choose Health Plan:" value={desiredMco} />
        <SubsectionHeader>Other Information</SubsectionHeader>
        <Item
          label="Head of the household"
          value={
            primaryPointOfContact
              ? getExtendedName({...primaryPointOfContact, t})
              : ''
          }
        />
        <Item
          label="What language you speak at home"
          value={preferredLanguage}
        />
      </Section>
      <Section label="Additional Comments">
        <Item label="Income/Additional Comments">
          {householdMembers
            .filter(
              ({person: householdMember}) =>
                householdMember.income === false &&
                householdMember.whoSupportWithoutIncome &&
                [
                  WHO_SUPPORT_WITHOUT_INCOME_SELF,
                  WHO_SUPPORT_WITHOUT_INCOME_OTHER,
                ].includes(householdMember.whoSupportWithoutIncome)
            )
            .map(({person: householdMember}) => (
              <ItemValue key={householdMember.id}>
                {householdMember.whoSupportWithoutIncome ===
                WHO_SUPPORT_WITHOUT_INCOME_SELF ? (
                  <>
                    {getExtendedName({...householdMember, t})} has no income in
                    the last 30 days and is currently supporting him/herself.
                    Explanation: {householdMember.selfSupportWithoutIncome}
                  </>
                ) : (
                  <>
                    {getExtendedName({...householdMember, t})} has no income in
                    last 30 days and is supported by{' '}
                    {householdMember.othersSupportWithoutIncome}
                  </>
                )}
              </ItemValue>
            ))}
        </Item>
      </Section>
    </article>
  )
)

export default MedicaidApplicationView
