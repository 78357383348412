import startOfMonth from 'date-fns/fp/startOfMonth'
import sub from 'date-fns/fp/sub'
import {flow, last} from 'lodash/fp'
import add from 'date-fns/add'
import {TFunction} from 'i18next'

import {
  BenefitOutcome,
  BENEFIT_OUTCOME_OUTCOME_APPROVED,
} from 'components/BenefitOutcomeFormDialog'
import {isBeforeOrEqual, isAfterOrEqual, getShortDate} from 'utils/date'
import {getDelimiterSeparated, getCommaSeparated} from 'utils/string'
import {getBenefitName} from 'utils/application'
import {getIsFacilitySpecificBenefitType, BenefitType} from 'utils/benefits'

export const getOverlappingBenefitOutcomes = ({
  benefitOutcomeId,
  effectiveStartDate,
  effectiveEndDate,
  benefit,
  coveredFacility,
  benefitOutcomes,
}: {
  benefitOutcomeId?: string
  effectiveStartDate: Date
  effectiveEndDate: Date
  benefit: string
  coveredFacility: string
  benefitOutcomes: BenefitOutcome[]
}): BenefitOutcome[] =>
  benefitOutcomes.filter(
    ({
      id: otherBenefitOutcomeId,
      effectiveStartDate: otherEffectiveStartDate,
      effectiveEndDate: otherEffectiveEndDate,
      benefit: otherBenefit,
      coveredFacility: otherCoveredFacility,
    }) =>
      benefitOutcomeId !== otherBenefitOutcomeId &&
      benefit === otherBenefit &&
      isBeforeOrEqual(effectiveStartDate, otherEffectiveEndDate) &&
      isAfterOrEqual(effectiveEndDate, otherEffectiveStartDate) &&
      !(
        getIsFacilitySpecificBenefitType(benefit as BenefitType) &&
        getIsFacilitySpecificBenefitType(otherBenefit as BenefitType) &&
        coveredFacility !== otherCoveredFacility
      )
  )

const getLastOverlappingBenefitOutcome = ({
  date,
  benefitOutcomeId,
  benefit,
  coveredFacility,
  benefitOutcomes,
}: {
  date: Date
  benefitOutcomeId?: string
  benefit: string
  coveredFacility: string
  benefitOutcomes: BenefitOutcome[]
}): BenefitOutcome | false =>
  last(
    getOverlappingBenefitOutcomes({
      benefitOutcomeId,
      effectiveStartDate: date,
      effectiveEndDate: date,
      benefit,
      coveredFacility,
      benefitOutcomes,
    })
  ) || false

export const getMedicaidEffectiveStartDateDefault = ({
  submitDate,
  benefitOutcomes,
}: {
  submitDate: Date
  benefitOutcomes: BenefitOutcome[]
}) => {
  let defaultEffectiveStartDate = flow(
    startOfMonth,
    sub({months: 3})
  )(submitDate)

  while (true) {
    let overlappingBenefitOutcome = getLastOverlappingBenefitOutcome({
      date: defaultEffectiveStartDate,
      benefit: 'medicaid',
      coveredFacility: 'All',
      benefitOutcomes,
    })
    if (!overlappingBenefitOutcome) break
    defaultEffectiveStartDate = add(
      overlappingBenefitOutcome.effectiveEndDate,
      {days: 1}
    )
  }

  return defaultEffectiveStartDate
}

export const isBenefitOutcomeOverlapping = ({
  benefitOutcomeId,
  effectiveStartDate,
  effectiveEndDate,
  benefit,
  coveredFacility,
  benefitOutcomes,
}: {
  benefitOutcomeId?: string
  effectiveStartDate: Date
  effectiveEndDate: Date
  benefit: string
  coveredFacility: string
  benefitOutcomes: BenefitOutcome[]
}) =>
  getOverlappingBenefitOutcomes({
    benefitOutcomeId,
    effectiveStartDate,
    effectiveEndDate,
    benefit,
    coveredFacility,
    benefitOutcomes,
  }).length > 0

export const getFormattedBenefitOutcomeTitle = ({
  benefit,
  coveredFacility,
  outcome,
  deniedIneligibleReason,
  t,
}: {
  benefit: string
  coveredFacility: string | null
  outcome: string
  deniedIneligibleReason: string | null
  t: TFunction
}) =>
  getCommaSeparated([
    getBenefitName({benefit, t}),
    coveredFacility,
    getDelimiterSeparated(': ')([outcome, deniedIneligibleReason]),
  ])

export const getFormattedBenefitOutcomeSubtitle = ({
  effectiveStartDate,
  effectiveEndDate,
  insuranceName,
  policyId,
}: {
  effectiveStartDate: Date
  effectiveEndDate: Date
  insuranceName: string | null
  policyId: string | null
}) =>
  getCommaSeparated([
    getFormattedBenefitOutcomeDateRange({effectiveStartDate, effectiveEndDate}),
    insuranceName,
    policyId,
  ])

export const getFormattedBenefitOutcomeDateRange = ({
  effectiveStartDate,
  effectiveEndDate,
}: {
  effectiveStartDate: Date
  effectiveEndDate: Date
}) =>
  getDelimiterSeparated(' - ')([
    getShortDate(effectiveStartDate),
    getShortDate(effectiveEndDate),
  ])

export const isBenefitOutcomeEditable = ({
  benefitOutcome,
  userIsApprover,
}: {
  benefitOutcome?: {outcome: string}
  userIsApprover: boolean
}) =>
  benefitOutcome?.outcome !== BENEFIT_OUTCOME_OUTCOME_APPROVED || userIsApprover
