/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {
  ServiceType,
  InboundType,
  InboundInsuranceOnDateOfService,
  Benefit,
  NjmmisCheckStatus,
} from './globalTypes' // ====================================================
// GraphQL mutation operation: UnstartInbound
// ====================================================

import {UnstartInboundVariables as UnstartInboundVariablesOrig} from '../types/UnstartInbound'
import {UnstartInbound as UnstartInboundOrig} from '../types/UnstartInbound'
import {UnstartInbound_unstartInbound as UnstartInbound_unstartInboundOrig} from '../types/UnstartInbound'
import {UnstartInbound_unstartInbound_account as UnstartInbound_unstartInbound_accountOrig} from '../types/UnstartInbound'
import {UnstartInbound_unstartInbound_mostRecentNjmmisCheck as UnstartInbound_unstartInbound_mostRecentNjmmisCheckOrig} from '../types/UnstartInbound'
import {UnstartInbound_unstartInbound_personFormData as UnstartInbound_unstartInbound_personFormDataOrig} from '../types/UnstartInbound'
import {UnstartInbound_unstartInbound_person as UnstartInbound_unstartInbound_personOrig} from '../types/UnstartInbound'
import {AdditionalData} from 'utils/getAdditionalData'
import {parseAdditionalData} from 'utils/getAdditionalData'
import {serializeAdditionalData} from 'utils/getAdditionalData'
import formatISO from 'date-fns/formatISO'
import {parseDate} from 'utils/form/fieldTypes'
import {formatISODate} from 'utils/date'
import {UnstartInbound_unstartInbound_person_mostRecentEligibilityDeterminations as UnstartInbound_unstartInbound_person_mostRecentEligibilityDeterminationsOrig} from '../types/UnstartInbound'
import {BenefitType} from 'utils/benefits'
import {deserializeBenefit} from 'graphql/converters'
import {serializeBenefit} from 'graphql/converters'
import {UnstartInbound_unstartInbound_person_openApplications as UnstartInbound_unstartInbound_person_openApplicationsOrig} from '../types/UnstartInbound'
import {UnstartInbound_unstartInbound_completedBy as UnstartInbound_unstartInbound_completedByOrig} from '../types/UnstartInbound'
import {UnstartInbound_unstartInbound_startedBy as UnstartInbound_unstartInbound_startedByOrig} from '../types/UnstartInbound'
export interface UnstartInbound_unstartInbound_startedBy {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeUnstartInbound_unstartInbound_startedBy = (
  serialized: UnstartInbound_unstartInbound_startedByOrig
): UnstartInbound_unstartInbound_startedBy => ({...serialized})
export const serializeUnstartInbound_unstartInbound_startedBy = (
  deserialized: UnstartInbound_unstartInbound_startedBy
): UnstartInbound_unstartInbound_startedByOrig => ({...deserialized})
export interface UnstartInbound_unstartInbound_completedBy {
  __typename: 'User'
  id: string
  email: string
  name: string | null
  isCurrentUser: boolean
}
export const deserializeUnstartInbound_unstartInbound_completedBy = (
  serialized: UnstartInbound_unstartInbound_completedByOrig
): UnstartInbound_unstartInbound_completedBy => ({...serialized})
export const serializeUnstartInbound_unstartInbound_completedBy = (
  deserialized: UnstartInbound_unstartInbound_completedBy
): UnstartInbound_unstartInbound_completedByOrig => ({...deserialized})
export interface UnstartInbound_unstartInbound_person_openApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializeUnstartInbound_unstartInbound_person_openApplications = (
  serialized: UnstartInbound_unstartInbound_person_openApplicationsOrig
): UnstartInbound_unstartInbound_person_openApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializeUnstartInbound_unstartInbound_person_openApplications = (
  deserialized: UnstartInbound_unstartInbound_person_openApplications
): UnstartInbound_unstartInbound_person_openApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface UnstartInbound_unstartInbound_person_mostRecentEligibilityDeterminations {
  __typename: 'EligibilityDetermination'
  id: string
  benefit: string
  status: string
  reasonOrInfo: string | null
  secondaryInfo: string | null
  householdSizeDescription: string | null
  month: Date | null
  numMonths: number | null
  createdAt: Date
  additionalData: AdditionalData | null
}
export const deserializeUnstartInbound_unstartInbound_person_mostRecentEligibilityDeterminations = (
  serialized: UnstartInbound_unstartInbound_person_mostRecentEligibilityDeterminationsOrig
): UnstartInbound_unstartInbound_person_mostRecentEligibilityDeterminations => ({
  ...serialized,
  month:
    serialized.month != null ? parseDate(serialized.month) : serialized.month,
  createdAt: parseDate(serialized.createdAt),
  additionalData:
    serialized.additionalData != null
      ? parseAdditionalData(serialized.additionalData)
      : serialized.additionalData,
})
export const serializeUnstartInbound_unstartInbound_person_mostRecentEligibilityDeterminations = (
  deserialized: UnstartInbound_unstartInbound_person_mostRecentEligibilityDeterminations
): UnstartInbound_unstartInbound_person_mostRecentEligibilityDeterminationsOrig => ({
  ...deserialized,
  month:
    deserialized.month != null
      ? formatISODate(deserialized.month)
      : deserialized.month,
  createdAt: formatISO(deserialized.createdAt),
  additionalData:
    deserialized.additionalData != null
      ? serializeAdditionalData(deserialized.additionalData)
      : deserialized.additionalData,
})
export interface UnstartInbound_unstartInbound_person {
  __typename: 'Person'
  id: string
  firstName: string | null
  middleName: string | null
  lastName: string | null
  preferredName: string | null
  suffix: string | null
  dob: Date | null
  openApplications: UnstartInbound_unstartInbound_person_openApplications[]
  mostRecentEligibilityDeterminations: UnstartInbound_unstartInbound_person_mostRecentEligibilityDeterminations[]
}
export const deserializeUnstartInbound_unstartInbound_person = (
  serialized: UnstartInbound_unstartInbound_personOrig
): UnstartInbound_unstartInbound_person => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  openApplications: serialized.openApplications.map(
    deserializeUnstartInbound_unstartInbound_person_openApplications
  ),
  mostRecentEligibilityDeterminations: serialized.mostRecentEligibilityDeterminations.map(
    deserializeUnstartInbound_unstartInbound_person_mostRecentEligibilityDeterminations
  ),
})
export const serializeUnstartInbound_unstartInbound_person = (
  deserialized: UnstartInbound_unstartInbound_person
): UnstartInbound_unstartInbound_personOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  openApplications: deserialized.openApplications.map(
    serializeUnstartInbound_unstartInbound_person_openApplications
  ),
  mostRecentEligibilityDeterminations: deserialized.mostRecentEligibilityDeterminations.map(
    serializeUnstartInbound_unstartInbound_person_mostRecentEligibilityDeterminations
  ),
})
export interface UnstartInbound_unstartInbound_personFormData {
  __typename: 'InboundPersonFormData'
  firstName: string | null
  lastName: string | null
  dob: Date | null
  phoneNumber: string | null
}
export const deserializeUnstartInbound_unstartInbound_personFormData = (
  serialized: UnstartInbound_unstartInbound_personFormDataOrig
): UnstartInbound_unstartInbound_personFormData => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
})
export const serializeUnstartInbound_unstartInbound_personFormData = (
  deserialized: UnstartInbound_unstartInbound_personFormData
): UnstartInbound_unstartInbound_personFormDataOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
})
export interface UnstartInbound_unstartInbound_mostRecentNjmmisCheck {
  __typename: 'NjmmisCheck'
  id: string
  status: NjmmisCheckStatus
  medicaidId: string | null
  eligibleServices: string[] | null
}
export const deserializeUnstartInbound_unstartInbound_mostRecentNjmmisCheck = (
  serialized: UnstartInbound_unstartInbound_mostRecentNjmmisCheckOrig
): UnstartInbound_unstartInbound_mostRecentNjmmisCheck => ({...serialized})
export const serializeUnstartInbound_unstartInbound_mostRecentNjmmisCheck = (
  deserialized: UnstartInbound_unstartInbound_mostRecentNjmmisCheck
): UnstartInbound_unstartInbound_mostRecentNjmmisCheckOrig => ({
  ...deserialized,
})
export interface UnstartInbound_unstartInbound_account {
  __typename: 'Account'
  id: string
  department: string
  insuranceType: string
}
export const deserializeUnstartInbound_unstartInbound_account = (
  serialized: UnstartInbound_unstartInbound_accountOrig
): UnstartInbound_unstartInbound_account => ({...serialized})
export const serializeUnstartInbound_unstartInbound_account = (
  deserialized: UnstartInbound_unstartInbound_account
): UnstartInbound_unstartInbound_accountOrig => ({...deserialized})
export interface UnstartInbound_unstartInbound {
  __typename: 'Inbound'
  id: string
  reason: string
  dateOfService: Date | null
  serviceType: ServiceType | null
  createdAt: Date
  lastIntakeWebformReceivedAt: Date | null
  inboundType: InboundType
  insuranceOnDateOfService: InboundInsuranceOnDateOfService | null
  startedBy: UnstartInbound_unstartInbound_startedBy | null
  startedAt: Date | null
  completedBy: UnstartInbound_unstartInbound_completedBy | null
  completedAt: Date | null
  person: UnstartInbound_unstartInbound_person | null
  personFormData: UnstartInbound_unstartInbound_personFormData | null
  mostRecentNjmmisCheck: UnstartInbound_unstartInbound_mostRecentNjmmisCheck | null
  account: UnstartInbound_unstartInbound_account | null
}
export const deserializeUnstartInbound_unstartInbound = (
  serialized: UnstartInbound_unstartInboundOrig
): UnstartInbound_unstartInbound => ({
  ...serialized,
  dateOfService:
    serialized.dateOfService != null
      ? parseDate(serialized.dateOfService)
      : serialized.dateOfService,
  createdAt: parseDate(serialized.createdAt),
  lastIntakeWebformReceivedAt:
    serialized.lastIntakeWebformReceivedAt != null
      ? parseDate(serialized.lastIntakeWebformReceivedAt)
      : serialized.lastIntakeWebformReceivedAt,
  startedBy:
    serialized.startedBy != null
      ? deserializeUnstartInbound_unstartInbound_startedBy(serialized.startedBy)
      : serialized.startedBy,
  startedAt:
    serialized.startedAt != null
      ? parseDate(serialized.startedAt)
      : serialized.startedAt,
  completedBy:
    serialized.completedBy != null
      ? deserializeUnstartInbound_unstartInbound_completedBy(
          serialized.completedBy
        )
      : serialized.completedBy,
  completedAt:
    serialized.completedAt != null
      ? parseDate(serialized.completedAt)
      : serialized.completedAt,
  person:
    serialized.person != null
      ? deserializeUnstartInbound_unstartInbound_person(serialized.person)
      : serialized.person,
  personFormData:
    serialized.personFormData != null
      ? deserializeUnstartInbound_unstartInbound_personFormData(
          serialized.personFormData
        )
      : serialized.personFormData,
  mostRecentNjmmisCheck:
    serialized.mostRecentNjmmisCheck != null
      ? deserializeUnstartInbound_unstartInbound_mostRecentNjmmisCheck(
          serialized.mostRecentNjmmisCheck
        )
      : serialized.mostRecentNjmmisCheck,
  account:
    serialized.account != null
      ? deserializeUnstartInbound_unstartInbound_account(serialized.account)
      : serialized.account,
})
export const serializeUnstartInbound_unstartInbound = (
  deserialized: UnstartInbound_unstartInbound
): UnstartInbound_unstartInboundOrig => ({
  ...deserialized,
  dateOfService:
    deserialized.dateOfService != null
      ? formatISODate(deserialized.dateOfService)
      : deserialized.dateOfService,
  createdAt: formatISO(deserialized.createdAt),
  lastIntakeWebformReceivedAt:
    deserialized.lastIntakeWebformReceivedAt != null
      ? formatISO(deserialized.lastIntakeWebformReceivedAt)
      : deserialized.lastIntakeWebformReceivedAt,
  startedBy:
    deserialized.startedBy != null
      ? serializeUnstartInbound_unstartInbound_startedBy(deserialized.startedBy)
      : deserialized.startedBy,
  startedAt:
    deserialized.startedAt != null
      ? formatISO(deserialized.startedAt)
      : deserialized.startedAt,
  completedBy:
    deserialized.completedBy != null
      ? serializeUnstartInbound_unstartInbound_completedBy(
          deserialized.completedBy
        )
      : deserialized.completedBy,
  completedAt:
    deserialized.completedAt != null
      ? formatISO(deserialized.completedAt)
      : deserialized.completedAt,
  person:
    deserialized.person != null
      ? serializeUnstartInbound_unstartInbound_person(deserialized.person)
      : deserialized.person,
  personFormData:
    deserialized.personFormData != null
      ? serializeUnstartInbound_unstartInbound_personFormData(
          deserialized.personFormData
        )
      : deserialized.personFormData,
  mostRecentNjmmisCheck:
    deserialized.mostRecentNjmmisCheck != null
      ? serializeUnstartInbound_unstartInbound_mostRecentNjmmisCheck(
          deserialized.mostRecentNjmmisCheck
        )
      : deserialized.mostRecentNjmmisCheck,
  account:
    deserialized.account != null
      ? serializeUnstartInbound_unstartInbound_account(deserialized.account)
      : deserialized.account,
})
export interface UnstartInbound {
  /**
   * Unstart inbound
   */
  unstartInbound: UnstartInbound_unstartInbound
}
export const deserializeUnstartInbound = (
  serialized: UnstartInboundOrig
): UnstartInbound => ({
  ...serialized,
  unstartInbound: deserializeUnstartInbound_unstartInbound(
    serialized.unstartInbound
  ),
})
export const serializeUnstartInbound = (
  deserialized: UnstartInbound
): UnstartInboundOrig => ({
  ...deserialized,
  unstartInbound: serializeUnstartInbound_unstartInbound(
    deserialized.unstartInbound
  ),
})
export interface UnstartInboundVariables {
  id: string
}
export const deserializeUnstartInboundVariables = (
  serialized: UnstartInboundVariablesOrig
): UnstartInboundVariables => ({...serialized})
export const serializeUnstartInboundVariables = (
  deserialized: UnstartInboundVariables
): UnstartInboundVariablesOrig => ({...deserialized})
