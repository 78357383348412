import {makeFormSchema} from 'utils/form/schema'
import {makeTextField, makeMcdTypeField} from 'utils/form/fieldTypes'
import {editMonitorApplicationFormSchema} from 'components/EditMonitorApplicationForm/schema'

const {
  primaryPointOfContactId,
  submitDate,
  submitMethod,
  countyName,
  confirmationNumber,
  policyId,
  assignedToId,
  notes,
  initialDateOfService,
  accountNumber,
} = editMonitorApplicationFormSchema.fields.application

export const editMedicaidApplicationFormSchema = makeFormSchema({
  fields: {
    application: {
      id: makeTextField({
        isRequired: true,
      }),
      primaryPointOfContactId,
      submitDate,
      submitMethod,
      countyName,
      confirmationNumber,
      policyId,
      assignedToId,
      notes,
      initialDateOfService,
      accountNumber,
      mcdType: makeMcdTypeField({
        isRequired: true,
      }),
    },
  },
})
