import React, {FC} from 'react'
import EditIcon from '@material-ui/icons/Edit'
import {
  flowMax,
  addDisplayName,
  addHandlers,
  addProps,
  branch,
  returns,
  addState,
  addEffect,
  addWrapper,
  renderNothing,
  SimpleUnchangedProps,
  addDefaultProps,
} from 'ad-hok'
import DeleteIcon from '@material-ui/icons/Delete'
import {format} from 'date-fns/fp'
import {
  addPropIdentityStabilization,
  cleanupProps,
  branchIfNullish,
  getContextHelpers,
  toObjectKeys,
} from 'ad-hok-utils'
import {isEqual, some, sortBy} from 'lodash/fp'
import {generatePath} from 'react-router'

import addDialogState from 'utils/addDialogState'
import {addApplicationFormContext} from 'components/EditApplicationForm/applicationFormContext'
import {
  addUpdateDocumentMutation,
  addCreateDocumentMutation,
  addDeleteDocumentMutation,
  addPersonQuery,
} from 'graphql/generated'
import {APPLICATION_QUERY} from 'graphql/queries'
import {addDocumentSubsectionContext} from 'components/EditApplicationForm/DataAndDocumentsSection/context'
import {addTranslationHelpers} from 'utils/i18n'
import ApplicationDocumentDialog, {
  applicationDocumentFormSchema,
} from 'components/ApplicationDocumentDialog'
import IconButton from 'components/IconButton'
import Tooltip from 'components/Tooltip'
import assertProps from 'utils/assertProps'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'
import ConfirmationDialog from 'components/ConfirmationDialog'
import {addClasses, makeClasses} from 'theme'
import DocumentFileLink from 'components/DocumentFileLink'
import DocumentFileDownloadLink from 'components/DocumentFileDownloadLink'
import {toEasternTime} from 'utils/date'
import Grid from 'components/Grid'
import Body1 from 'components/Body1'
import InfoTooltip from 'components/InfoTooltip'
import {FormCanonicalValues, ExtractFormSchemaFields} from 'utils/form/schema'
import AddButton from 'components/AddButton'
import {isDataLoaded} from 'utils/dataLoading'
import {
  determineDocuments,
  RequiredDocument,
} from 'components/EditPersonForm/documents'
import Link, {highlightStyle} from 'components/Link'
import {personDetailPath} from 'components/PersonDetail/index'
import {getExtendedName} from 'utils/name'
import {
  PersistedDocument,
  ReconciledDocument,
} from 'utils/persistedDocumentTypes'
import PersistedDocumentCheckMarkOrNecessaryIcon from 'components/PersistedDocumentCheckMarkOrNecessaryIcon'
import {DocumentFileFields} from 'graphql/deserializedTypes/DocumentFileFields'
import {Assert, SchemaDoesntHaveExtraFields} from 'utils/form/typeHelpers'

const classes = makeClasses((theme) => ({
  infoTooltipContainer: {
    marginRight: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing(1),
  },
  fileContainer: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(-2),
  },
  fileLink: {
    fontSize: 12,
  },
  updatedAt: {
    fontSize: 12,
  },
  documentContainer: {
    flexWrap: 'nowrap',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  documentsContainer: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(2),
  },
  personLink: {
    color: highlightStyle.color,
    fontWeight: highlightStyle.fontWeight,
    marginRight: theme.spacing(2),
  },
  addButton: {
    color: highlightStyle.color,
  },
  personTopRowContainer: {
    height: 36,
    marginBottom: theme.spacing(-3),
  },
  sectionContainer: {
    marginBottom: theme.spacing(4),
  },
}))

const [
  addIsDocumentEditingFrozenContextProvider,
  addIsDocumentEditingFrozenContext,
] = getContextHelpers<{
  isDocumentEditingFrozen: boolean
}>(toObjectKeys(['isDocumentEditingFrozen']))

export {addIsDocumentEditingFrozenContextProvider}

type SuppressIfDocumentEditingIsFrozenType = SimpleUnchangedProps
const suppressIfDocumentEditingIsFrozen: SuppressIfDocumentEditingIsFrozenType = flowMax(
  addIsDocumentEditingFrozenContext,
  branch(
    ({isDocumentEditingFrozen}) => isDocumentEditingFrozen,
    renderNothing()
  ),
  cleanupProps(['isDocumentEditingFrozen'])
)

type CheckCreate = Assert<
  SchemaDoesntHaveExtraFields<
    typeof applicationDocumentFormSchema,
    typeof addCreateDocumentMutation
  >
>

type CheckUpdate = Assert<
  SchemaDoesntHaveExtraFields<
    typeof applicationDocumentFormSchema,
    typeof addUpdateDocumentMutation
  >
>

interface EditApplicationDocumentButtonProps {
  document: ReconciledDocument
}

const EditApplicationDocumentButton: FC<EditApplicationDocumentButtonProps> = flowMax(
  addDisplayName('EditApplicationDocumentButton'),
  suppressIfDocumentEditingIsFrozen,
  addDialogState,
  addApplicationFormContext,
  addUpdateDocumentMutation({
    refetchQueries: ({application: {id}}) => [
      {
        query: APPLICATION_QUERY,
        variables: {id},
      },
    ],
  }),
  addCreateDocumentMutation({
    refetchQueries: ({application: {id}}) => [
      {
        query: APPLICATION_QUERY,
        variables: {id},
      },
    ],
  }),
  addDocumentSubsectionContext,
  addTranslationHelpers,
  addState('isSubmitting', 'setIsSubmitting', false),
  addHandlers({
    onSubmit: ({
      mutateUpdateDocument,
      mutateCreateDocument,
      hideDialog,
      document: {persistedDocument},
      application: {id: applicationId},
      personId,
      setIsSubmitting,
      t,
    }) => ({
      canonicalValues,
    }: {
      canonicalValues: FormCanonicalValues<
        ExtractFormSchemaFields<typeof applicationDocumentFormSchema>
      >
    }) => {
      if (persistedDocument) {
        mutateUpdateDocument({
          variables: {
            document: {
              ...canonicalValues.document,
              id: persistedDocument.id,
            },
          },
        })
          .then(() => {
            hideDialog()
            setIsSubmitting(false)
          })
          .catch(() => {
            window.alert(t('applicationForm.applicationDocuments.failedToSave'))
            setIsSubmitting(false)
          })
      } else {
        mutateCreateDocument({
          variables: {
            document: {
              ...canonicalValues.document,
              applicationId,
              personId,
            },
          },
        })
          .then(() => {
            hideDialog()
            setIsSubmitting(false)
          })
          .catch(() => {
            window.alert(t('applicationForm.applicationDocuments.failedToSave'))
            setIsSubmitting(false)
          })
      }
      setIsSubmitting(true)
    },
  }),
  ({
    isShowingDialog,
    hideDialog,
    showDialog,
    onSubmit,
    document: {persistedDocument, requiredDocument},
    isSubmitting,
  }) => (
    <>
      <IconButton onClick={showDialog}>
        <EditIcon />
      </IconButton>
      <ApplicationDocumentDialog
        open={isShowingDialog}
        onClose={hideDialog}
        onSubmit={onSubmit}
        document={persistedDocument}
        documentType={
          persistedDocument ? undefined : requiredDocument!.documentType
        }
        canEditDocumentType={false}
        isSubmitting={isSubmitting}
      />
    </>
  )
)

interface DeleteApplicationDocumentButtonProps {
  document: ReconciledDocument
}

const DeleteApplicationDocumentButton: FC<DeleteApplicationDocumentButtonProps> = flowMax(
  addDisplayName('DeleteApplicationDocumentButton'),
  suppressIfDocumentEditingIsFrozen,
  addProps(({document: {persistedDocument, requiredDocument}}) => ({
    persistedDocument,
    requiredDocument,
  })),
  addTranslationHelpers,
  branch(
    ({requiredDocument}) => !!requiredDocument,
    returns(({t}) => (
      <Tooltip
        title={t('applicationForm.applicationDocuments.deleteDisabledTooltip')!}
      >
        <span>
          <IconButton disabled>
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
    ))
  ),
  assertProps('persistedDocument'),
  addDialogState,
  addApplicationFormContext,
  addDeleteDocumentMutation({
    refetchQueries: ({application: {id}}) => [
      {
        query: APPLICATION_QUERY,
        variables: {id},
      },
    ],
  }),
  addAppSnackbarContext,
  addHandlers({
    onDelete: ({
      mutateDeleteDocument,
      hideDialog,
      persistedDocument,
      showSnackbarMessage,
      t,
    }) => () => {
      mutateDeleteDocument({
        variables: {
          id: persistedDocument.id,
        },
      })
        .then(() => {
          hideDialog()
          showSnackbarMessage(t('applicationForm.applicationDocuments.deleted'))
        })
        .catch(() => {
          window.alert(t('applicationForm.applicationDocuments.failedToDelete'))
        })
    },
  }),
  ({
    isShowingDialog,
    hideDialog,
    showDialog,
    onDelete,
    document: {documentType},
    t,
  }) => (
    <>
      <IconButton onClick={showDialog}>
        <DeleteIcon />
      </IconButton>
      <ConfirmationDialog
        open={isShowingDialog}
        onCancel={hideDialog}
        onConfirm={onDelete}
        title={t('deleteApplicationDocumentDialog.title', {
          documentType,
        })}
        cancelText={t('deleteApplicationDocumentDialog.cancel')}
        confirmText={t('deleteApplicationDocumentDialog.confirm')}
      />
    </>
  )
)

interface FileProps {
  file: DocumentFileFields
}

const File: FC<FileProps> = flowMax(
  addDisplayName('File'),
  addClasses(classes),
  ({file, classes}) => (
    <div className={classes.fileContainer}>
      <DocumentFileLink documentFile={file} className={classes.fileLink} />
      <DocumentFileDownloadLink documentFile={file} />
    </div>
  )
)

interface DocumentProps {
  document: ReconciledDocument
}

const Document: FC<DocumentProps> = flowMax(
  addDisplayName('Document'),
  addTranslationHelpers,
  addProps(({document: {persistedDocument}, t}) => ({
    updatedAtFormatted: persistedDocument
      ? t('applicationForm.applicationDocuments.updatedAt', {
          updatedAt: `${format('M/d/yy HH:mm')(
            toEasternTime(persistedDocument.updatedAt)
          )}ET`,
        })
      : null,
  })),
  addClasses(classes),
  ({
    document: {documentType, persistedDocument},
    document,
    updatedAtFormatted,
    classes,
  }) => (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.documentContainer}
      >
        <Body1>
          {documentType}{' '}
          <span className={classes.updatedAt}>{updatedAtFormatted}</span>
        </Body1>
        <div className={classes.infoTooltipContainer}>
          <InfoTooltip info={persistedDocument?.notes} />
        </div>
        <EditApplicationDocumentButton document={document} />
        <DeleteApplicationDocumentButton document={document} />
        <PersistedDocumentCheckMarkOrNecessaryIcon
          persistedDocument={persistedDocument}
        />
      </Grid>
      {persistedDocument?.files.map((file) => (
        <File file={file} key={file.id} />
      ))}
    </>
  )
)

interface CreateApplicationDocumentButtonProps {
  label?: string
}

const CreateApplicationDocumentButton: FC<CreateApplicationDocumentButtonProps> = flowMax(
  addDisplayName('CreateApplicationDocumentButton'),
  suppressIfDocumentEditingIsFrozen,
  addDialogState,
  addApplicationFormContext,
  addCreateDocumentMutation({
    refetchQueries: ({application: {id}}) => [
      {
        query: APPLICATION_QUERY,
        variables: {id},
      },
    ],
  }),
  addDocumentSubsectionContext,
  addTranslationHelpers,
  addState('isSubmitting', 'setIsSubmitting', false),
  addHandlers({
    onSubmit: ({
      mutateCreateDocument,
      hideDialog,
      application: {id: applicationId},
      personId,
      setIsSubmitting,
      t,
    }) => ({
      canonicalValues,
    }: {
      canonicalValues: FormCanonicalValues<
        ExtractFormSchemaFields<typeof applicationDocumentFormSchema>
      >
    }) => {
      mutateCreateDocument({
        variables: {
          document: {
            ...canonicalValues.document,
            applicationId,
            personId,
          },
        },
      })
        .then(() => {
          hideDialog()
          setIsSubmitting(false)
        })
        .catch(() => {
          window.alert(t('applicationForm.applicationDocuments.failedToSave'))
          setIsSubmitting(false)
        })
      setIsSubmitting(true)
    },
  }),
  addDefaultProps(({t}) => ({
    label: t('applicationForm.applicationDocuments.add'),
  })),
  addClasses(classes),
  ({
    isShowingDialog,
    showDialog,
    hideDialog,
    onSubmit,
    isSubmitting,
    label,
    classes,
  }) => (
    <>
      <AddButton onClick={showDialog} className={classes.addButton}>
        {label}
      </AddButton>
      <ApplicationDocumentDialog
        open={isShowingDialog}
        onClose={hideDialog}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
      />
    </>
  )
)

type AddRequiredDocumentsType = <
  TProps extends {
    application: {
      person: {
        id: string
      }
    }
  }
>(
  props: TProps
) => TProps & {
  requiredDocuments: RequiredDocument[] | null
}

const addRequiredDocuments: AddRequiredDocumentsType = flowMax(
  addPersonQuery({
    variables: ({
      application: {
        person: {id},
      },
    }) => ({id}),
  }),
  // addLoadingIndicatorFor('person', {}),
  addState(
    'requiredDocuments',
    'setRequiredDocuments',
    (): RequiredDocument[] | null => null
  ),
  addPropIdentityStabilization('person'),
  // eslint-disable-next-line ad-hok/dependencies
  addEffect(
    ({person, setRequiredDocuments, requiredDocuments}) => () => {
      if (!isDataLoaded(person)) return
      const personData = person.data
      const determinedDocuments = determineDocuments(
        {person: personData},
        personData.openApplications
      )
      if (
        determinedDocuments === requiredDocuments ||
        isEqual(determinedDocuments, requiredDocuments)
      )
        return
      setRequiredDocuments(determinedDocuments)
    },
    ['person']
  ),
  cleanupProps(['setRequiredDocuments'])
)

const getReconciledDocuments = ({
  application: {documents: persistedDocuments},
  application,
  requiredDocuments,
  personId,
}: {
  application: {
    id: string
    documents: PersistedDocument[]
  }
  requiredDocuments: RequiredDocument[]
  personId: string | null
}): ReconciledDocument[] => {
  const reconciledRequiredDocuments = requiredDocuments
    .filter(
      ({applicationId, personId: documentPersonId}) =>
        applicationId === application.id &&
        ((!personId && !documentPersonId) || personId === documentPersonId)
    )
    .map((relevantRequiredDocument) => ({
      documentType: relevantRequiredDocument.documentType,
      requiredDocument: relevantRequiredDocument,
      persistedDocument: persistedDocuments.find(
        ({documentType, person}) =>
          documentType === relevantRequiredDocument.documentType &&
          ((!person && !personId) || person?.id === personId)
      ),
    }))
  const nonrequiredPersistedDocuments = persistedDocuments
    .filter(
      ({documentType, id, person}) =>
        ((!personId && !person) || personId === person?.id) &&
        !some(
          ({
            documentType: reconciledRequiredDocumentDocumentType,
            persistedDocument,
          }) =>
            documentType === reconciledRequiredDocumentDocumentType &&
            id === persistedDocument?.id,
          reconciledRequiredDocuments
        )
    )
    .map((persistedDocument) => ({
      documentType: persistedDocument.documentType,
      persistedDocument,
    }))
  return [...reconciledRequiredDocuments, ...nonrequiredPersistedDocuments]
}

const PersonHeader: FC = flowMax(
  addDisplayName('PersonHeader'),
  addDocumentSubsectionContext,
  addApplicationFormContext,
  addProps(
    ({application: {householdMembers, person}, personId}) => ({
      person:
        householdMembers.find(({person}) => person.id === personId)?.person ??
        (personId === person.id ? person : null),
    }),
    ['application', 'personId']
  ),
  branchIfNullish('person'),
  addClasses(classes),
  addTranslationHelpers,
  ({person, classes, t}) => (
    <div className={classes.personTopRowContainer}>
      <Link
        to={generatePath(`${personDetailPath}/files`, {id: person.id})}
        className={classes.personLink}
      >
        {getExtendedName({...person, t})}
      </Link>
      <CreateApplicationDocumentButton />
    </div>
  )
)

const DocumentsSubsection: FC = flowMax(
  addDisplayName('DocumentsSubsection'),
  addDocumentSubsectionContext,
  addClasses(classes),
  addApplicationFormContext,
  addRequiredDocuments,
  addProps(
    ({application, requiredDocuments, personId}) => ({
      documents: sortBy(
        'documentType',
        getReconciledDocuments({
          application,
          requiredDocuments: requiredDocuments ?? [],
          personId,
        })
      ),
    }),
    ['application', 'requiredDocuments', 'personId']
  ),
  addIsDocumentEditingFrozenContext,
  branch(
    ({documents, isDocumentEditingFrozen}) =>
      isDocumentEditingFrozen && !documents.length,
    renderNothing()
  ),
  addTranslationHelpers,
  addClasses(classes),
  addWrapper((render, {personId, t, classes}) => (
    <div className={classes.sectionContainer}>
      {personId && <PersonHeader />}
      {render()}
      {!personId && (
        <CreateApplicationDocumentButton
          label={
            t('applicationForm.applicationDocuments.addApplicationDocument')!
          }
        />
      )}
    </div>
  )),
  // eslint-disable-next-line ad-hok/dependencies
  addEffect(
    ({documents, setHasNecessaryDocumentsByPersonId, personId}) => () => {
      setHasNecessaryDocumentsByPersonId(
        personId,
        documents.some(({persistedDocument}) => !persistedDocument?.complete)
      )
    },
    ['documents']
  ),
  branch(({documents}) => !documents.length, renderNothing()),
  ({documents, classes}) => (
    <div className={classes.documentsContainer}>
      {documents.map((document, index) => (
        <Document
          document={document}
          key={
            document.persistedDocument?.id ??
            `tmp-${index}-${document.documentType}`
          }
        />
      ))}
    </div>
  )
)

export default DocumentsSubsection
