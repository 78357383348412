import {editMonitorApplicationFormSchema} from 'components/EditMonitorApplicationForm/schema'
import {makeFormSchema} from 'utils/form/schema'
import {
  makeTextField,
  makeDateField,
  makeSelectField,
} from 'utils/form/fieldTypes'

const {notes} = editMonitorApplicationFormSchema.fields.application

export const editSlideApplicationFormSchema = makeFormSchema({
  fields: {
    application: {
      id: makeTextField({
        isRequired: true,
      }),
      initialDateOfService: makeDateField(),
      accountNumber: makeTextField(),
      receivedDate: makeDateField(),
      assignedToId: makeSelectField({}),
      notes,
    },
  },
})
