/* eslint-disable */
import {FederalPovertyLevels as FederalPovertyLevelsOrig} from '../types/FederalPovertyLevels'
import {FederalPovertyLevels_federalPovertyLevels as FederalPovertyLevels_federalPovertyLevelsOrig} from '../types/FederalPovertyLevels'

/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
// ====================================================
// GraphQL query operation: FederalPovertyLevels
// ====================================================
export interface FederalPovertyLevels_federalPovertyLevels {
  __typename: 'FederalPovertyLevel'
  id: string
  familySize: string
  monthlyIncome: number
}
export const deserializeFederalPovertyLevels_federalPovertyLevels = (
  serialized: FederalPovertyLevels_federalPovertyLevelsOrig
): FederalPovertyLevels_federalPovertyLevels => ({...serialized})
export const serializeFederalPovertyLevels_federalPovertyLevels = (
  deserialized: FederalPovertyLevels_federalPovertyLevels
): FederalPovertyLevels_federalPovertyLevelsOrig => ({...deserialized})
export interface FederalPovertyLevels {
  /**
   * Federal poverty levels
   */
  federalPovertyLevels: FederalPovertyLevels_federalPovertyLevels[]
}
export const deserializeFederalPovertyLevels = (
  serialized: FederalPovertyLevelsOrig
): FederalPovertyLevels => ({
  ...serialized,
  federalPovertyLevels: serialized.federalPovertyLevels.map(
    deserializeFederalPovertyLevels_federalPovertyLevels
  ),
})
export const serializeFederalPovertyLevels = (
  deserialized: FederalPovertyLevels
): FederalPovertyLevelsOrig => ({
  ...deserialized,
  federalPovertyLevels: deserialized.federalPovertyLevels.map(
    serializeFederalPovertyLevels_federalPovertyLevels
  ),
})
