import React, {FC} from 'react'
import {
  flowMax,
  addDisplayName,
  addProps,
  branch,
  renderNothing,
  returns,
  addWrapper,
  addMemoBoundary,
} from 'ad-hok'
import {Theme} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import {format} from 'date-fns/fp'
import {generatePath} from 'react-router'
import {TFunction} from 'i18next'
import {branchIfNullish, branchIfFailsPredicate} from 'ad-hok-utils'

import {addClasses, makeClasses} from 'theme'
import Grid from 'components/Grid'
import Body1 from 'components/Body1'
import Subtitle2 from 'components/Subtitle2'
import Button from 'components/Button'
import Paper from 'components/Paper'
import ApplicationStatusUpdateSelect from 'components/ApplicationStatusUpdateSelect'
import Link from 'components/Link'
import {editApplicationPath} from 'components/TopLevelRoutes'
import {addTranslationHelpers} from 'utils/i18n'
import {PERSON_QUERY} from 'graphql/queries'
import {addRightColumnContext} from 'components/EditPersonForm/rightColumnContext'
import {getApplicationName} from 'utils/application'
import {BenefitType} from 'utils/benefits'
import {isClosedOrVoid} from 'utils/applicationStatuses'
import {DeterminedEligibilitiesForBenefit} from 'utils/form/determiners'
import {toEasternTime} from 'utils/date'
import typedAs from 'utils/typedAs'
import {Person_person_openApplications} from 'graphql/deserializedTypes/Person'
import {ApplicationFields} from 'graphql/deserializedTypes/ApplicationFields'
import {ApplicationForStatusUpdateFields} from 'graphql/deserializedTypes/ApplicationForStatusUpdateFields'
import {addCreateApplicationDialog} from 'components/CreateApplicationDialog'
import {CHARITY_CARE_INCOME_ANNUAL_NUM_MONTHS} from 'utils/income'

const classes = makeClasses((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(4),
  },
  noResults: {
    marginTop: theme.spacing(2),
  },
  applicationsHeader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  applicationItemContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  topRowContainer: {
    marginBottom: theme.spacing(2),
  },
  statusSelect: {
    maxWidth: 270,
  },
  checkEligibilityButton: {
    maxWidth: 175,
    lineHeight: 1.2,
    padding: '3px 8px',
    marginTop: theme.spacing(1),
  },
  determinedEligibilityForMonthContainer: {
    marginBottom: theme.spacing(1),
  },
  month: {
    width: 140,
  },
  eligible: {
    color: '#219653',
  },
  ineligible: {
    color: '#fd0000',
  },
  secondaryInfo: {
    color: '#828282',
  },
  lastChecked: {
    color: '#828282',
    fontStyle: 'italic',
    marginTop: theme.spacing(1),
  },
  applicationStubHeader: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2),
  },
}))

interface DeterminedEligibilityForMonthProps {
  determinedEligibility: DeterminedEligibilitiesForBenefit | undefined
  benefit: BenefitType
}

const DeterminedEligibilityForMonth: FC<DeterminedEligibilityForMonthProps> = flowMax(
  addDisplayName('DeterminedEligibilityForMonth'),
  branchIfNullish('determinedEligibility'),
  addClasses(classes),
  addWrapper((render, {classes}) => (
    <Grid
      container
      direction="row"
      className={classes.determinedEligibilityForMonthContainer}
    >
      {render()}
    </Grid>
  )),
  addTranslationHelpers,
  branch(
    ({benefit}) => benefit === 'slide',
    returns(
      ({determinedEligibility: {reasonOrInfo, secondaryInfo}, classes}) => (
        <div>
          <Body1 className={classes.eligible}>{reasonOrInfo}</Body1>
          <Body1 className={classes.secondaryInfo}>{secondaryInfo}</Body1>
        </div>
      )
    )
  ),
  ({
    determinedEligibility: {
      reasonOrInfo,
      isEligible,
      secondaryInfo,
      month,
      numMonths,
    },
    classes,
    t,
  }) => (
    <>
      <Body1 className={classes.month}>
        {numMonths === CHARITY_CARE_INCOME_ANNUAL_NUM_MONTHS
          ? t('personForm.applications.lastMonthAnnual')
          : numMonths
          ? t('personForm.applications.lastMonths', {count: numMonths})
          : month
          ? format('MMMM yyyy')(month)
          : ''}
      </Body1>
      <div>
        <Body1 className={isEligible ? classes.eligible : classes.ineligible}>
          {isEligible
            ? t('personForm.applications.eligible', {
                info: reasonOrInfo,
              })
            : t('personForm.applications.ineligible', {
                reason: reasonOrInfo,
              })}
        </Body1>
        <Body1 className={classes.secondaryInfo}>{secondaryInfo}</Body1>
      </div>
    </>
  )
)

const getRefetchQueries = ({
  personId,
  benefit,
  status,
}: {
  personId: string
  benefit: BenefitType
  status: string
}): any[] =>
  isClosedOrVoid({benefit, status})
    ? [
        {
          query: PERSON_QUERY,
          variables: {id: personId},
        },
      ]
    : []

interface LastCheckedProps {
  text: string | null
}

export const LastChecked: FC<LastCheckedProps> = flowMax(
  addDisplayName('LastChecked'),
  branchIfNullish('text'),
  addClasses(classes),
  ({text, classes}) => <Body1 className={classes.lastChecked}>{text}</Body1>
)

export const getLastChecked = ({
  eligibilityDeterminations,
  t,
}: {
  eligibilityDeterminations: {createdAt: Date}[]
  t: TFunction
}): string | null =>
  eligibilityDeterminations.length
    ? t('personForm.eligibilityCheck.lastChecked', {
        time: format('M/d/yy HH:mm')(
          toEasternTime(eligibilityDeterminations[0].createdAt)
        ),
      })
    : null

interface ApplicationTopRowProps {
  application: ApplicationFields & ApplicationForStatusUpdateFields
}

const ApplicationTopRow: FC<ApplicationTopRowProps> = flowMax(
  addDisplayName('ApplicationTopRow'),
  addRightColumnContext,
  addMemoBoundary(['application', 'person.id']),
  addTranslationHelpers,
  addClasses(classes),
  ({application, person: {id: personId}, classes, t}) => (
    <Grid
      container
      alignItems="center"
      direction="row"
      justify="space-between"
      wrap="nowrap"
      className={classes.topRowContainer}
    >
      <Body1>
        <Link
          highlight
          to={generatePath(editApplicationPath, {id: application.id})}
        >
          {getApplicationName({...application, t})}
        </Link>
      </Body1>
      <div className={classes.statusSelect}>
        <ApplicationStatusUpdateSelect
          application={application}
          refetchQueries={(benefit, status) =>
            getRefetchQueries({personId, benefit, status})
          }
        />
      </div>
    </Grid>
  )
)

type BenefitTypeWithEligibilityDeterminations =
  | BenefitType.medicaid
  | BenefitType.charityCare
  | BenefitType.slide

export const getIsBenefitTypeWithEligibilityDeterminations = (
  benefit: BenefitType
): benefit is BenefitTypeWithEligibilityDeterminations =>
  ['medicaid', 'charityCare', 'slide'].includes(benefit)

interface ItemProps {
  application: Person_person_openApplications
}

const ApplicationEligibilityCheckItem: FC<ItemProps> = flowMax(
  addDisplayName('ApplicationEligibilityCheckItem'),
  addMemoBoundary(['application']),
  addClasses(classes),
  addTranslationHelpers,
  addWrapper((render, {application, classes}) => (
    <Paper
      className={classes.applicationItemContainer}
      data-testid={`application-${application.id}`}
    >
      <ApplicationTopRow application={application} />
      {render()}
    </Paper>
  )),
  addProps(({application: {benefit}}) => ({
    benefit,
  })),
  branchIfFailsPredicate(
    'benefit',
    getIsBenefitTypeWithEligibilityDeterminations
  ),
  addProps(
    ({application: {eligibilityDeterminations}, t}) => ({
      lastChecked: getLastChecked({eligibilityDeterminations, t}),
    }),
    ['application', 't']
  ),
  addWrapper((render, {lastChecked}) => (
    <>
      <Grid container direction="column">
        {render()}
      </Grid>
      <Grid container direction="row" justify="space-between">
        <LastChecked text={lastChecked} />
      </Grid>
    </>
  )),
  addRightColumnContext,
  addProps(
    ({
      determinedEligibilitiesMedicaidFull,
      determinedEligibilitiesCharityCareFull,
      determinedEligibilitiesSlideFull,
      householdSizeDescriptionMedicaidFull,
      householdSizeDescriptionCharityCareFull,
      householdSizeDescriptionSlideFull,
      benefit,
    }) => ({
      determinedEligibilities: typedAs<DeterminedEligibilitiesForBenefit[]>(
        {
          medicaid: determinedEligibilitiesMedicaidFull,
          charityCare: determinedEligibilitiesCharityCareFull,
          slide: determinedEligibilitiesSlideFull,
        }[benefit]
      ),
      householdSizeDescription: {
        medicaid: householdSizeDescriptionMedicaidFull,
        charityCare: householdSizeDescriptionCharityCareFull,
        slide: householdSizeDescriptionSlideFull,
      }[benefit],
    }),
    [
      'determinedEligibilitiesMedicaidFull',
      'determinedEligibilitiesCharityCareFull',
      'determinedEligibilitiesSlideFull',
      'householdSizeDescriptionMedicaidFull',
      'householdSizeDescriptionCharityCareFull',
      'householdSizeDescriptionSlideFull',
      'benefit',
    ]
  ),
  addMemoBoundary([
    'determinedEligibilities',
    'householdSizeDescription',
    'benefit',
  ]),
  branch(
    ({determinedEligibilities}) => !determinedEligibilities.length,
    renderNothing()
  ),
  branch(
    ({determinedEligibilities}) =>
      !determinedEligibilities.filter(({isEligible}) => isEligible != null)
        .length,
    returns(({determinedEligibilities, t}) => (
      <Body1>
        {determinedEligibilities.some(({reasonOrInfo}) => !!reasonOrInfo)
          ? t('personForm.eligibilityCheck.undecidedWithReason', {
              reason: determinedEligibilities.find(
                ({reasonOrInfo}) => reasonOrInfo
              )!.reasonOrInfo,
            })
          : t('personForm.eligibilityCheck.undecided')}
      </Body1>
    ))
  ),
  addProps(
    ({benefit}) => ({
      determinerNames:
        benefit === 'medicaid'
          ? ['medicaid', 'medicaid-lastMonth']
          : benefit === 'charityCare'
          ? [
              'charityCare',
              'charityCare-last3Months',
              'charityCare-last12Months',
              'charityCare-annual',
            ]
          : [benefit],
    }),
    ['benefit']
  ),
  ({
    determinedEligibilities,
    householdSizeDescription,
    determinerNames,
    benefit,
  }) => (
    <Grid container direction="column">
      <Body1>{householdSizeDescription}</Body1>
      {determinerNames.map((determinerName) => (
        <DeterminedEligibilityForMonth
          determinedEligibility={determinedEligibilities.find(
            ({name}) => name === determinerName
          )}
          benefit={benefit}
          key={determinerName}
        />
      ))}
    </Grid>
  )
)

interface ApplicationStubProps {
  application: ApplicationFields & ApplicationForStatusUpdateFields
  header: string
}

const ApplicationStub: FC<ApplicationStubProps> = flowMax(
  addDisplayName('ApplicationStub'),
  addClasses(classes),
  ({application, header, classes}) => (
    <Paper
      className={classes.applicationItemContainer}
      data-testid={`application-${application.id}`}
    >
      <div className={classes.applicationStubHeader}>{header}</div>
      <ApplicationTopRow application={application} />
    </Paper>
  )
)

interface HouseholdMemberApplicationProps {
  application: ApplicationFields & ApplicationForStatusUpdateFields
}

const HouseholdMemberApplication: FC<HouseholdMemberApplicationProps> = flowMax(
  addDisplayName('HouseholdMemberApplication'),
  addMemoBoundary(['application']),
  addTranslationHelpers,
  ({application, t}) => (
    <ApplicationStub
      application={application}
      header={t('personForm.applications.householdMember')}
    />
  )
)

interface PrimaryPointOfContactApplicationProps {
  application: ApplicationFields & ApplicationForStatusUpdateFields
}

const PrimaryPointOfContactApplication: FC<PrimaryPointOfContactApplicationProps> = flowMax(
  addDisplayName('PrimaryPointOfContactApplication'),
  addMemoBoundary(['application']),
  addTranslationHelpers,
  ({application, t}) => (
    <ApplicationStub
      application={application}
      header={t('personForm.applications.pointOfContact')}
    />
  )
)

const TopRow: FC = flowMax(
  addDisplayName('TopRow'),
  addCreateApplicationDialog,
  addMemoBoundary(['person.id', 'onApplicationCreate']),
  addClasses(classes),
  addTranslationHelpers,
  ({showCreateApplicationDialog, classes, t}) => (
    <Grid container direction="row" justify="space-between">
      <Subtitle2 className={classes.applicationsHeader}>
        {t('personForm.applications.listTitle')}
      </Subtitle2>
      <Button
        color="primary"
        startIcon={<AddIcon />}
        onClick={showCreateApplicationDialog}
      >
        {t('personForm.applications.createApplication')}
      </Button>
    </Grid>
  )
)

const ApplicationEligibilityCheckList: FC = flowMax(
  addDisplayName('ApplicationEligibilityCheckList'),
  addMemoBoundary([]),
  addTranslationHelpers,
  addClasses(classes),
  addWrapper((render, {classes}) => (
    <Grid className={classes.container}>
      <TopRow />
      {render()}
    </Grid>
  )),
  addRightColumnContext,
  addMemoBoundary([
    'person.openApplications',
    'person.openHouseholdMemberApplications',
    'person.openPrimaryPointOfContactApplications',
  ]),
  addProps(
    ({
      person: {
        openApplications,
        openHouseholdMemberApplications,
        openPrimaryPointOfContactApplications,
      },
    }) => ({
      applications: openApplications,
      householdMemberApplications: openHouseholdMemberApplications,
      primaryPointOfContactApplications: openPrimaryPointOfContactApplications,
    })
  ),
  branch(
    ({
      applications,
      householdMemberApplications,
      primaryPointOfContactApplications,
    }) =>
      !applications.length &&
      !householdMemberApplications.length &&
      !primaryPointOfContactApplications.length,
    returns(({classes, t}) => (
      <Body1 className={classes.noResults}>
        {t('personForm.applications.noResults')}
      </Body1>
    ))
  ),
  ({
    applications,
    householdMemberApplications,
    primaryPointOfContactApplications,
  }) => (
    <>
      {applications.map((application) => (
        <ApplicationEligibilityCheckItem
          application={application}
          key={application.id}
        />
      ))}
      {householdMemberApplications.map((application) => (
        <HouseholdMemberApplication
          application={application}
          key={application.id}
        />
      ))}
      {primaryPointOfContactApplications.map((application) => (
        <PrimaryPointOfContactApplication
          application={application}
          key={application.id}
        />
      ))}
    </>
  )
)

export default ApplicationEligibilityCheckList
