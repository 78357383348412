import {getContextHelpers, toObjectKeys} from 'ad-hok-utils'

import {
  DeterminedEligibilitiesForBenefit,
  DeterminedEligibilitiesForFullMedicaid,
  Determiner,
  DeterminedEligibilitiesForFullCharityCare,
  DeterminedEligibilitiesForFullSlide,
} from 'utils/form/determiners'
import {Person_person} from 'graphql/deserializedTypes/Person'
import {OnSubmitSuccessOptions} from 'components/Form'
import {ExtractFormSchemaFields} from 'utils/form/schema'
import {createApplicationFormSchemaStatic} from 'components/CreateApplicationDialog/schema'
import {CreateApplication} from 'graphql/types/CreateApplication'

interface RightColumnContextValue {
  person: Person_person
  determinedEligibilitiesPreliminary: DeterminedEligibilitiesForBenefit[]
  householdSizeDescriptionMedicaidPreliminary: string
  householdSizeDescriptionCharityCarePreliminary: string
  onApplicationCreate: (
    opts: OnSubmitSuccessOptions<
      ExtractFormSchemaFields<typeof createApplicationFormSchemaStatic>,
      CreateApplication
    >
  ) => void
  determinedEligibilitiesMedicaidFull: DeterminedEligibilitiesForFullMedicaid[]
  determinedEligibilitiesCharityCareFull: DeterminedEligibilitiesForFullCharityCare[]
  determinedEligibilitiesSlideFull: DeterminedEligibilitiesForFullSlide[]
  householdSizeDescriptionMedicaidFull: string
  householdSizeDescriptionCharityCareFull: string
  householdSizeDescriptionSlideFull: string
  hasCharityCareApplication: boolean
  incomeSourceFormDeterminers: Determiner[]
}

const {
  addProvider: addRightColumnContextProvider,
  addConsumer: addRightColumnContext,
} = getContextHelpers<RightColumnContextValue>(
  toObjectKeys([
    'person',
    'determinedEligibilitiesPreliminary',
    'householdSizeDescriptionMedicaidPreliminary',
    'householdSizeDescriptionCharityCarePreliminary',
    'onApplicationCreate',
    'determinedEligibilitiesMedicaidFull',
    'determinedEligibilitiesCharityCareFull',
    'determinedEligibilitiesSlideFull',
    'householdSizeDescriptionMedicaidFull',
    'householdSizeDescriptionCharityCareFull',
    'householdSizeDescriptionSlideFull',
    'hasCharityCareApplication',
    'incomeSourceFormDeterminers',
  ])
)

export {addRightColumnContextProvider, addRightColumnContext}
