import {MutationFunction} from '@apollo/react-common'
import {PureQueryOptions} from 'apollo-boost'
import {getContextHelpers, toObjectKeys} from 'ad-hok-utils'

import {
  Application_application_MonitorApplication,
  Application_application_MedicaidApplication,
  Application_application_CharityCareApplication,
  Application_application_SlideApplication,
} from 'graphql/deserializedTypes/Application'
import {
  // UpdateApplication,
  UpdateApplicationVariables,
} from 'graphql/deserializedTypes/UpdateApplication'
import {UpdateApplication} from 'graphql/types/UpdateApplication'

type MutateUpdateApplicationType = MutationFunction<
  UpdateApplication,
  UpdateApplicationVariables
>

interface ApplicationFormContextValue {
  application:
    | Application_application_MonitorApplication
    | Application_application_MedicaidApplication
    | Application_application_CharityCareApplication
    | Application_application_SlideApplication
  onUpdateSuccess: () => void
  mutateUpdateApplication: MutateUpdateApplicationType
  refetchQueriesOnStatusChange: () => PureQueryOptions[]
  hasNecessaryDocuments: boolean
  setHasNecessaryDocumentsByPersonId: (
    personId: string | null,
    hasNecessaryDocuments: boolean
  ) => void
}

const [
  addApplicationFormContextProvider,
  addApplicationFormContext,
] = getContextHelpers<ApplicationFormContextValue>(
  toObjectKeys([
    'application',
    'onUpdateSuccess',
    'mutateUpdateApplication',
    'refetchQueriesOnStatusChange',
    'hasNecessaryDocuments',
    'setHasNecessaryDocumentsByPersonId',
  ])
)

export {addApplicationFormContextProvider, addApplicationFormContext}
