import React, {FC} from 'react'
import {
  Select,
  MenuItem,
  FormControl,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  Paper,
} from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'
import {
  flowMax,
  addDisplayName,
  addState,
  addProps,
  addStateHandlers,
  addHandlers,
  addMemoBoundary,
  SimplePropsAdder,
} from 'ad-hok'
import SendIcon from '@material-ui/icons/Send'
import TextField from '@material-ui/core/TextField'
import SendSharp from '@material-ui/icons/SendSharp'
import {
  branchIfEmpty,
  branchIfNullish,
  addInterval,
  getContextHelpersFromInitialValues,
} from 'ad-hok-utils'

import {addTranslationHelpers} from 'utils/i18n'
import {makeClasses, addClasses} from 'theme'
import Button from 'components/Button'
import Grid from 'components/Grid'
import {addLoadingIndicator} from 'utils/dataLoading'
import {getFormattedPhoneNumber} from 'utils/phone'
import {addFormContext} from 'utils/form/context'
import {addFormik} from 'utils/form/formik'
import {getLongDate} from 'utils/date'
import {addFormPrefixContext} from '../FormPrefix'
import addDialogState from 'utils/addDialogState'
import {PersonFields_phoneNumbers} from 'graphql/deserializedTypes/PersonFields'
import IconButton from 'components/IconButton'
import SendWebformDialog from 'components/SendWebformDialog'
import {addWebformsContext} from 'components/EditPersonForm/webformsContext'
import {
  addSendDocumentRequestsMutation,
  addTextMessagesQuery,
  addSentWebFormsQuery,
  addUpdatePersonMessageReadMutation,
} from 'graphql/generated'
import {addAppSnackbarContext} from 'utils/addAppSnackbar'
import Tooltip from 'components/Tooltip'
import {
  Person_person,
  Person_person_phoneNumbers,
} from 'graphql/deserializedTypes/Person'
import {addRightColumnContext} from 'components/EditPersonForm/rightColumnContext'
import {TEXT_MESSAGES_QUERY} from 'graphql/queries'
import Box from 'components/Box'
import {formatShortDateTimeEastern} from 'utils/date'
import Body1 from 'components/Body1'

const classes = makeClasses((theme) => ({
  boxBorder: {
    color: '#000000',
    width: 375,
    border: '1px solid lightgrey',
    borderRadius: 8,
    padding: 7,
    margin: 1,
  },
  phoneNumberRowContainer: {
    height: 38,
    whiteSpace: 'nowrap',
  },
  phoneNumbersContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(-1),
  },
  phoneNumberInfo: {
    position: 'relative',
    top: 6,
    left: 4,
  },
  phoneNumberEsignIcon: {
    position: 'relative',
    top: -2,
  },
  container: {
    marginBottom: theme.spacing(2),
  },
  feedbackIconAdd: {
    position: 'relative',
    top: -5,
  },
  sendWebformButtonContainer: {
    marginLeft: 'auto',
  },
  textField: {
    rows: 1,
    variant: 'outlined',
    margin: 'normal',
  },
  tableHeader: {
    backgroundColor: '#3f51b5',
    color: 'white',
  },
  tableCellHeader: {
    padding: '0px 5px 0px 0px',
    color: 'white',
  },
  tableCellHeaderIcon: {
    color: 'white',
  },
  tableCell: {
    padding: '0px 5px 0px 0px',
    fontSize: '11px',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#dfe3e8',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
}))

interface FormatPhoneDetailsOptions {
  language?: string | null
}

// const formatPhoneDetails = ({language}: FormatPhoneDetailsOptions) =>
//   language ?? ''

type PhoneNumbersType = PersonFields_phoneNumbers

interface SendWebformIconProps {
  onClick: () => void
  phoneNumber: {
    id: string | undefined
  }
}

const SendWebformIcon: FC<SendWebformIconProps> = flowMax(
  addDisplayName('SendWebformIcon'),
  addWebformsContext,
  branchIfEmpty('webforms'),
  addClasses(classes),
  addTranslationHelpers,
  addProps(({phoneNumber: {id}}) => ({
    id,
  })),
  branchIfNullish('id', {
    returns: ({t, classes}) => (
      <Tooltip
        title={t('sendWebformIcon.disabled')}
        className={classes.sendWebformButtonContainer}
      >
        <span>
          <IconButton onClick={() => {}} disabled>
            <SendIcon />
          </IconButton>
        </span>
      </Tooltip>
    ),
  }),
  ({onClick, classes}) => (
    <IconButton
      onClick={onClick}
      className={classes.sendWebformButtonContainer}
    >
      <SendIcon />
    </IconButton>
  )
)

const [
  addShouldShowApplicationPromptContextProvider,
  addShouldShowApplicationPromptContext,
] = getContextHelpersFromInitialValues({
  shouldShowApplicationPrompt: false,
})

type AddShouldShowApplicationPromptContextProviderFromRightColumnContext = <
  TProps extends {
    person: Person_person
  }
>(
  props: TProps
) => TProps

export const addShouldShowApplicationPromptContextProviderFromRightColumnContext: AddShouldShowApplicationPromptContextProviderFromRightColumnContext = flowMax(
  addProps(
    ({person: {openApplications}}) => ({
      shouldShowApplicationPrompt: !openApplications.length,
    }),
    ['person']
  ),
  addShouldShowApplicationPromptContextProvider
)

type AddShouldShowApplicationPrompt = SimplePropsAdder<{
  shouldShowApplicationPrompt: boolean
}>

export const addShouldShowApplicationPrompt: AddShouldShowApplicationPrompt = flowMax(
  addShouldShowApplicationPromptContext
)

interface PhoneNumberItemProps {
  phoneNumber?: PhoneNumbersType
  formPrefix: string
  personId: string
  phoneNumbers: Person_person_phoneNumbers[]
}

const PhoneNumberItem: FC<PhoneNumberItemProps> = flowMax(
  addDisplayName('PhoneNumberItem'),
  addAppSnackbarContext,
  addFormik,
  addMemoBoundary(['phoneNumber', 'index', 'formPrefix']),
  addClasses(classes),
  addState('selected', 'setSelected', 0),
  addState('isSubmitting', 'setIsSubmitting', false),
  addState('textMessage', 'setTextMessage', ''),
  addState('applicationId', 'setApplicationId', ''),
  addSendDocumentRequestsMutation({}),
  addStateHandlers(
    {
      isShowingEditDialog: false,
      isShowingDeleteDialog: false,
      isShowingSendWebformDialog: false,
      requestorIds: new Set<string>(),
      workflowIds: new Set<string>(),
      documentIds: new Set<string>(),
    },
    {
      showEditDialog: () => () => ({isShowingEditDialog: true}),
      hideEditDialog: () => () => ({isShowingEditDialog: false}),
      showDeleteDialog: () => () => ({isShowingDeleteDialog: true}),
      hideDeleteDialog: () => () => ({isShowingDeleteDialog: false}),
      showSendWebformDialog: () => () => ({isShowingSendWebformDialog: true}),
      hideSendWebformDialog: () => () => ({isShowingSendWebformDialog: false}),
    }
  ),
  addFormContext,
  addHandlers({
    selectChangeHandler2: ({setSelected}) => (
      event: React.ChangeEvent<{value: unknown}>
    ) => {
      const status = event.target.value as number
      setSelected(status)
      console.log('Selected Index2:' + status)
    },
    onSave: ({
      requestorIds,
      documentIds,
      mutateSendDocumentRequests,
      showSnackbarMessage,
      setIsSubmitting,
      applicationId,
      personId,
      phoneNumber,
      phoneNumbers,
      textMessage,
      setTextMessage,
      selected,
    }) => () => {
      setIsSubmitting(true)
      setTextMessage(textMessage)
      phoneNumber = phoneNumbers[selected]
      mutateSendDocumentRequests({
        variables: {
          esignSessionId: phoneNumber.esignSessionId!,
          phoneNumber: phoneNumber.number,
          language: phoneNumber.language,
          applicationId,
          personId,
          requestorIds: Array.from(requestorIds),
          documentIds: Array.from(documentIds),
          smsMessage: textMessage,
        },
      })
        .then(() => {
          showSnackbarMessage('Message Request')
        })
        .catch(() => {
          window.alert('Failed to Send Message')
        })
        .then(() => {
          setIsSubmitting(false)
        })
      textMessage = ''
      setTextMessage(textMessage)
    },
  }),
  addShouldShowApplicationPrompt,
  addTranslationHelpers,
  ({
    classes,
    showSendWebformDialog,
    isShowingSendWebformDialog,
    hideSendWebformDialog,
    textMessage,
    setTextMessage,
    onSave,
    phoneNumbers,
    selected,
    selectChangeHandler2,
  }) => (
    <>
      <FormControl style={{minWidth: 150}} size="medium" margin="dense">
        <Select
          id="phone-select"
          value={selected}
          onChange={selectChangeHandler2}
          variant="outlined"
        >
          {phoneNumbers.flatMap((phoneNumber, index) => (
            <MenuItem value={index} selected={true} key={index}>
              {getFormattedPhoneNumber(phoneNumber.number)}
              <b>
                {phoneNumber.comment?.length
                  ? ' - ' + phoneNumber.comment?.substring(0, 15)
                  : ''}
              </b>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {phoneNumbers.map((phoneNo, index) => {
        if (index === selected) {
          return (
            <>
              <SendWebformIcon
                onClick={showSendWebformDialog}
                phoneNumber={phoneNo}
              />
              <SendWebformDialog
                open={isShowingSendWebformDialog}
                onCancel={hideSendWebformDialog}
                phoneNumber={phoneNo}
              />
              <Grid>
                <FormControl component="fieldset" variant="standard">
                  <TextField
                    className={classes.textField}
                    label="Text Message"
                    id="TextMessage"
                    variant="outlined"
                    value={textMessage}
                    multiline
                    onChange={({target: {value}}) => setTextMessage(value)}
                  />
                </FormControl>
                &nbsp;&nbsp;
                <FormControl component="fieldset" variant="standard">
                  <Button
                    onClick={onSave}
                    variant="contained"
                    color="primary"
                    disabled={!textMessage}
                  >
                    <SendSharp />
                  </Button>
                </FormControl>
              </Grid>
            </>
          )
        } else return null
      })}
    </>
  )
)

// Start of Person Pending Forms Section

interface PersonPendingFormsRequestProps {
  personId: string
}

const PersonPendingFormsRequest: FC<PersonPendingFormsRequestProps> = flowMax(
  addDisplayName('PersonPendingForms'),
  addAppSnackbarContext,
  addSentWebFormsQuery({
    variables: ({personId}) => ({personId}),
  }),
  addLoadingIndicator({}),
  addClasses(classes),
  addTranslationHelpers,
  ({classes, sentWebForms}) => (
    <div>
      <Box className={classes.boxBorder}>
        <Grid container>
          <Typography style={{fontStyle: 'Bold'}}>Pending Request</Typography>
          {sentWebForms.length > 0 && (
            <TableContainer component={Paper} style={{maxHeight: 300}}>
              <Table aria-label="Document Request Table">
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell
                      className={classes.tableCellHeader}
                      align="center"
                    >
                      Document&nbsp;
                    </TableCell>
                    <TableCell
                      className={classes.tableCellHeader}
                      align="center"
                      style={{minWidth: 75}}
                    >
                      Phone
                    </TableCell>
                    <TableCell
                      className={classes.tableCellHeader}
                      align="center"
                    >
                      Sent&nbsp;
                    </TableCell>
                    <TableCell
                      className={classes.tableCellHeader}
                      align="center"
                    >
                      Request By
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sentWebForms.map((sentWebForm) => (
                    <TableRow className={classes.tableRow} key={sentWebForm.id}>
                      <TableCell className={classes.tableCell}>
                        {sentWebForm.webFormName}
                      </TableCell>

                      <TableCell
                        className={classes.tableCell}
                        style={{width: 75}}
                      >
                        {sentWebForm.phoneNumber}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getLongDate(sentWebForm.createdAt)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {!!sentWebForm.requestInitiatedBy?.id
                          ? sentWebForm.requestInitiatedBy?.name
                          : 'System'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {sentWebForms.length === 0 && (
            <Typography style={{fontStyle: 'italic'}}>
              &nbsp;&nbsp;- None
            </Typography>
          )}
        </Grid>
      </Box>
    </div>
  )
)

export const MESSAGE_POLL_INTERVAL = 30 * 1000

interface PersonMessageSectionProps {
  personId: string
  unreadMessageStatus: boolean
}

const PersonMessageSection: FC<PersonMessageSectionProps> = flowMax(
  addDisplayName('CommunicationHistorySection'),
  addAppSnackbarContext,
  addState('open', 'setOpen', false),
  addTextMessagesQuery({
    variables: ({personId}) => ({personId}),
  }),
  addLoadingIndicator({}),
  addInterval(
    ({refetchTextMessages}) => () => {
      refetchTextMessages()
    },
    MESSAGE_POLL_INTERVAL,
    ['personId']
  ),
  addUpdatePersonMessageReadMutation({}),
  addProps(
    ({personId}) => ({
      refetchQueries: [
        {
          query: TEXT_MESSAGES_QUERY,
          variables: {personId},
        },
      ],
    }),
    ['personId']
  ),
  addHandlers({
    updateStatus: ({
      personId,
      mutateUpdatePersonMessageRead,
      setOpen,
      open,
    }) => () => {
      console.log(personId)
      mutateUpdatePersonMessageRead({
        variables: {
          personId,
        },
      }).catch(() => {
        window.alert('Failed to Update Read Status')
      })
      setOpen(!open)
    },
  }),
  addClasses(classes),
  addTranslationHelpers,
  ({classes, textMessages, open, updateStatus, unreadMessageStatus}) => (
    <div>
      <Box className={classes.boxBorder}>
        <Grid container style={{maxHeight: 400}}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={updateStatus}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <Typography variant={unreadMessageStatus === true ? 'h6' : 'body1'}>
            Text Message(s){' '}
            {unreadMessageStatus === true ? <MessageOutlinedIcon /> : ''}
          </Typography>
          {textMessages.length === 0 && (
            <Typography style={{fontStyle: 'italic'}}>
              &nbsp;&nbsp;- None
            </Typography>
          )}
          <TableContainer component={Paper} style={{maxHeight: 300}}>
            <Table aria-label="Document Request Table">
              <TableBody>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  {textMessages.map((message, key) => (
                    <Card variant="outlined" key={key} style={{width: 360}}>
                      <CardContent
                        style={{
                          alignContent:
                            message.messageType === 'Send' ? 'right' : 'left',
                          padding: 0,
                          margin: 4,
                          width: 335,
                        }}
                      >
                        <Typography
                          color="secondary"
                          gutterBottom
                          style={{
                            textAlign:
                              message.messageType === 'Send' ? 'right' : 'left',
                            color:
                              message.messageType === 'Send'
                                ? '#8d6949'
                                : '#6a4b85',
                            fontSize: '12px',
                          }}
                        >
                          {message.applicationId
                            ? 'App ID:' + message.applicationId
                            : ''}
                          {message.messageInitiatedBy?.name
                            ? ' [' + message.messageInitiatedBy.name + ']  '
                            : ''}
                          {message.phoneNumber
                            ? getFormattedPhoneNumber(message.phoneNumber) +
                              '  '
                            : ''}
                          {formatShortDateTimeEastern(message.createdAt)}
                        </Typography>
                        <Typography
                          color="secondary"
                          style={{
                            textAlign:
                              message.messageType === 'Send' ? 'right' : 'left',
                            color:
                              message.messageType === 'Send'
                                ? '#8d6949'
                                : '#6a4b85',
                            fontSize: '12px',
                            alignContent: 'left',
                          }}
                        >
                          <b color="#F8FD99">{message.message}</b>
                        </Typography>
                      </CardContent>
                    </Card>
                  ))}
                </Collapse>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>
    </div>
  )
)

interface Props {
  name: string
}

const PersonCommunicationSection: FC<Props> = flowMax(
  addDisplayName('PhoneFieldArray'),
  addFormPrefixContext,

  addState('selected', 'setSelected', 0),
  addTranslationHelpers,
  addHandlers({
    selectChangeHandler: ({setSelected}) => (
      event: React.ChangeEvent<{value: unknown}>
    ) => {
      const status = event.target.value as number
      setSelected(status)
    },
  }),
  addRightColumnContext,
  addProps(({person: {openApplications, phoneNumbers}}) => ({
    applications: openApplications,
    phoneNumbersUsedForEsign: phoneNumbers.filter(
      ({usedForEsign}) => usedForEsign
    ),
  })),
  addFormContext,
  addStateHandlers(
    {
      isShowingEditDialog: false,
      isShowingDeleteDialog: false,
      isShowingSendWebformDialog: false,
    },
    {
      showEditDialog: () => () => ({isShowingEditDialog: true}),
      hideEditDialog: () => () => ({isShowingEditDialog: false}),
      showDeleteDialog: () => () => ({isShowingDeleteDialog: true}),
      hideDeleteDialog: () => () => ({isShowingDeleteDialog: false}),
      showSendWebformDialog: () => () => ({isShowingSendWebformDialog: true}),
      hideSendWebformDialog: () => () => ({isShowingSendWebformDialog: false}),
    }
  ),
  addMemoBoundary(['name', 'items', 'formPrefix', 'formName']),
  addDialogState,

  addProps(({formName, name, t}) => ({
    addLabel: t(`${formName}.collectionControls.${name}.add`),
    noItemsLabel: t(`${formName}.collectionControls.${name}.noItems`),
  })),
  addClasses(classes),
  ({classes, phoneNumbersUsedForEsign, formPrefix, person}) => (
    <Grid className={classes.container}>
      <Body1>
        {person.firstName} {person.lastName}
      </Body1>
      {phoneNumbersUsedForEsign.length === 0 && (
        <b style={{color: 'red'}}>Please add/enable Client Phone for E-Sign</b>
      )}
      {phoneNumbersUsedForEsign.length !== 0 && (
        <>
          <PhoneNumberItem
            formPrefix={formPrefix}
            personId={person.id}
            phoneNumbers={phoneNumbersUsedForEsign}
          />
        </>
      )}
      <PersonPendingFormsRequest personId={person.id} />
      <PersonMessageSection
        personId={person.id}
        unreadMessageStatus={!!person.unreadMessageStatus}
      />
    </Grid>
  )
)

export default PersonCommunicationSection
