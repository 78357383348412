/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {NjmmisCheckResult, Benefit} from './globalTypes' // ====================================================
// GraphQL query operation: People
// ====================================================

import {PeopleVariables as PeopleVariablesOrig} from '../types/People'
import {People as PeopleOrig} from '../types/People'
import {People_people as People_peopleOrig} from '../types/People'
import {AdditionalData} from 'utils/getAdditionalData'
import {parseAdditionalData} from 'utils/getAdditionalData'
import {serializeAdditionalData} from 'utils/getAdditionalData'
import {People_people_mostRecentEligibilityDeterminations as People_people_mostRecentEligibilityDeterminationsOrig} from '../types/People'
import {BenefitType} from 'utils/benefits'
import {deserializeBenefit} from 'graphql/converters'
import {serializeBenefit} from 'graphql/converters'
import {People_people_openApplications as People_people_openApplicationsOrig} from '../types/People'
import {deserializeNjmmisCheckResult} from 'graphql/converters'
import {serializeNjmmisCheckResult} from 'graphql/converters'
import formatISO from 'date-fns/formatISO'
import {parseDate} from 'utils/form/fieldTypes'
import {formatISODate} from 'utils/date'
import {People_people_mostRecentAccount as People_people_mostRecentAccountOrig} from '../types/People'
import {People_people_assets as People_people_assetsOrig} from '../types/People'
import {People_people_deductions as People_people_deductionsOrig} from '../types/People'
import {People_people_incomeSources as People_people_incomeSourcesOrig} from '../types/People'
import {People_people_phoneNumbers as People_people_phoneNumbersOrig} from '../types/People'
export interface People_people_phoneNumbers {
  __typename: 'PhoneNumber'
  id: string
  number: string
  language: string | null
  comment: string | null
  usedInApp: boolean | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
}
export const deserializePeople_people_phoneNumbers = (
  serialized: People_people_phoneNumbersOrig
): People_people_phoneNumbers => ({...serialized})
export const serializePeople_people_phoneNumbers = (
  deserialized: People_people_phoneNumbers
): People_people_phoneNumbersOrig => ({...deserialized})
export interface People_people_incomeSources {
  __typename: 'IncomeSource'
  id: string
  incomeType: string | null
  employerName: string | null
  employerAddressStreet: string | null
  employerAddressCity: string | null
  employerAddressState: string | null
  employerAddressZip: string | null
  employerPhone: string | null
  employerInsurance: boolean | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  fullPartTime: string | null
  amount: number | null
  payFrequency: string | null
  usedForEsign: boolean | null
  esignSessionId: string | null
  esignSessionStatus: string | null
  esignSessionUrl: string | null
  proofOfIncome: boolean | null
  noProofOfIncomeReason: string | null
}
export const deserializePeople_people_incomeSources = (
  serialized: People_people_incomeSourcesOrig
): People_people_incomeSources => ({...serialized})
export const serializePeople_people_incomeSources = (
  deserialized: People_people_incomeSources
): People_people_incomeSourcesOrig => ({...deserialized})
export interface People_people_deductions {
  __typename: 'Deduction'
  id: string
  deductionType: string | null
  startMonth: string | null
  startYear: string | null
  endMonth: string | null
  endYear: string | null
  amount: number | null
  deductionFrequency: string | null
}
export const deserializePeople_people_deductions = (
  serialized: People_people_deductionsOrig
): People_people_deductions => ({...serialized})
export const serializePeople_people_deductions = (
  deserialized: People_people_deductions
): People_people_deductionsOrig => ({...deserialized})
export interface People_people_assets {
  __typename: 'Asset'
  id: string
  assetType: string | null
  amount: number | null
}
export const deserializePeople_people_assets = (
  serialized: People_people_assetsOrig
): People_people_assets => ({...serialized})
export const serializePeople_people_assets = (
  deserialized: People_people_assets
): People_people_assetsOrig => ({...deserialized})
export interface People_people_mostRecentAccount {
  __typename: 'Account'
  id: string
  hospitalAccountId: string | null
  dateOfService: Date
  timeOfService: string | null
  serviceTimeZone: string
  facility: string
  department: string
  location: string | null
  insuranceType: string
  status: string
  createdAt: Date
  updatedAt: Date
  notes: string | null
  accountAmount: number | null
  njmmisCheck: string | null
  previousInsurance: string | null
  invoiceMonth: string | null
  invoiceYear: string | null
  dischargeDate: Date | null
  isScheduled: boolean
}
export const deserializePeople_people_mostRecentAccount = (
  serialized: People_people_mostRecentAccountOrig
): People_people_mostRecentAccount => ({
  ...serialized,
  dateOfService: parseDate(serialized.dateOfService),
  createdAt: parseDate(serialized.createdAt),
  updatedAt: parseDate(serialized.updatedAt),
  njmmisCheck:
    serialized.njmmisCheck != null
      ? deserializeNjmmisCheckResult(serialized.njmmisCheck)
      : serialized.njmmisCheck,
  dischargeDate:
    serialized.dischargeDate != null
      ? parseDate(serialized.dischargeDate)
      : serialized.dischargeDate,
})
export const serializePeople_people_mostRecentAccount = (
  deserialized: People_people_mostRecentAccount
): People_people_mostRecentAccountOrig => ({
  ...deserialized,
  dateOfService: formatISODate(deserialized.dateOfService),
  createdAt: formatISO(deserialized.createdAt),
  updatedAt: formatISO(deserialized.updatedAt),
  njmmisCheck:
    deserialized.njmmisCheck != null
      ? serializeNjmmisCheckResult(deserialized.njmmisCheck)
      : deserialized.njmmisCheck,
  dischargeDate:
    deserialized.dischargeDate != null
      ? formatISODate(deserialized.dischargeDate)
      : deserialized.dischargeDate,
})
export interface People_people_openApplications {
  __typename:
    | 'CharityCareApplication'
    | 'MedicaidApplication'
    | 'MonitorApplication'
    | 'SlideApplication'
  id: string
  benefit: BenefitType
}
export const deserializePeople_people_openApplications = (
  serialized: People_people_openApplicationsOrig
): People_people_openApplications => ({
  ...serialized,
  benefit: deserializeBenefit(serialized.benefit),
})
export const serializePeople_people_openApplications = (
  deserialized: People_people_openApplications
): People_people_openApplicationsOrig => ({
  ...deserialized,
  benefit: serializeBenefit(deserialized.benefit),
})
export interface People_people_mostRecentEligibilityDeterminations {
  __typename: 'EligibilityDetermination'
  id: string
  benefit: string
  status: string
  reasonOrInfo: string | null
  secondaryInfo: string | null
  householdSizeDescription: string | null
  month: Date | null
  numMonths: number | null
  createdAt: Date
  additionalData: AdditionalData | null
}
export const deserializePeople_people_mostRecentEligibilityDeterminations = (
  serialized: People_people_mostRecentEligibilityDeterminationsOrig
): People_people_mostRecentEligibilityDeterminations => ({
  ...serialized,
  month:
    serialized.month != null ? parseDate(serialized.month) : serialized.month,
  createdAt: parseDate(serialized.createdAt),
  additionalData:
    serialized.additionalData != null
      ? parseAdditionalData(serialized.additionalData)
      : serialized.additionalData,
})
export const serializePeople_people_mostRecentEligibilityDeterminations = (
  deserialized: People_people_mostRecentEligibilityDeterminations
): People_people_mostRecentEligibilityDeterminationsOrig => ({
  ...deserialized,
  month:
    deserialized.month != null
      ? formatISODate(deserialized.month)
      : deserialized.month,
  createdAt: formatISO(deserialized.createdAt),
  additionalData:
    deserialized.additionalData != null
      ? serializeAdditionalData(deserialized.additionalData)
      : deserialized.additionalData,
})
export interface People_people {
  __typename: 'Person'
  id: string
  updatedAt: Date
  firstName: string | null
  middleName: string | null
  lastName: string | null
  suffix: string | null
  preferredName: string | null
  dob: Date | null
  gender: string | null
  ssn: string | null
  phoneNumbers: People_people_phoneNumbers[]
  homeAddressStreet: string | null
  homeAddressCity: string | null
  homeAddressState: string | null
  homeAddressZip: string | null
  homeAddressComment: string | null
  mailingAddressStreet: string | null
  mailingAddressCity: string | null
  mailingAddressState: string | null
  mailingAddressZip: string | null
  mailingAddressComment: string | null
  email: string | null
  emailComment: string | null
  preferredLanguage: string | null
  hospitalPatientId: string | null
  livesInNj: boolean | null
  hasInsurance: boolean | null
  ciStatus: string | null
  fiveYearsInUs: boolean | null
  specialCategory: boolean | null
  ciDocumentType: string | null
  pregnant: boolean | null
  maritalStatus: string | null
  spouseLiveWithMe: boolean | null
  expectedChild: number | null
  taxFilingStatus: string | null
  claimedBySomeoneOtherThanParents: boolean | null
  livingWithParentsWhoDoNotFileJointly: boolean | null
  estTaxDependentCount: number | null
  taxDependentInclSpouseLiveWithClient: boolean | null
  estParentLiveWithClient: number | null
  estChildren19LiveWithClient: number | null
  estMinor19SiblingLiveWithClient: number | null
  estHouseholdIncome: number | null
  incomeSources: People_people_incomeSources[]
  deductions: People_people_deductions[]
  assets: People_people_assets[]
  currentInsuranceName: string | null
  currentInsurancePolicyNo: string | null
  insuranceStartdate: Date | null
  njResidencyDoc: string | null
  ciDocumentName: string | null
  dateOfEntry: Date | null
  uscisNo: string | null
  prCardNo: string | null
  dueDate: Date | null
  spousePregnant: boolean | null
  isStudent: boolean | null
  dependentOfTaxFilerId: string | null
  changeJobInLast6Mon: string | null
  income: boolean | null
  whoSupportWithoutIncome: string | null
  othersSupportWithoutIncome: string | null
  selfSupportWithoutIncome: string | null
  insuranceLast3Months: boolean | null
  wantsCoverage: boolean | null
  alaskanNativeamerican: boolean | null
  fosterCareAfter18: boolean | null
  identityDocument: string | null
  desiredMco: string | null
  pcp: string | null
  childrenPcp: string | null
  note: string | null
  children18LiveWithClient: number | null
  minor18SiblingLiveWithClient: number | null
  pendingUsCitizenship: boolean | null
  livedInNjSinceMonth: string | null
  livedInNjSinceYear: string | null
  hasDeduction: boolean | null
  hasAsset: boolean | null
  noIncomeSinceMonth: string | null
  noIncomeSinceYear: string | null
  noAssetSinceMonth: string | null
  noAssetSinceYear: string | null
  homelessSinceMonth: string | null
  homelessSinceYear: string | null
  supporterRelationship: string | null
  supportSinceMonth: string | null
  supportSinceYear: string | null
  supportType: string[] | null
  supporterAddressStreet: string | null
  supporterAddressCity: string | null
  supporterAddressState: string | null
  supporterAddressZip: string | null
  supporterPhone: string | null
  othersAttestationWho: string | null
  othersAttestationRelationship: string | null
  othersAttestationPhone: string | null
  addressSinceMonth: string | null
  addressSinceYear: string | null
  race: string | null
  currentInsuranceType: string | null
  currentInsuranceIncludesDental: boolean | null
  noIdentityDocReason: string | null
  ownNonresidentProperty: boolean | null
  nonresidentPropertyMailingAddressStreet: string | null
  nonresidentPropertyMailingAddressCity: string | null
  nonresidentPropertyMailingAddressState: string | null
  nonresidentPropertyMailingAddressZip: string | null
  separatedSinceMonth: string | null
  separatedSinceYear: string | null
  dontLiveWithSpouse: boolean | null
  noJointAssetsWithSpouse: boolean | null
  noFinancialSupportFromSpouse: boolean | null
  notFiledJointlySince: string | null
  reasonNotFiling: string | null
  lastKnownAddressStreet: string | null
  lastKnownAddressCity: string | null
  lastKnownAddressState: string | null
  lastKnownAddressZip: string | null
  unreadMessageStatus: boolean | null
  mostRecentAccount: People_people_mostRecentAccount | null
  openApplications: People_people_openApplications[]
  mostRecentEligibilityDeterminations: People_people_mostRecentEligibilityDeterminations[]
}
export const deserializePeople_people = (
  serialized: People_peopleOrig
): People_people => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
  phoneNumbers: serialized.phoneNumbers.map(
    deserializePeople_people_phoneNumbers
  ),
  incomeSources: serialized.incomeSources.map(
    deserializePeople_people_incomeSources
  ),
  deductions: serialized.deductions.map(deserializePeople_people_deductions),
  assets: serialized.assets.map(deserializePeople_people_assets),
  insuranceStartdate:
    serialized.insuranceStartdate != null
      ? parseDate(serialized.insuranceStartdate)
      : serialized.insuranceStartdate,
  dateOfEntry:
    serialized.dateOfEntry != null
      ? parseDate(serialized.dateOfEntry)
      : serialized.dateOfEntry,
  dueDate:
    serialized.dueDate != null
      ? parseDate(serialized.dueDate)
      : serialized.dueDate,
  mostRecentAccount:
    serialized.mostRecentAccount != null
      ? deserializePeople_people_mostRecentAccount(serialized.mostRecentAccount)
      : serialized.mostRecentAccount,
  openApplications: serialized.openApplications.map(
    deserializePeople_people_openApplications
  ),
  mostRecentEligibilityDeterminations: serialized.mostRecentEligibilityDeterminations.map(
    deserializePeople_people_mostRecentEligibilityDeterminations
  ),
})
export const serializePeople_people = (
  deserialized: People_people
): People_peopleOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
  phoneNumbers: deserialized.phoneNumbers.map(
    serializePeople_people_phoneNumbers
  ),
  incomeSources: deserialized.incomeSources.map(
    serializePeople_people_incomeSources
  ),
  deductions: deserialized.deductions.map(serializePeople_people_deductions),
  assets: deserialized.assets.map(serializePeople_people_assets),
  insuranceStartdate:
    deserialized.insuranceStartdate != null
      ? formatISODate(deserialized.insuranceStartdate)
      : deserialized.insuranceStartdate,
  dateOfEntry:
    deserialized.dateOfEntry != null
      ? formatISODate(deserialized.dateOfEntry)
      : deserialized.dateOfEntry,
  dueDate:
    deserialized.dueDate != null
      ? formatISODate(deserialized.dueDate)
      : deserialized.dueDate,
  mostRecentAccount:
    deserialized.mostRecentAccount != null
      ? serializePeople_people_mostRecentAccount(deserialized.mostRecentAccount)
      : deserialized.mostRecentAccount,
  openApplications: deserialized.openApplications.map(
    serializePeople_people_openApplications
  ),
  mostRecentEligibilityDeterminations: deserialized.mostRecentEligibilityDeterminations.map(
    serializePeople_people_mostRecentEligibilityDeterminations
  ),
})
export interface People {
  /**
   * List of people
   */
  people: People_people[]
}
export const deserializePeople = (serialized: PeopleOrig): People => ({
  ...serialized,
  people: serialized.people.map(deserializePeople_people),
})
export const serializePeople = (deserialized: People): PeopleOrig => ({
  ...deserialized,
  people: deserialized.people.map(serializePeople_people),
})
export interface PeopleVariables {
  name?: string | null
  dob?: Date | null
  phoneNumber?: string | null
  hospitalPatientId?: string | null
  hospitalAccountId?: string | null
  personId?: string | null
  ssn?: string | null
  doesNotHaveApplicationOfType?: string[] | null
  openApplications?: string[] | null
  mostRecentMedicaidEligibilityDetermination?: string[] | null
  insuranceTypeOnMostRecentAccount?: string[] | null
  hasMrn?: string[] | null
  pastDateOfService?: string[] | null
  futureDateOfService?: string[] | null
  departmentOfMostRecentAccount?: string[] | null
  dischargeDate?: string[] | null
  missingBenefitOutcomeForDateOfService?: string[] | null
  unreviewedWebformIds?: string[] | null
  hasUnreadTextMessages?: string[] | null
}
export const deserializePeopleVariables = (
  serialized: PeopleVariablesOrig
): PeopleVariables => ({
  ...serialized,
  dob: serialized.dob != null ? parseDate(serialized.dob) : serialized.dob,
})
export const serializePeopleVariables = (
  deserialized: PeopleVariables
): PeopleVariablesOrig => ({
  ...deserialized,
  dob:
    deserialized.dob != null
      ? formatISODate(deserialized.dob)
      : deserialized.dob,
})
