/* eslint-disable */
/* tslint:disable */

/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.
import {EditableFileStatus} from './globalTypes' // ====================================================
// GraphQL mutation operation: MergeEditableFiles
// ====================================================

import {MergeEditableFilesVariables as MergeEditableFilesVariablesOrig} from '../types/MergeEditableFiles'
import {MergeEditableFiles as MergeEditableFilesOrig} from '../types/MergeEditableFiles'
import {parseDate} from 'utils/form/fieldTypes'
import formatISO from 'date-fns/formatISO'
import {MergeEditableFiles_mergeEditableFiles as MergeEditableFiles_mergeEditableFilesOrig} from '../types/MergeEditableFiles'
export interface MergeEditableFiles_mergeEditableFiles {
  __typename: 'EditableFile'
  id: string
  fileKey: string
  filename: string
  updatedAt: Date
  status: EditableFileStatus
  allowSigning: boolean | null
  documentSendType: string | null
}
export const deserializeMergeEditableFiles_mergeEditableFiles = (
  serialized: MergeEditableFiles_mergeEditableFilesOrig
): MergeEditableFiles_mergeEditableFiles => ({
  ...serialized,
  updatedAt: parseDate(serialized.updatedAt),
})
export const serializeMergeEditableFiles_mergeEditableFiles = (
  deserialized: MergeEditableFiles_mergeEditableFiles
): MergeEditableFiles_mergeEditableFilesOrig => ({
  ...deserialized,
  updatedAt: formatISO(deserialized.updatedAt),
})
export interface MergeEditableFiles {
  mergeEditableFiles: MergeEditableFiles_mergeEditableFiles
}
export const deserializeMergeEditableFiles = (
  serialized: MergeEditableFilesOrig
): MergeEditableFiles => ({
  ...serialized,
  mergeEditableFiles: deserializeMergeEditableFiles_mergeEditableFiles(
    serialized.mergeEditableFiles
  ),
})
export const serializeMergeEditableFiles = (
  deserialized: MergeEditableFiles
): MergeEditableFilesOrig => ({
  ...deserialized,
  mergeEditableFiles: serializeMergeEditableFiles_mergeEditableFiles(
    deserialized.mergeEditableFiles
  ),
})
export interface MergeEditableFilesVariables {
  editableFileIds: string[]
  applicationId: string
}
export const deserializeMergeEditableFilesVariables = (
  serialized: MergeEditableFilesVariablesOrig
): MergeEditableFilesVariables => ({...serialized})
export const serializeMergeEditableFilesVariables = (
  deserialized: MergeEditableFilesVariables
): MergeEditableFilesVariablesOrig => ({...deserialized})
