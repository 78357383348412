import React, {FC} from 'react'
import {flowMax, addHandlers, addDisplayName} from 'ad-hok'
import Dropzone, {
  IFileWithMeta,
  StatusValue,
  IUploadParams,
} from 'react-dropzone-uploader'
import mime from 'mime-types'

import {makeClasses, addClasses} from 'theme'
import {addTranslationHelpers} from 'utils/i18n'
import addApolloClient from 'utils/addApolloClient'
import addFetchSignedDocumentUploadUrl from 'utils/addFetchSignedDocumentUploadUrl'
import {makeEditableFileKey} from 'utils/editableFiles'
import EditableFileInput from 'components/EditableFileInput'

const classes = makeClasses((theme) => ({
  dropzone: {
    marginTop: theme.spacing(2),
    borderColor: '#C0C0C0',
    borderWidth: 1,
  },
  inputLabel: {
    fontFamily: 'Roboto',
    fontWeight: 400,
  },
  inputLabelWithFiles: {
    display: 'none',
  },
}))

export interface UploadedFile {
  name: string
  fileKey: string
  extension: string | false
}

interface Props {
  personId: string
  uploadInstructionsText: string
  onFileUpload: (opts: {file: UploadedFile}) => void
  onFileRemove?: (opts: {file: UploadedFile}) => void
}

const EditableFileUploader: FC<Props> = flowMax(
  addDisplayName('EditableFileUploader'),
  addApolloClient,
  addFetchSignedDocumentUploadUrl,
  addHandlers({
    getUploadParams: ({personId, fetchSignedDocumentUploadUrl}) => async ({
      file,
    }: IFileWithMeta): Promise<IUploadParams> => {
      const fileKey = makeEditableFileKey(personId)
      const url = await fetchSignedDocumentUploadUrl(fileKey)

      return {
        body: file,
        url,
        method: 'PUT',
        meta: {fileKey},
      }
    },
    handleChangeStatus: ({onFileUpload, onFileRemove}) => (
      {meta: {name, fileKey}, file}: IFileWithMeta & {meta: {fileKey: string}},
      status: StatusValue
    ) => {
      const extension = mime.extension(file.type)
      if (status === 'done') {
        onFileUpload({file: {name, fileKey, extension}})
      } else if (status === 'removed') {
        onFileRemove?.({file: {name, fileKey, extension}})
      }
    },
  }),
  addClasses(classes),
  addTranslationHelpers,
  ({getUploadParams, handleChangeStatus, uploadInstructionsText, classes}) => (
    <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      addClassNames={classes}
      inputContent={uploadInstructionsText}
      inputWithFilesContent={null}
      multiple={false}
      maxFiles={1}
      accept="application/pdf, image/*"
      InputComponent={EditableFileInput}
    />
  )
)

export default EditableFileUploader
