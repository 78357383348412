import React from 'react'
import {
  flowMax,
  addDisplayName,
  addHandlers,
  addWrapper,
  addEffect,
  addRef,
  addProps,
  SimplePropsAdder,
  addStateHandlers,
} from 'ad-hok'
import {FC} from 'react'

import Form, {formColumnWidth, OnSubmitSuccessOptions} from 'components/Form'
import {addTranslationHelpers} from 'utils/i18n'
import {addClasses, makeClasses} from 'theme'
import Dialog from 'components/Dialog'
import DialogTitle from 'components/DialogTitle'
import DialogContent from 'components/DialogContent'
import SelectField from 'components/SelectField'
import DialogActions from 'components/DialogActions'
import Button from 'components/Button'
import {addCreateApplicationMutation, addMeQuery} from 'graphql/generated'
import {ExtractFormSchemaFields} from 'utils/form/schema'
import {addFormikTyped} from 'utils/form/formik'
import {
  BenefitType,
  getIsFacilitySpecificBenefitType,
  getIsInitialDateOfServiceRequiredWhenCreatingBenefitType,
} from 'utils/benefits'
import {getStatusesForBenefit} from 'utils/applicationStatuses'
import {addRightColumnContext} from 'components/EditPersonForm/rightColumnContext'
import {Assert, SchemaDoesntHaveExtraFields} from 'utils/form/typeHelpers'
import {
  addFacilities,
  addBenefits,
  addCharityCareTypes,
} from 'utils/configContext'
import typedAs from 'utils/typedAs'
import {getExtendedName} from 'utils/name'
import {getCanonicalValues, getInitialValues} from 'utils/form/getValues'
import {CreateApplication} from 'graphql/types/CreateApplication'
import {
  createApplicationFormSchemaStatic,
  getCreateApplicationFormSchema,
} from 'components/CreateApplicationDialog/schema'
import DateField from 'components/DateField'
import AssignedToField from 'components/AssignedToField'
import {addLoadingIndicator} from 'utils/dataLoading'

const classes = makeClasses(() => ({
  contentContainer: {
    width: formColumnWidth,
  },
}))

const getInitialStatus = (benefit: BenefitType | null): string | null =>
  benefit && getStatusesForBenefit(benefit)[0].status

type Check = Assert<
  SchemaDoesntHaveExtraFields<
    typeof createApplicationFormSchemaStatic,
    typeof addCreateApplicationMutation
  >
>

interface Props {
  personId: string
  onApplicationCreate: (
    opts: OnSubmitSuccessOptions<
      ExtractFormSchemaFields<typeof createApplicationFormSchemaStatic>,
      CreateApplication
    >
  ) => void
  open: boolean
  onClose: () => void
}

const CreateApplicationDialog: FC<Props> = flowMax(
  addDisplayName('CreateApplicationDialog'),
  addCreateApplicationMutation({}),
  addRightColumnContext,
  addFacilities,
  addCharityCareTypes,
  addBenefits,
  addTranslationHelpers,
  addProps(
    ({person, facilities, benefits, charityCareTypes, t}) => ({
      applicationFormSchema: getCreateApplicationFormSchema({
        facilities,
        benefits,
        charityCareTypes,
        t,
      }),
      personName: getExtendedName({...person, t}),
    }),
    ['person', 'facilities', 'benefits', 'charityCareTypes', 't']
  ),
  addMeQuery({}),
  addLoadingIndicator({}),
  addProps(
    ({applicationFormSchema, personId, me}) => ({
      initialValues: {
        application: {
          ...getCanonicalValues(
            getInitialValues(applicationFormSchema),
            applicationFormSchema
          ).application,
          personId,
          assignedToId: me.id,
        },
      },
    }),
    ['applicationFormSchema', 'personId', 'me']
  ),
  addWrapper(
    (
      render,
      {
        open,
        onClose,
        mutateCreateApplication,
        onApplicationCreate,
        applicationFormSchema,
        initialValues,
      }
    ) => (
      <Dialog
        open={open}
        onClose={onClose}
        data-testid="create-application-dialog"
      >
        <Form
          name="applicationCreateForm"
          schema={applicationFormSchema}
          mutate={mutateCreateApplication}
          initialValues={initialValues}
          onSubmitSuccess={(
            opts: OnSubmitSuccessOptions<
              ExtractFormSchemaFields<typeof applicationFormSchema>,
              CreateApplication
            >
          ) => {
            onClose()
            console.log('Creation of Records successful')
            onApplicationCreate(opts)
          }}
        >
          {render()}
        </Form>
      </Dialog>
    )
  ),
  addFormikTyped(createApplicationFormSchemaStatic),
  addHandlers({
    onSaveClick: ({formik: {submitForm}}) => () => {
      submitForm()
    },
  }),
  addRef('previousBenefit', typedAs<BenefitType | null>(null)),
  addEffect(
    ({formik: {values, setFieldValue}, previousBenefit}) => () => {
      const benefit = values.application.benefit as BenefitType
      if (previousBenefit.current !== benefit) {
        setFieldValue('application.status', getInitialStatus(benefit))
      }
      previousBenefit.current = benefit
    },
    ['formik.values.application.benefit', 'previousBenefit']
  ),
  addClasses(classes),
  ({
    onClose,
    onSaveClick,
    formik: {
      values: {
        application: {benefit},
      },
      isSubmitting,
    },
    personName,
    classes,
    t,
  }) => (
    <>
      <DialogTitle>
        {t('personForm.applications.createDialog.title', {name: personName})}
      </DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <SelectField name="application.benefit" />
        {getIsFacilitySpecificBenefitType(benefit as BenefitType) && (
          <SelectField name="application.facility" />
        )}
        {(benefit as BenefitType) === 'medicaid' && (
          <SelectField name="application.mcdType" />
        )}
        {(benefit as BenefitType) === 'charityCare' && (
          <SelectField name="application.charityCareType" />
        )}
        <AssignedToField name="application.assignedToId" />
        {getIsInitialDateOfServiceRequiredWhenCreatingBenefitType(
          benefit as BenefitType
        ) && <DateField name="application.initialDateOfService" />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('personForm.applications.createDialog.cancel')}
        </Button>
        <Button onClick={onSaveClick} color="primary" disabled={isSubmitting}>
          {t('personForm.applications.createDialog.save')}
        </Button>
      </DialogActions>
    </>
  )
)

export default CreateApplicationDialog

type AddCreateApplicationDialog = SimplePropsAdder<{
  showCreateApplicationDialog: () => void
}>

export const addCreateApplicationDialog: AddCreateApplicationDialog = flowMax(
  addRightColumnContext,
  addStateHandlers(
    {
      isShowingCreateApplicationDialog: false,
    },
    {
      showCreateApplicationDialog: () => () => ({
        isShowingCreateApplicationDialog: true,
      }),
      hideCreateApplicationDialog: () => () => ({
        isShowingCreateApplicationDialog: false,
      }),
    }
  ),
  addWrapper(
    (
      render,
      {
        person,
        onApplicationCreate,
        isShowingCreateApplicationDialog,
        hideCreateApplicationDialog,
      }
    ) => (
      <>
        <CreateApplicationDialog
          personId={person.id}
          open={isShowingCreateApplicationDialog}
          onApplicationCreate={onApplicationCreate}
          onClose={hideCreateApplicationDialog}
        />
        {render()}
      </>
    )
  )
)
