import React, {FC} from 'react'
import {flowMax, addDisplayName, addProps, addMemoBoundary} from 'ad-hok'
import {FieldProps} from 'formik'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import {ToggleButtonGroupProps} from '@material-ui/lab/ToggleButtonGroup'
import cx from 'classnames'
import {addComponentBoundary, removeProps} from 'ad-hok-utils'

import {FieldComponentProps} from 'components/TextField'
import Field, {addFieldInfoWithMemoBoundary} from 'components/Field'
import FormControl from 'components/FormControl'
import {addFieldError} from 'utils/field'
import {addTranslationHelpers} from 'utils/i18n'
import FormLabel from 'components/FormLabel'
import {makeClasses, addClasses} from 'theme'
import ToggleButtonGroup from 'components/ToggleButtonGroup'
import ToggleButton from 'components/ToggleButton'
import Body1 from 'components/Body1'
import FormFeedbackIcon from 'components/FormFeedbackIcon'
import {addFormContext} from 'utils/form/context'
import FormHelperText from 'components/FormHelperText'
import {FormFieldConfirmFreshnessButton} from 'components/FormFieldConfirmFreshnessButton'
import FieldScrollTarget from 'components/FieldScrollTarget'

const classes = makeClasses((theme) => ({
  container: {
    marginBottom: theme.spacing(4),
    width: '100%',
  },
  mainRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fieldContainer: {
    width: 'auto',
    marginBottom: 0,
    marginRight: theme.spacing(3),
  },
  optionIcon: {
    marginRight: theme.spacing(1),
  },
  yesIcon: {
    color: '#219653', // Green 1
  },
  noIcon: {
    color: '#b00020', // Error
  },
  optionLabel: {
    color: '#000000',
  },
  yesContainer: {
    '&.Mui-selected': {
      backgroundColor: 'rgba(39, 174, 96, 0.29)',
    },
  },
  noContainer: {
    '&.Mui-selected': {
      backgroundColor: 'rgba(176, 0, 32, 0.29)',
    },
  },
  fieldLabel: {
    color: '#333333',
    marginBottom: theme.spacing(1),
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  confirmFreshnessButton: {
    marginRight: theme.spacing(1),
  },
  helperText: {
    marginRight: 56,
    marginTop: theme.spacing(1),
  },
}))

interface ToggleButtonGroupWiredProps
  extends FieldProps,
    Omit<ToggleButtonGroupProps, 'name' | 'value'> {}

const fieldToToggleButtonGroup = ({
  field,
  form,
  ...props
}: ToggleButtonGroupWiredProps): ToggleButtonGroupProps => ({
  ...props,
  ...field,
})

const ToggleButtonGroupWired: FC<ToggleButtonGroupWiredProps> = (props) => (
  <ToggleButtonGroup
    {...fieldToToggleButtonGroup(props)}
    onChange={(e, value) =>
      props.field.onChange({
        ...e,
        target: {
          ...e.target,
          name: props.field.name,
          value,
        },
      })
    }
    onBlur={(e) =>
      props.field.onBlur({
        ...e,
        target: {
          ...e.target,
          name: props.field.name,
        },
      })
    }
  />
)

const OptionalBooleanField: FC<FieldComponentProps> = flowMax(
  addDisplayName('OptionalBooleanField'),
  addMemoBoundary([]),
  addProps({exampleValue: null as boolean | null}),
  addProps(({name: unprefixedName}) => ({
    unprefixedName,
  })),
  addFieldInfoWithMemoBoundary(['unprefixedName']),
  addMemoBoundary(['name']),
  addFormContext,
  addProps(({formName, name}) => ({
    id: `${formName}-${name}-boolean`,
  })),
  addProps(({id}) => ({
    labelId: `${id}-label`,
  })),
  addComponentBoundary,
  addFieldError,
  addMemoBoundary(['fieldError', 'name', 'labelId', 'id']),
  addTranslationHelpers,
  addClasses(classes),
  removeProps(['formSchema', 'formName', 'fieldSchema']),
  addProps(({fieldError, helperText}) => ({
    helperText: fieldError || helperText,
  })),
  ({
    required,
    fieldError,
    labelId,
    label,
    name,
    id,
    t,
    classes,
    unprefixedName,
    helperText,
    showConfirmButton,
    callbackRef,
    ...props
  }) => (
    <FormControl error={!!fieldError} className={classes.container}>
      <FieldScrollTarget ref={callbackRef} offset={40} />
      <FormLabel id={labelId} className={classes.fieldLabel}>
        {label}
        {required && ' *'}
      </FormLabel>
      <div className={classes.mainRowContainer}>
        <Field
          component={ToggleButtonGroupWired}
          name={unprefixedName}
          id={id}
          label={label}
          omitProps={['fullWidth', 'helperText']}
          exclusive
          className={classes.fieldContainer}
          noIcon
          noConfirmButton
          noFieldScrollTarget
          {...props}
        >
          <ToggleButton value="true" className={classes.yesContainer}>
            <CheckIcon className={cx(classes.optionIcon, classes.yesIcon)} />
            <Body1 className={classes.optionLabel}>{t('form.yes')}</Body1>
          </ToggleButton>
          <ToggleButton value="false" className={classes.noContainer}>
            <CloseIcon className={cx(classes.optionIcon, classes.noIcon)} />
            <Body1 className={classes.optionLabel}>{t('form.no')}</Body1>
          </ToggleButton>
        </Field>
        <div className={classes.iconContainer}>
          {showConfirmButton && (
            <FormFieldConfirmFreshnessButton
              name={name}
              className={classes.confirmFreshnessButton}
            />
          )}
          <FormFeedbackIcon name={name} />
        </div>
      </div>
      {helperText && (
        <FormHelperText className={classes.helperText}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
)

export default OptionalBooleanField
